import React from "react";
import { Modal } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ChartModal = ({ show, setShow, chartData }) => {
  // useEffect(() => {}, [chartData]);
  // for categories

  let cat = [];

  let viData = [];
  let vaData = [];
  let tpvaData = [];

  chartData?.map((model, i) => {
    cat.push(
      model?.brands?.name + " " + model?.models?.name + " " + model?.trims?.name
    );

    if (i === 0 && model) {
      viData.push(100);
      vaData.push(100);
    } else if (model !== undefined) {
      viData.push(Math.round((model?.cdh_msrp / chartData[0]?.cdh_msrp) * 100));
      vaData.push(
        Math.round(
          ((model?.cdh_msrp + (chartData[0]?.TOValue - model?.TOValue)) /
            chartData[0]?.cdh_msrp) *
            100
        )
      );
    }
    tpvaData.push(
      Math.round(
        ((model?.cdh_msrp +
          (chartData[0]?.TOValue - model?.TOValue) -
          model?.cdh_total_offer_value) /
          (chartData[0]?.cdh_msrp - model?.cdh_total_offer_value)) *
          100
      )
    );
  });

  const options = {
    colors: ["#0097ce", "#014eb3", "#003789"],
    credits: {
      enabled: false,
    },
    // margin: 25,

    xAxis: {
      categories: [...cat],
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}%",
        },
      },
    },
    yAxis: {
      tickAmount: 10,
      labels: {
        format: "{value}%",
      },
      title: {
        text: "Total percent market share",
      },
    },
    title: {
      text: "Charts",
    },
    series: [
      {
        type: "column",
        data: viData,
        name: "VI",
      },
      {
        type: "column",
        data: vaData,
        name: "VA",
      },
      {
        type: "column",
        data: tpvaData,
        name: "TPVA",
      },
    ],
  };
  return (
    <>
      {" "}
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body>
          <Modal.Header closeButton></Modal.Header>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChartModal;
