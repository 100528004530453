import React, { useRef, useState } from "react";

import { useEffect } from "react";
import { axiosPrivate } from "../../../api/axios";
import { useDownloadExcel } from "react-export-table-to-excel";
import { waveMonths } from "../../../data/wave-month-data";

const FixedSegmentsFeatures = ({
  bmData,
  currencyFactor,
  currencyCode,
  compareData,
  bmTrimSelected,
  countryName,
  modelName,
  wave,
  showFeatures,
}) => {
  const [features, setFeatures] = useState();
  const [bmFeatureValues, setBmFeatureValues] = useState();
  const [compareFeatureValues, setComareFeatureValues] = useState({});

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Features xls",
    sheet: "fea",
  });
  // console.log("currentRe", tableRef1);
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  console.log("compareFeatureValue", compareFeatureValues);
  console.log("bmFeatureValues", bmFeatureValues);
  console.log("bmData", bmData);
  console.log("compareData", compareData);
  useEffect(() => {
    axiosPrivate
      .get("features")
      .then((res) => {
        setFeatures(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  useEffect(() => {
    bmData &&
      axiosPrivate
        .post("features", {
          cdh_id: bmData?.cdh_id,
        })
        .then((res) => {
          setBmFeatureValues(res?.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [bmData]);
  useEffect(() => {
    compareData &&
      Object.keys(compareData).map((key, index) => {
        compareData[key]?.map((item, ind) => {
          console.log("item", item);
          axiosPrivate
            .post("features", {
              cdh_id: item?.cdh_id,
            })
            .then((res) => {
              setComareFeatureValues((prev) => ({
                ...prev,
                [item?.cdh_id]: res?.data?.data,
              }));
            })
            .catch((e) => Promise.reject(e));
        });
      });
  }, [compareData, bmTrimSelected]);

  return (
    <>
      <div className="user-table">
        <br />
        <div className="details-controls">
          {" "}
          {/* <button onClick={onDownload} className="btn-details">
            Export
          </button> */}
          &nbsp;
          {/* <button onClick={handleFeaturesShow} className="btn-details">
            {showFeatures ? "Collapse" : "Expand"}
          </button> */}
        </div>
        <br />
        {showFeatures && (
          <table ref={tableRef} id="l">
            <thead>
              <tr>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Label</th>
                <th>Price USD</th>
                <th>{`Price ${currencyCode}`}</th>
                <th>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </th>
                <th>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </th>
                {compareData &&
                  Object.keys(compareData).map((key, index) => (
                    <th>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                  ))}

                {/* <th>Promotions</th> */}
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid " }}>
                <td style={{ fontSize: "2rem" }}>
                  {" "}
                  {`${countryName}  ${modelName} VIVA `}
                </td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>&nbsp;</td>

                <td></td>

                {/* <th>Promotions</th> */}
              </tr>

              <tr style={{ borderBottom: "1px solid " }}>
                <td style={{ fontSize: "1.2rem" }}>
                  {" "}
                  {waveMonths[Number(String(wave).slice(-2))] +
                    "," +
                    String(wave).slice(0, 2)}
                </td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> </td>

                <td></td>

                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Country</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{countryName} </td>

                <td></td>

                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Maker</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{bmData?.brands?.name} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return compareData[key]?.map((item, i) => {
                      return (
                        <>
                          <td>{item?.brands?.name} </td>
                        </>
                      );
                    });
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Model</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{bmData?.models?.name} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return compareData[key]?.map((item, i) => {
                      return (
                        <>
                          <td>{item?.models?.name} </td>
                        </>
                      );
                    });
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Model Year</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{`MY${String(bmData?.cdh_car_year)?.slice(-2)}`} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return compareData[key]?.map((item, i) => {
                      return (
                        <>
                          <td>{item?.cdh_car_year} </td>
                        </>
                      );
                    });
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Trim Code</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{`${bmData?.trim_code_ext}`} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return (
                      <>
                        <td>{compareData[key]?.trim_code_ext} </td>
                      </>
                    );
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Trim</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{bmData?.trims?.name} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return compareData[key]?.map((item, i) => {
                      return (
                        <>
                          <td>{item?.trims?.name} </td>
                        </>
                      );
                    });
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              {features?.map((feature, index) => {
                //   return feature?.other_featurelist?.map((sub, ind) => {
                return feature?.other_featurelist?.map((sub, ind) => {
                  if (!sub?.other_featurelist?.length) {
                    return (
                      <tr
                        key={sub.featurelist_id}
                        style={{ borderBottom: "1px solid " }}
                      >
                        <td
                          style={{
                            borderBottom: `1px solid ${
                              index !== feature?.length - 1 ? "#fff" : "#000"
                            }`,
                            borderRight: "1px solid",
                          }}
                        >
                          {ind === 0 ? feature?.featurelist_name : ""}
                        </td>
                        <td>{""}</td>
                        <td>{sub?.featurelist_name}</td>
                        <td>{sub?.featurelist_value}</td>
                        <td>
                          {Math.round(sub?.featurelist_value * currencyFactor)}
                        </td>
                        <td>
                          {bmFeatureValues &&
                            bmFeatureValues[sub?.featurelist_feature_id]}{" "}
                        </td>
                        {compareFeatureValues &&
                          Object.keys(compareFeatureValues).map((key, idx) => {
                            return (
                              <td>
                                {
                                  compareFeatureValues[key][
                                    sub?.featurelist_feature_id
                                  ]
                                }
                              </td>
                            );
                          })}
                      </tr>
                    );
                  } else {
                    return sub?.other_featurelist?.map((sub1, i) => {
                      return (
                        <tr
                          key={sub1.featurelist_id}
                          style={{ borderBottom: "1px solid " }}
                        >
                          <td
                            style={{
                              borderBottom: `1px solid ${
                                index !== feature?.length - 1 ? "#fff" : "#000"
                              }`,
                              borderRight: "1px solid",
                            }}
                          >
                            {ind === 0 && i === 0
                              ? feature?.featurelist_name
                              : ""}
                          </td>
                          <td
                            style={{
                              borderBottom: `1px solid ${
                                i !== sub?.other_featurelist?.length - 1
                                  ? "#fff"
                                  : "#000"
                              }`,
                              borderRight: "1px solid",
                            }}
                          >
                            {i === 0 ? sub?.featurelist_name : ""}
                          </td>
                          <td>{sub1?.featurelist_name}</td>
                          <td>{sub1?.featurelist_value}</td>
                          <td>
                            {Math.round(
                              sub1?.featurelist_value * currencyFactor
                            )}
                          </td>
                          <td>
                            {bmFeatureValues &&
                              bmFeatureValues[
                                sub1?.featurelist_feature_id
                              ]}{" "}
                          </td>
                          {compareFeatureValues &&
                            Object.keys(compareFeatureValues).map(
                              (key, idx) => {
                                return (
                                  <td>
                                    {
                                      compareFeatureValues[key][
                                        sub1?.featurelist_feature_id
                                      ]
                                    }
                                  </td>
                                );
                              }
                            )}
                        </tr>
                      );
                    });
                  }
                });
                //   });
              })}
              <tr style={{ borderBottom: "1px solid " }}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>
                  Manufacturing Selling Retail Price (MSRP) - Including VAT
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{numberWithCommas(bmData?.cdh_msrp)}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return <td>{numberWithCommas(item?.cdh_msrp)}</td>;
                    });
                  })}
              </tr>

              <tr style={{ borderBottom: "1px solid " }}>
                <td>Total Options Value </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>
                  {numberWithCommas(
                    Math.round(bmData?.TOValue * currencyFactor)
                  )}
                </td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>
                          {numberWithCommas(
                            Math.round(item?.TOValue * currencyFactor)
                          )}
                        </td>
                      );
                    });
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>
                  {" "}
                  Retail Incentive (Input the total offer value; example Cash
                  Discount, Free Service, Insurance, Finance, etc..
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{numberWithCommas(bmData?.cdh_total_offer_value)}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>{numberWithCommas(item?.cdh_total_offer_value)}</td>
                      );
                    });
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>MSRP VA</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>
                  {numberWithCommas(
                    Math.round(
                      bmData?.cdh_msrp - bmData?.TOValue * currencyFactor
                    )
                  )}
                </td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>
                          {numberWithCommas(
                            Math.round(
                              item?.cdh_msrp - item?.TOValue * currencyFactor
                            )
                          )}
                        </td>
                      );
                    });
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>TP</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>
                  {numberWithCommas(
                    bmData?.cdh_msrp - bmData?.cdh_total_offer_value
                  )}
                </td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>
                          {numberWithCommas(
                            item?.cdh_msrp - item?.cdh_total_offer_value
                          )}
                        </td>
                      );
                    });
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>TPVA</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>
                  {numberWithCommas(
                    Math.round(
                      bmData?.cdh_msrp -
                        bmData?.TOValue * currencyFactor -
                        bmData?.cdh_total_offer_value
                    )
                  )}
                </td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>
                          {numberWithCommas(
                            Math.round(
                              item?.cdh_msrp -
                                item?.TOValue * currencyFactor -
                                item?.cdh_total_offer_value
                            )
                          )}
                        </td>
                      );
                    });
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>Visual Index (VI) %</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{"100%"}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>
                          {Math.round(
                            (item?.cdh_msrp / bmData?.cdh_msrp) * 100
                          ) + "%"}
                        </td>
                      );
                    });
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>Value Adjusted (VA) %</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{"100%"}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>
                          {Math.round(
                            ((item?.cdh_msrp +
                              (bmData?.TOValue * currencyFactor -
                                item?.TOValue * currencyFactor)) /
                              bmData?.cdh_msrp) *
                              100
                          ) + "%"}
                        </td>
                      );
                    });
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>Transaction Price VA (TPVA) %</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{"100%"}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return compareData[key]?.map((item, ind) => {
                      return (
                        <td>
                          {Math.round(
                            ((item?.cdh_msrp +
                              (bmData?.TOValue * currencyFactor -
                                item?.TOValue * currencyFactor) -
                              item?.cdh_total_offer_value) /
                              (bmData?.cdh_msrp -
                                bmData?.cdh_total_offer_value)) *
                              100
                          ) + "%"}
                        </td>
                      );
                    });
                  })}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default FixedSegmentsFeatures;
