import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import * as BsIcons from "react-icons/bs";

import { axiosAdmin } from "../../api/axios";
import CommonTable from "./table";
import AddModel from "./forms/add-model-form";
import { useEffect } from "react";
import { Popconfirm, Tag } from "antd";
import AddTrims from "./forms/add-trim-form";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import { left, right } from "@popperjs/core";

const Trims = () => {
  const [formType, setFormType] = useState("Add");
  const [tableFilteredValue, setTableFilteredValue] = useState({});
  const [rowCountItemsShow, setRowCountItemsShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [modelsData, setModelsData] = useState();
  const [filteredTrimsData, setFilteredTrimsData] = useState();
  const [brandsData, setBrandsData] = useState();
  const [carTypesData, setCarTypesData] = useState();
  const [editTrimData, setEditTrimData] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [trims, setTrims] = useState();
  const [csvData, setCsvData] = useState([]); // State to hold CSV data

  useEffect(() => {
    if (searchQuery !== "") {
      let filteredData = [];
      trims?.filter((item) => {
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(searchQuery) &&
            filteredData.push(item)
        ) ||
          Object.values(item).some(
            (val) =>
              typeof val === "string" &&
              val.includes(searchQuery) &&
              filteredData.push(item)
          );
      });
      setFilteredTrimsData(filteredData);
    }
  }, [searchQuery, modelsData]);
  useEffect(() => {
    axiosAdmin
      .get("trim/getAllTrims")
      .then((res) => {
        let data = [];
        res?.data?.map((trim, i) => {
          data.push({
            key: trim?.id,
            serialNum: String(i + 1),
            trimName: trim?.name,
            id: String(trim?.code),
            status: trim?.status ? 1 : 0,
            modelName: trim?.models?.name,
          });
        });
        setTrims(data);

        setCsvData(data);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  useEffect(() => {
    axiosAdmin
      .get("model/getAllModels")
      .then((res) => {
        let data = [];
        res?.data?.map((model, i) => {
          data.push({
            key: model?.id,
            serialNum: String(i + 1),
            name: model?.name,
            id: String(model?.id),
            status: model?.status ? 1 : 0,
            carType: model?.car_types?.name,
            modelName: model?.brands?.name,
          });
        });
        setModelsData(data);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  const addModelClickHandler = () => {
    setShow(true);
    setFormType("Add");
    axiosAdmin
      .get("brand/getAllBrands")
      .then((res) => {
        setBrandsData(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  };
  const handleEdit = (key) => {
    setFormType("Edit");
    setShow(true);
    axiosAdmin
      .get(`trim/getTrimById/${Number(key)}`)
      .then((res) => {
        setEditTrimData(res.data);
      })
      .catch((e) => Promise.reject(e));
    axiosAdmin.get("brand/getAllBrands").then((res) => {
      setBrandsData(res?.data?.data);
    });
  };
  const handleChangeStatus = (key) => {
    axiosAdmin
      .delete(`trim/deleteTrim/${Number(key)}`)
      .then((res) => {
        if (res?.data?.success) {
          axiosAdmin
            .get("trim/getAllTrims")
            .then((res) => {
              let data = [];
              res?.data?.map((trim, i) => {
                data.push({
                  key: trim?.id,
                  serialNum: String(i + 1),
                  trimName: trim?.name,
                  id: String(trim?.code),
                  status: trim?.status ? 1 : 0,
                  modelName: trim?.models?.name,
                });
              });
              setTrims(data);
              setCsvData(data);
            })
            .catch((e) => Promise.reject(e));
        }
      })
      .catch((e) => Promise.reject(e));
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "serialNum",
      align: "center",
      width: 8,
    },
    {
      title: "",
      dataIndex: "edit",
      render: (_, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleEdit(record.key)}
        >
          <BsIcons.BsPencil />
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <>
          <Popconfirm
            title={`Are you sure, change status to ${
              record.status ? "inactive" : "active"
            }.?`}
            okText="Yes"
            onConfirm={() => handleChangeStatus(record.key)}
          >
            <Tag
              style={{ cursor: "pointer" }}
              color={record.status ? "green" : "red"}
            >
              {record.status ? "Active" : "Inactive"}
            </Tag>
          </Popconfirm>
        </>
      ),
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Inactive",
          value: "Inactive",
        },
      ],
      filteredValue: tableFilteredValue.status || null,
      onFilter: (value, record) =>
        record?.status ? value === "Active" : value === "Inactive",
    },

    {
      title: "Model name",
      dataIndex: "modelName",
      // sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Trim Code",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Trim Name",
      dataIndex: "trimName",
    },
  ];

  const handleExportCSV = () => {
    // Create a Blob containing the CSV data
    const csvString = CSVLink.getData(csvData, {
      headers: [
        { label: "Serial No.", key: "serialNum" },
        { label: "Trim Name", key: "trimName" },
        { label: "Trim Code", key: "id" },
        { label: "Status", key: "status" },
        { label: "Model Name", key: "modelName" },
      ],
    });
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    // Save the Blob as a CSV file
    saveAs(blob, "trims.csv");
  };

  return (
    <>
      <div className="vendors">
        <div className="vendors__header">
          <p>Models</p>

          <div className="d-flex justify-content-end">
            <Button onClick={addModelClickHandler} className="me-2">
              Add Trim
            </Button>

            <CSVLink
              data={csvData}
              headers={[
                { label: "Serial No.", key: "serialNum" },
                { label: "Trim Name", key: "trimName" },
                { label: "Trim Code", key: "id" },
                { label: "Status", key: "status" },
                { label: "Model Name", key: "modelName" },
              ]}
              filename={"trims.csv"}
              className="btn btn-primary me-2"
            >
              Export CSV
            </CSVLink>
          </div>

          <AddTrims
            setTrims={setTrims}
            editTrimData={editTrimData}
            formType={formType}
            brands={brandsData}
            models={modelsData}
            type="Model"
            show={show}
            setShow={setShow}
          />
        </div>
        <div className="vendors__body">
          <div className="vendors__table-controls">
            <div className="row-count">
              <span>Show</span>
              <div
                onClick={() => setRowCountItemsShow((prev) => !prev)}
                className="row-count__dropdown"
              >
                <span>{pageSize}</span>
                <BsIcons.BsChevronDown />
                <div
                  className={`row-count__items ${
                    rowCountItemsShow ? "visible" : ""
                  }`}
                >
                  <li
                    onClick={() => setPageSize(10)}
                    className="row-count__item"
                  >
                    10
                  </li>
                  <li
                    onClick={() => setPageSize(20)}
                    className="row-count__item"
                  >
                    20
                  </li>
                  <li
                    onClick={() => setPageSize(30)}
                    className="row-count__item"
                  >
                    30
                  </li>
                </div>
              </div>
              <span>Entries</span>
            </div>

            <form onChange={onSearchChange} action="" className="search">
              <p>Search:</p>
              <input type="text" />
            </form>
          </div>
          <div className="vendors__table">
            {/* table */}
            <CommonTable
              setTableFilteredValue={setTableFilteredValue}
              data={searchQuery ? filteredTrimsData : trims}
              columns={columns}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Trims;
