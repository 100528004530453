import React from "react";

const PageNotFound = () => {
  return (
    <>
      <div className="pageNotFound">
        <div className="pageNotFound_errorCode">404</div>
        <div className="pageNotFound_seperator">|</div>
        <div className="pageNotFound_content">Page Not Found</div>
      </div>
    </>
  );
};

export default PageNotFound;
