import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import * as BsIcons from "react-icons/bs";

import { axiosAdmin } from "../../api/axios";
import CommonTable from "./table";
import { useEffect } from "react";
import { Popconfirm, Tag } from "antd";
import AddCarType from "./forms/add-edit-carType-form";

const CarType = () => {
  const [formType, setFormType] = useState("Add");
  const [tableFilteredValue, setTableFilteredValue] = useState({});
  const [rowCountItemsShow, setRowCountItemsShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [carTypeData, setCarTypeData] = useState();
  const [filteredCarTypeData, setFilteredCarTypeData] = useState();
  const [editCarTypeData, setEditCarTypeData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (searchQuery !== "") {
      let filteredData = [];
      carTypeData?.filter((item) => {
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(searchQuery) &&
            filteredData.push(item)
        ) ||
          Object.values(item).some(
            (val) =>
              typeof val === "string" &&
              val.includes(searchQuery) &&
              filteredData.push(item)
          );
      });
      setFilteredCarTypeData(filteredData);
    }
  }, [searchQuery, carTypeData]);

  useEffect(() => {
    axiosAdmin
      .get("carType/getAllCarTypes")
      .then((res) => {
        let data = [];
        res?.data?.map((country, i) => {
          data.push({
            key: country?.id,
            serialNum: String(i + 1),
            name: country?.name,
            id: String(country?.id),

            status: country?.status ? 1 : 0,
            currency: country?.currency_code,
          });
        });
        setCarTypeData(data);
      })
      .catch((e) => {
        Promise.reject(e);
      });
  }, []);

  const addModelClickHandler = () => {
    setShow(true);
    setFormType("Add");
  };
  const handleEdit = (key) => {
    setFormType("Edit");
    setShow(true);
    axiosAdmin
      .get(`cartype/getCarTypeById/${Number(key)}`)
      .then((res) => {
        setEditCarTypeData(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  };
  const handleChangeStatus = (key) => {
    axiosAdmin
      .delete(`carType/deleteCarType/${Number(key)}`)
      .then((res) => {
        if (res?.data?.success) {
          axiosAdmin
            .get("carType/getAllCarTypes")
            .then((res) => {
              let data = [];
              res?.data?.map((brand, i) => {
                data.push({
                  key: brand?.id,
                  serialNum: String(i + 1),
                  name: brand?.name,
                  id: String(brand?.id),
                  status: brand?.status ? 1 : 0,
                });
              });
              setCarTypeData(data);
            })
            .catch((e) => Promise.reject(e));
        }
      })
      .catch((e) => {
        Promise.reject(e);
      });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "serialNum",
      align: "center",
      width: 8,
    },
    {
      title: "",
      dataIndex: "edit",
      render: (_, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleEdit(record.key)}
        >
          <BsIcons.BsPencil />
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <>
          <Popconfirm
            title={`Are you sure, change status to ${
              record?.status ? "inactive" : "active"
            }.?`}
            okText="Yes"
            onConfirm={() => handleChangeStatus(record?.key)}
          >
            <Tag
              style={{ cursor: "pointer" }}
              color={record?.status ? "green" : "red"}
            >
              {record?.status ? "Active" : "Inactive"}
            </Tag>
          </Popconfirm>
        </>
      ),
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Inactive",
          value: "Inactive",
        },
      ],
      filteredValue: tableFilteredValue.status || null,
      onFilter: (value, record) =>
        record?.status ? value === "Active" : value === "Inactive",
    },
    {
      title: "Car Type Id",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Car Type Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ];

  return (
    <>
      <div className="vendors">
        <div className="vendors__header">
          <p>Car Types</p>

          <Button onClick={addModelClickHandler} className="me-2">
            Add Car Type
          </Button>
          <AddCarType
            setCarTypesData={setCarTypeData}
            editCarTypeData={editCarTypeData}
            formType={formType}
            show={show}
            setShow={setShow}
          />
        </div>
        <div className="vendors__body">
          <div className="vendors__table-controls">
            <div className="row-count">
              <span>Show</span>
              <div
                onClick={() => setRowCountItemsShow((prev) => !prev)}
                className="row-count__dropdown"
              >
                <span>{pageSize}</span>
                <BsIcons.BsChevronDown />
                <div
                  className={`row-count__items ${
                    rowCountItemsShow ? "visible" : ""
                  }`}
                >
                  <li
                    onClick={() => setPageSize(10)}
                    className="row-count__item"
                  >
                    10
                  </li>
                  <li
                    onClick={() => setPageSize(20)}
                    className="row-count__item"
                  >
                    20
                  </li>
                  <li
                    onClick={() => setPageSize(30)}
                    className="row-count__item"
                  >
                    30
                  </li>
                </div>
              </div>
              <span>Entries</span>
            </div>
            <form
              onSubmit={(e) => e.preventDefault()}
              action=""
              className="search"
            >
              <p>Search:</p>
              <input onChange={onSearchChange} type="text" />
            </form>
          </div>
          <div className="vendors__table">
            {/* table */}
            <CommonTable
              setTableFilteredValue={setTableFilteredValue}
              data={searchQuery ? filteredCarTypeData : carTypeData}
              columns={columns}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CarType;
