import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, FormSelect, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import { waveMonthsData } from "../../data/user/import-data-wave";
import { axiosAdmin, axiosPrivate } from "../../api/axios";

const CarSpecFile = () => {
  const [fileParams, setFileParams] = useState(null);
  const [file, setFile] = useState();
  const [selectedData, setSelectedData] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [trimsData, setTrimsData] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axiosAdmin
      .get("country/getActiveCountries")
      .then((res) => {
        setCountryData(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  useEffect(() => {
    selectedData?.country &&
      axiosPrivate
        .get(`brand/brandByCountry/${selectedData?.country}`)
        .then((res) => {
          setBrandData(res?.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [selectedData?.country]);

  useEffect(() => {
    selectedData?.brand &&
      axiosPrivate
        .post(`yourChoice/models`, {
          countryId: Number(selectedData?.country),
          brandId: selectedData?.brand,
          wave: fileParams?.year?.slice(-2) + fileParams?.wave,
          segment: Number(selectedData?.segment),
        })
        .then((res) => {
          setModelData(res?.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [selectedData?.brand]);
  useEffect(() => {
    selectedData?.model &&
      axiosPrivate
        .post(`yourChoice/trims`, {
          countryId: Number(selectedData?.country),
          month: Number(fileParams?.wave),
          year: Number(fileParams?.year),
          segment: Number(selectedData?.segment),
          modelCode: selectedData?.model,
        })
        .then((res) => {
          setTrimsData(res?.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [selectedData?.model]);

  const handleWaveChange = (e) => {
    setFileName((prev) => ({ ...prev, month: e.target.value.split("-")[1] }));
    setFileParams({ ...fileParams, wave: e.target.value.split("-")[0] });
  };
  const handleYearChange = (e) => {
    setFileName((prev) => ({ ...prev, year: e.target.value }));
    setFileParams({ ...fileParams, year: e.target.value });
  };
  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const onFileSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    let newFileName =
      `${fileName?.segment}-${fileName?.country}-${fileName?.brand}-${fileName?.model}-${fileName?.trim}-${fileName?.year}-${fileName?.month}.pdf`.replace(
        "/",
        "_"
      );
    // console.log(newFileNameArr);
    // const newFileName = newFileNameArr.join("??");
    const newFile = new File([file], newFileName);
    formData.append("file", newFile); // if (fs.existsSync(`./public/data/uploads/pdf/${file.originalname}`)) {
    console.log(newFile);
    // }
    // fs.renameSync(
    //   `./public/data/uploads/pdf/${file.originalname}`,
    //   `./public/data/uploads/pdf/${filename}`
    // );
    formData.append("month", fileParams.wave);
    formData.append("year", fileParams.year);
    formData.append("segment", selectedData.segment);
    formData.append("model", selectedData.model);
    formData.append("country", selectedData.country);
    formData.append("brand", selectedData.brand);
    formData.append("trim", selectedData.trim);

    axiosAdmin
      .post("file/importSpecFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success && res.data.message === "updated") {
          toast.warning("File Successfully updated", {
            position: "top-left",
            autoClose: 2000,
          });
        } else if (res.data.success && res.data.message === "added") {
          res.data.success &&
            res.data.message === "added" &&
            toast.success("File Successfully added", {
              position: "top-left",
              autoClose: 2000,
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Failed to update file", {
          position: "top-left",
          autoClose: 2000,
        });
        Promise.reject(e);
      });
  };
  const onSelect = (e) => {
    const { name, value } = e.target;
    setSelectedData({ ...selectedData, [name]: value.split("-")[0] });
    setFileName((prev) => ({ ...prev, [name]: value.split("-")[1] }));
  };
  return (
    <>
      <div className="import-data">
        <div className="import-data__header">
          <h5>Import</h5>
        </div>
        <Form action="." encType="multipart/form-data" onSubmit={onFileSubmit}>
          <div className="import-data__dropdowns">
            <Form.Group className="mb-4">
              <Form.Label>{`Month`}</Form.Label>
              <Form.Select
                style={{ maxWidth: "10rem" }}
                required
                onChange={handleWaveChange}
                aria-label="Default select example"
              >
                <option value="">Select Month</option>
                {waveMonthsData.map((item, i) => {
                  return (
                    <option
                      key={i}
                      value={item.id + "-" + item.month}
                      name="wave"
                    >
                      {item.month}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Year`}</Form.Label>
              <FormSelect
                style={{ maxWidth: "10rem" }}
                required
                onChange={handleYearChange}
                aria-label="Default select example"
              >
                <option value="">Select Year</option>

                <option value={2022} name="year">
                  2022
                </option>
                <option value={2023} name="year">
                  2023
                </option>
                <option value={2024} name="year">
                  2024
                </option>
                <option value={2025} name="year">
                  2025
                </option>
              </FormSelect>
            </Form.Group>
            <Form.Group className="mb-4 trim_select">
              <Form.Label>{`Segment`}</Form.Label>
              <FormSelect
                style={{ maxWidth: "10rem" }}
                name="segment"
                onChange={onSelect}
                required
                aria-label="Default select example"
              >
                <option value="">Select Segment</option>
                <option value={1 + "-" + "Retail"}>Retail</option>
                <option value={2 + "-" + "Fleet"}>Fleet</option>
              </FormSelect>
            </Form.Group>
            <Form.Group className="mb-4 trim_select">
              <Form.Label>{`Country`}</Form.Label>
              <FormSelect
                style={{ maxWidth: "10rem" }}
                name="country"
                onChange={onSelect}
                required
                aria-label="Default select example"
              >
                <option value="">Select Country</option>

                {countryData?.map((country) => (
                  <option
                    value={country?.code + "-" + country?.name}
                    key={country?.id}
                  >
                    {country?.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>

            <Form.Group className="mb-4 trim_select">
              <Form.Label>{`Brand`}</Form.Label>
              <FormSelect
                style={{ maxWidth: "10rem" }}
                name="brand"
                onChange={onSelect}
                required
                aria-label="Default select example"
              >
                <option value="">Select Brand</option>

                {brandData?.map((brand) => (
                  <option
                    value={brand?.code + "-" + brand?.name}
                    key={brand?.id}
                  >
                    {brand?.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>
            <Form.Group className="mb-4 trim_select">
              <Form.Label>{`Model`}</Form.Label>
              <FormSelect
                onChange={onSelect}
                name="model"
                style={{ maxWidth: "10rem" }}
                required
                aria-label="Default select example"
              >
                <option value="">Select Model</option>
                {modelData?.map((model) => (
                  <option
                    value={model?.code + "-" + model?.name}
                    key={model?.id}
                  >
                    {model?.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>
            <Form.Group className="mb-4 trim_select">
              <Form.Label>{`Trim`}</Form.Label>
              <FormSelect
                onChange={onSelect}
                name="trim"
                style={{ maxWidth: "10rem" }}
                required
                aria-label="Default select example"
              >
                <option value="">Select Trim</option>
                {trimsData.length &&
                  trimsData?.map((trim) => (
                    <option value={trim?.id + "-" + trim?.name} key={trim?.id}>
                      {trim?.name}
                    </option>
                  ))}
              </FormSelect>
            </Form.Group>
          </div>
          {/* <Form.Group controlId="formFile" className="mb-4">
            <Form.Label>{`File`}</Form.Label>
            <Form.Control
              id="file"
              onChange={onFileChange}
              style={{ maxWidth: "40rem" }}
              required
              type="file"
              name="file"
            />
          </Form.Group> */}
          <input type="file" onChange={onFileChange} />
          <br />
          <br />
          <Button disabled={loading} className=" w-20 mx-auto" type="submit">
            {!loading ? (
              "Import"
            ) : (
              <>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>&nbsp;Uploading...</span>
              </>
            )}
          </Button>
          &nbsp; &nbsp;
          <Button className="btn-reset w-20 mx-auto" type="reset">
            Reset
          </Button>
        </Form>
      </div>
    </>
  );
};

export default CarSpecFile;
