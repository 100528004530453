import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { axiosAdmin, axiosPrivate } from "../../api/axios";
import { Button, Select } from "antd";
import { toast } from "react-toastify";

const CompetModels = () => {
  const [viewSegment, setViewSegment] = useState();
  const [viewModel, setViewModel] = useState();
  const [bmModel, setBmModel] = useState();
  const [viewCountry, setViewCountry] = useState();
  const [viewCountryData, setViewCountryData] = useState();
  const [viewModelData, setViewModelData] = useState();
  const [viewCompetData, setViewCompetData] = useState();
  const [modelData, setModelData] = useState();
  const [competModelData, setCompetModelData] = useState();
  const [competSelected, setCompetSelected] = useState();
  const [options, setOptions] = useState();
  const [defaultValues, setDefaultValues] = useState([]);
  const [allModels, setAllModels] = useState();

  console.log("options", options);
  console.log("default", defaultValues);

  //   useEffect(() => {
  //   viewModel && axiosAdmin.
  // }, [viewModel])

  useEffect(() => {
    axiosAdmin
      .get("country/getAllCountries")
      .then((res) => {
        setViewCountryData(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  useEffect(() => {
    setOptions([]);
    axiosAdmin
      .get("model/getAllModels")
      .then((res) => {
        res?.data?.map((item) => {
          item?.brands?.code !== "20" &&
            setOptions((prev) => [
              ...prev,
              { label: item?.name, value: item?.code },
            ]);
        });
      })
      .catch((e) => Promise.reject(e));
  }, []);

  useEffect(() => {
    console.log("segment", viewCountry, viewSegment);
    viewSegment &&
      viewCountry &&
      axiosAdmin
        .post("model/benchmark", {
          segment: Number(viewSegment),
          countryId: Number(viewCountry),
        })
        .then((res) => {
          console.log("resdata".res?.data);
          setViewModelData(res?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [viewSegment, viewCountry]);

  ////////////////////////////Add/Update/////////////////////////////

  useEffect(() => {
    setCompetSelected([]);
    viewSegment &&
      viewCountry &&
      bmModel &&
      axiosPrivate
        .post("competModels/models", {
          segment: Number(viewSegment),
          countryId: Number(viewCountry),
          modelCode: bmModel,
        })
        .then((res) => {
          res?.data?.data?.map((item) => {
            setCompetSelected((prev) => [...prev, item?.code]);
          });
        })
        .catch((e) => Promise.reject(e));

    viewSegment &&
      viewCountry &&
      bmModel &&
      axiosPrivate
        .post("competModels/models", {
          segment: Number(viewSegment),
          countryId: Number(viewCountry),
          modelCode: bmModel,
        })
        .then((res) => {})
        .catch((e) => Promise.reject(e));
  }, [viewSegment, viewCountry, bmModel]);

  const handleCountryChange = (e) => {
    setCompetSelected();
    setViewCompetData([]);
    setViewModelData([]);
    setBmModel();
    setViewCountry(e.target.value);
  };
  const handleSegmentChange = (e) => {
    setCompetSelected();
    setViewCompetData([]);
    setViewModelData([]);
    setBmModel();
    setViewCountry();
    setViewSegment(e.target.value);
  };
  const handleModelChange = (e) => {
    setBmModel(e.target.value);
  };
  const handleCompetChange = (value) => {
    console.log("vallue", value);
    setCompetSelected(value);
  };

  const onUpdate = () => {
    competSelected?.length
      ? axiosPrivate
          .post("competModels/create", {
            segment: Number(viewSegment),
            countryId: Number(viewCountry),
            bmModelCode: bmModel,
            competCodes: competSelected,
          })
          .then((res) => {
            res?.data?.success && toast.success("Competitive models added");

            viewSegment &&
              viewCountry &&
              viewModel &&
              axiosPrivate
                .post("competModels/models", {
                  segment: Number(viewSegment),
                  countryId: Number(viewCountry),
                  modelCode: viewModel,
                })
                .then((res) => {
                  setViewCompetData(res?.data?.data);
                })
                .catch((e) => Promise.reject(e));
          })
          .catch((err) => {
            Promise.reject(err);
          })
      : toast.error("Plese select competitive model");
  };

  console.log("competSelected", competSelected);

  return (
    <>
      {" "}
      <div className="competModels">
        <div className="competModels__header">
          <h4>Add/Update Competitive Models</h4>
        </div>
        <br />
        <div className="competModels_view">
          <Form.Group className="mb-4">
            <Form.Label>{`Segment`}</Form.Label>
            <Form.Select
              required
              onChange={handleSegmentChange}
              aria-label="Default select example"
            >
              <option value="">Select Segment</option>
              <option value={1} name={"Segment"}>
                Retail
              </option>
              <option value={2} name={"Segment"}>
                Fleet
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{`Country`}</Form.Label>
            <Form.Select
              required
              onChange={handleCountryChange}
              aria-label="Default select example"
            >
              <option value="">Select Country</option>
              {viewCountryData &&
                viewCountryData?.map((country) => {
                  return (
                    country.status && (
                      <option
                        // selected={country.id == editTrimData?.countrys?.id}
                        name="country"
                        key={country.id}
                        value={country.id}
                      >
                        {country.name}
                      </option>
                    )
                  );
                })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{`Benchmark Models`}</Form.Label>
            <Form.Select
              required
              onChange={handleModelChange}
              aria-label="Default select example"
            >
              <option value="">Select Model</option>
              {viewModelData &&
                viewModelData?.map((model) => {
                  return (
                    model.status && (
                      <option
                        // selected={model.id == editTrimData?.models?.id}
                        name="model"
                        key={model.id}
                        value={model.code}
                      >
                        {model.name}
                      </option>
                    )
                  );
                })}
            </Form.Select>
          </Form.Group>

          {options?.length ? (
            <Select
              // value={competSelected}
              lab
              allowClear
              style={{
                width: "100%",
              }}
              value={competSelected}
              mode="multiple"
              maxTagCount="responsive"
              placeholder="Competitive models"
              size="large"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toUpperCase().includes(input.toUpperCase())
              }
              id="modal-data"
              onChange={handleCompetChange}
              options={options?.length ? options : []}
            />
          ) : (
            ""
          )}
        </div>
        <br />
        <Button onClick={onUpdate} type="primary">
          Update
        </Button>
      </div>{" "}
    </>
  );
};

export default CompetModels;
