import React from "react";

import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";

const DashboardBreadcrumb = () => {
  const breadcrumbNames = {
    "/admin": "Admin",
    "/admin/brand": "Brand",
    "/admin/countries": "Countries",
    "/admin/models": "Models",
    "/admin/car-type": "Car Type",
    "/admin/users": "Users",
    "/admin/trim-type": "Trim Type",
    "/admin/import": "Import",
    "/admin/trim": "Trims",
  };

  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbItems = pathSnippets.map((_, i) => {
    const url = `/${pathSnippets.slice(0, i + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={i}>
        <Link to={url}>{breadcrumbNames[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <>
      <div className="breadcrumb">
        <Breadcrumb separator="-">{breadcrumbItems}</Breadcrumb>
      </div>
    </>
  );
};

export default DashboardBreadcrumb;
