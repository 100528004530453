import React, { useState } from "react";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { axiosAdmin } from "../../../api/axios";

const UpdateUserPassword = ({ show, setShow, editUserData }) => {
  const [userData, setUserData] = useState(null);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const handleupdateUserPassword = (e) => {
    e.preventDefault();
    if (userData?.password === userData?.confirmPassword) {
      axiosAdmin
        .patch("user/updateUserPassword", {
          password: userData?.password,
          id: editUserData?.id,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Password Updated", {
              position: "top-center",
              autoClose: 2000,
            });
            setShow(false);
            setUserData({
              ...userData,
              password: "",
              confirmPassword: "",
            });
          }
        })
        .catch((e) => {
          Promise.reject(e);
        });
    } else {
      toast.error("No Match", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`Password Update`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleupdateUserPassword} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`New password`}</Form.Label>
              <Form.Control
                required
                isValid={false}
                onChange={handleUserChange}
                type="password"
                placeholder={`Enter New password`}
                value={userData?.password}
                name="password"
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>{`Confirm Password`}</Form.Label>
              <Form.Control
                required
                onChange={handleUserChange}
                type="password"
                placeholder={`Enter Confirm Password`}
                value={userData?.confirmPassword}
                name="confirmPassword"
              />
            </Form.Group>

            <Button className=" w-100 mx-auto" type="submit">
              Update Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateUserPassword;
