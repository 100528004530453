import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./app.css";
import "./sass/main.scss";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Brands from "./components/admin/Brands";
import Users from "./components/admin/Users";
import Currencies from "./components/admin/Countries";
import YourChoice from "./components/user/YourChoice";
import DashboardUser from "./components/user/DashboardUser";
import PriceTrend from "./components/user/TransactionPriceTrend";
import SupportingDocuments from "./components/user/SupportingDocuments";
import Models from "./components/admin/Models";
import CarType from "./components/admin/CarType";
import TrimType from "./components/admin/TrimType";
import ImportData from "./components/admin/ImportData";
import Login from "./components/admin/Login";
import ProtectedRoute from "./components/admin/ProtectedRoute";
import Trims from "./components/admin/Trims";
import PriceLadder from "./components/user/PriceLadder";
import PricingSummary from "./components/user/PricingSummary";
import FixedSegments from "./components/user/FixedSegments";
import UserLogin from "./components/user/UserLogin";
import ForgotPass from "./components/user/ForgotPass";
import SummaryOfModels from "./components/user/summaryOfModels";
import CarSpecFile from "./components/admin/CarSpecFile";
import PageNotFound from "./components/404";
import Exports from "./components/user/Exports";
import PPTReport from "./components/user/pptReport";
import PrivateRoute from "./components/user/PrivateRoute";
import CompetModels from "./components/admin/competModels";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/admin/login" element={<Login />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/user/fogotpass" element={<ForgotPass />} />
          <Route path="/" element={<Home />}>
            <Route
              path="/your-choice"
              element={
                <PrivateRoute>
                  <YourChoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <PricingSummary />
                </PrivateRoute>
              }
            />

            <Route
              path="/transaction-price-trend"
              element={
                <PrivateRoute>
                  <PriceTrend />
                </PrivateRoute>
              }
            />
            <Route
              path="/price-ladder"
              element={
                <PrivateRoute>
                  <PriceLadder />
                </PrivateRoute>
              }
            />
            <Route
              path="/pricing-summary"
              element={
                <PrivateRoute>
                  <PricingSummary />
                </PrivateRoute>
              }
            />
            <Route
              path="/summary-of-models"
              element={
                <PrivateRoute>
                  <SummaryOfModels />
                </PrivateRoute>
              }
            />
            <Route
              path="/fixed-segments"
              element={
                <PrivateRoute>
                  <FixedSegments />
                </PrivateRoute>
              }
            />
            <Route path="/exports" element={<Exports />} />
            <Route path="/ppt-reports" element={<PPTReport />} />
            <Route
              path="/supporting-documents"
              element={
                <PrivateRoute>
                  <SupportingDocuments />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/admin/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route
              path="/admin/brand"
              element={
                <ProtectedRoute>
                  <Brands />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/countries"
              element={
                <ProtectedRoute>
                  <Currencies />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/models"
              element={
                <ProtectedRoute>
                  <Models />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/competitive-models"
              element={
                <ProtectedRoute>
                  <CompetModels />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/car-type"
              element={
                <ProtectedRoute>
                  <CarType />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/trim-type"
              element={
                <ProtectedRoute>
                  <TrimType />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/import"
              element={
                <ProtectedRoute>
                  <ImportData />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/car-spec-file"
              element={
                <ProtectedRoute>
                  <CarSpecFile />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/trim"
              element={
                <ProtectedRoute>
                  <Trims />{" "}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
