import React, { useState, useRef, useEffect } from "react";
import { Popover, Select } from "antd";
import * as BsIcons from "react-icons/bs";
import { useDownloadExcel } from "react-export-table-to-excel";
import { axiosPrivate } from "../../api/axios";
import { waveMonths } from "../../data/wave-month-data";

const PricingSummary = () => {
  const [countryData, setCountryData] = useState();
  const [bmModelData, setBmModelData] = useState();
  const [countrySelected, setCountrySelected] = useState();
  const [modelSelected, setModelSelected] = useState();
  const [modelName, setModelName] = useState();
  const [wavesData, setWavesData] = useState();
  const [waveSelected, setWaveSelected] = useState();
  const [segment, setSegment] = useState(true);
  const [trimsData, setTrimsData] = useState(null);
  const [bmTrimsData, setBmTrimsData] = useState();
  const [modelData, setModelData] = useState([]);
  const [currency, setCurrency] = useState(true);
  const [currencyCode, setCurrencyCode] = useState("AED");
  const [VAT, setVAT] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [breakdownData, setBreakdownData] = useState([]);

  //   modelData.map((data) => {
  //     data.trims.map((item) => {
  //       // console.log("modelName", item);
  //     });
  //   });
  // }
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    if (Number(countrySelected) === 1 && currency) {
      setCurrencyCode("AED");
      setVAT(5);
    } else if (Number(countrySelected) === 2 && currency) {
      setCurrencyCode("SAR");
      setVAT(15);
    } else if (Number(countrySelected) === 3 && currency) {
      setCurrencyCode("EGP");
      setVAT(14);
    } else {
      setCurrencyCode("AED");
    }
  }, [countrySelected]);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
    axiosPrivate
      .get("wave")
      .then((res) => {
        let waves = [];
        res.data.data.map((item) => {
          waves.push({
            month: waveMonths[Number(String(item.code).slice(-2)) - 1],
            year: String(item.code).slice(0, 2),
            code: item.code,
            id: item.id,
          });
        });
        setWavesData(waves);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  useEffect(() => {
    countrySelected &&
      axiosPrivate
        .post("competModels/benchmarkModels", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
        })
        .then((res) => {
          setBmModelData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [countrySelected, segment]);

  useEffect(() => {
    ////// for benchmark data
    modelSelected &&
      waveSelected &&
      axiosPrivate
        .post(`yourChoice/trims`, {
          countryId: Number(countrySelected),
          month: Number(String(waveSelected).slice(-2)),
          year: Number("20" + String(waveSelected).slice(0, 2)),
          modelCode: modelSelected,
          segment: segment ? 1 : 2,
        })
        .then((res) => {
          const sorted = res?.data?.data?.sort(
            (a, b) => a?.cdh_msrp - b?.cdh_msrp
          );
          setBmTrimsData(sorted);
        })
        .catch((e) => Promise.reject(e));
  }, [modelSelected, segment, waveSelected, countrySelected]);

  useEffect(() => {
    //// for compare data
    modelSelected &&
      waveSelected &&
      countrySelected &&
      axiosPrivate
        .post("competModels", {
          countryId: Number(countrySelected),
          month: Number(String(waveSelected).slice(-2)),
          year: Number("20" + String(waveSelected).slice(0, 2)),
          segment: segment ? 1 : 2,
          modelCode: modelSelected,
        })
        .then((res) => {
          setModelData(res.data.data);
          let modelRes = res.data.data;
          modelRes?.map((item, i) => {
            axiosPrivate
              .post(`yourChoice/trims`, {
                countryId: Number(countrySelected),
                month: Number(String(waveSelected).slice(-2)),
                year: Number("20" + String(waveSelected).slice(0, 2)),
                modelCode: item,
                segment: segment ? 1 : 2,
              })
              .then((response) => {
                setTrimsData((prev) => ({
                  ...prev,
                  [item]: response.data.data,
                }));
              })
              .catch((e) => Promise.reject(e));
          });
        })
        .catch((e) => Promise.reject(e));
  }, [modelSelected, segment, waveSelected, countrySelected]);

  const handleCountryChange = (value) => {
    setCountrySelected(value);
  };

  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  const handleModalChange = (value, data) => {
    setModelName(data.children);
    setModelSelected(value);
  };
  const handleWaveChange = (value) => {
    setWaveSelected(value);
  };

  const handleBreakdownOffer = (value) => {
    setShowPopup(true);
    axiosPrivate
      .get(`extraFeatures/${value}`)
      .then((res) => {
        setBreakdownData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
  };
  const handleOpenChange = (newOpen) => {
    setShowPopup(newOpen);
  };
  // const hidePopup = () => {
  //   setShowPopup(false);
  // };
  // console.log(showPopup);

  return (
    <>
      <div className="pricing-summary">
        <div className="main-header">
          <div className="main-toggle">
            <p className="rtl">RTL</p>
            <div
              onClick={() => setSegment((prev) => !prev)}
              className={`toggle ${segment ? "right" : "left"}`}
            >
              <div className="toggle__toggler"></div>
            </div>
            <p className="sme">SME</p>
          </div>
          <div className="vat">
            {VAT ? `*All Values Including ${VAT}% VAT` : ""}
          </div>
        </div>
        <div className="pricing-summary__controls">
          <Select
            defaultValue="Country"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={handleCountryChange}
          >
            {countryData &&
              countryData?.map((item) => (
                <Select.Option value={item.code} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>

          <Select
            defaultValue="Model"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            id="modal-data"
            onChange={handleModalChange}
          >
            {bmModelData &&
              bmModelData?.map((item) => (
                <Select.Option value={item.code} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
          <Select
            defaultValue="Wave"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={handleWaveChange}
          >
            {wavesData?.map((wave, i) => (
              <Select.Option key={wave.id} value={wave.code}>
                {wave.month + "-" + wave.year}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="data-options">
          <h5>Pricing Summary</h5>
          <div className="data-options__tools">
            <span onClick={onDownload}>
              <BsIcons.BsFileEarmarkExcel />
            </span>
            <span>|</span>
            <div className="main-toggle">
              <p className="rtl">{currencyCode}</p>
              <div
                onClick={() => setCurrency((prev) => !prev)}
                className={`toggle ${currency ? "right" : "left"}`}
              >
                <div className="toggle__toggler"></div>
              </div>
              <p className="USD">USD</p>
            </div>
          </div>
        </div>
        <div className="user-table">
          <table ref={tableRef} id="l">
            <thead>
              <tr>
                <th>Model Name</th>
                <th>Trim Name</th>
                <th>Trim Name Spec</th>
                <th>Trim Code</th>
                <th>Model Year</th>
                <th>MSRP</th>
                <th>Cash Discount</th>
                <th>Value of other Offers(vo)</th>
                <th>Transaction Price(TP = MSRP - CD)</th>
                <th>Total Offer Value (CD + VO)</th>
                <th>Net Transaction Price(NTP=TP-VO)</th>
                {/* <th>Promotions</th> */}
              </tr>
            </thead>
            <tbody>
              {bmTrimsData?.map((item, i) => (
                <tr style={{ borderBottom: "1px solid #0000003d" }} key={i}>
                  <td
                    style={{
                      borderTop: ` ${i !== 0 ? "1px solid #fff" : ""}`,
                      borderRight: "1px solid #0000003d",
                    }}
                  >
                    {i === 0 ? item.brandName + " " + item.modelName : ""}
                  </td>
                  <td>{item.name}</td>
                  <td>{item?.trim_name_spec}</td>
                  <td>{item.code}</td>
                  <td>{item.carYear}</td>
                  <td>
                    {numberWithCommas(
                      currency ? item.cdh_msrp : item.cdh_msrp_dollar
                    )}
                  </td>
                  <td>
                    {numberWithCommas(
                      currency
                        ? item.cdh_cash_discount
                        : item.cdh_cash_discount_dollar
                    )}
                  </td>
                  <Popover
                    content={
                      <>
                        {/* <span
                          onClick={hidePopup}
                          className="breakdownOffer_close"
                        >
                          <BsIcons.BsXLg />
                        </span> */}
                        <div
                          onClick={() => handleBreakdownOffer(item?.cdh_id)}
                          className="breakdownOffer"
                        >
                          {breakdownData?.map((item, i) => (
                            <>
                              <div key={i}>
                                <div className="breakdownOffer_option">
                                  {item.name}
                                </div>
                                <div className="breakdownOffer_value">
                                  <span>Option: </span> {item.key}
                                </div>
                                {item.value && (
                                  <div className="breakdownOffer_value">
                                    {" "}
                                    <span>Value: </span> {item.value}
                                  </div>
                                )}
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    }
                    trigger="click"
                    open={showPopup}
                    onOpenChange={handleOpenChange}
                  >
                    <td
                      onClick={() => handleBreakdownOffer(item?.cdh_id)}
                      style={{
                        color: "#0097ce",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      {numberWithCommas(
                        currency
                          ? item.cdh_total_offer_value
                          : item.cdh_total_offer_value_dollar
                      )}
                    </td>
                  </Popover>
                  <td>
                    {numberWithCommas(
                      currency
                        ? item.cdh_transaction_price
                        : item.cdh_transaction_price_dollar
                    )}
                  </td>
                  <td>
                    {numberWithCommas(
                      currency
                        ? item.cdh_cash_discount + item.cdh_total_offer_value
                        : item.cdh_cash_discount_dollar +
                            item.cdh_total_offer_value_dollar
                    )}
                  </td>
                  <td>
                    {numberWithCommas(
                      currency
                        ? item.cdh_transaction_price -
                            item.cdh_total_offer_value
                        : item.cdh_transaction_price_dollar -
                            item.cdh_total_offer_value_dollar
                    )}
                  </td>
                  {/* <td></td> */}
                </tr>
              ))}
              {
                trimsData &&
                  modelData?.map((data, i) => {
                    let allTrims = { ...trimsData };
                    let trim = allTrims[data];
                    trim && trim.map((item) => {});
                    let column = 0;
                    // Math.floor(trim?.length / 2);
                    return (
                      <>
                        {trim
                          ?.sort((a, b) => a?.cdh_msrp - b?.cdh_msrp)
                          ?.map((trim, index) => {
                            return (
                              // trimsData &&
                              // trimsData[data.code]?.map((item) => {
                              //   return (
                              <tr
                                key={index}
                                style={{
                                  borderTop: "1px solid #0000003d",
                                  borderBottom: "1px solid #0000003d",
                                }}
                              >
                                <td
                                  style={{
                                    borderTop: ` ${
                                      index !== 0 ? "1px solid #fff" : ""
                                    }`,
                                    borderRight: "1px solid #0000003d",
                                  }}
                                >
                                  {index === column
                                    ? trim.brandName + " " + trim.modelName
                                    : ""}
                                </td>
                                <td>{trim.name}</td>
                                <td>{trim?.trim_name_spec}</td>
                                <td>{trim.code}</td>
                                <td>{trim.carYear}</td>
                                <td>
                                  {numberWithCommas(
                                    currency
                                      ? trim.cdh_msrp
                                      : trim.cdh_msrp_dollar
                                  )}
                                </td>

                                <td>
                                  {numberWithCommas(
                                    currency
                                      ? trim.cdh_cash_discount
                                      : trim.cdh_cash_discount_dollar
                                  )}
                                </td>
                                <Popover
                                  content={
                                    <>
                                      {/* <span
                                      onClick={() => setShowPopup(false)}
                                      className="breakdownOffer_close"
                                    >
                                      <BsIcons.BsXLg />
                                    </span> */}
                                      <div
                                        onClick={() =>
                                          handleBreakdownOffer(trim?.cdh_id)
                                        }
                                        className="breakdownOffer"
                                      >
                                        {breakdownData?.map((item, i) => (
                                          <>
                                            <div key={i}>
                                              <div className="breakdownOffer_option">
                                                {item.name}
                                              </div>
                                              <div className="breakdownOffer_value">
                                                <span>Option: </span> {item.key}
                                              </div>
                                              {item.value && (
                                                <div className="breakdownOffer_value">
                                                  {" "}
                                                  <span>Value: </span>{" "}
                                                  {item.value}
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        ))}
                                      </div>
                                    </>
                                  }
                                  trigger="click"
                                  open={showPopup}
                                >
                                  {" "}
                                  <td
                                    onClick={() =>
                                      handleBreakdownOffer(trim?.cdh_id)
                                    }
                                    style={{
                                      color: "#0097ce",
                                      cursor: "pointer",
                                      userSelect: "none",
                                    }}
                                  >
                                    {numberWithCommas(
                                      currency
                                        ? trim.cdh_value_other_offer
                                        : trim.cdh_value_other_offer_dollar
                                    )}
                                  </td>
                                </Popover>
                                <td>
                                  {numberWithCommas(
                                    currency
                                      ? trim.cdh_transaction_price
                                      : trim.cdh_transaction_price_dollar
                                  )}
                                </td>
                                <td>
                                  {numberWithCommas(
                                    currency
                                      ? trim.cdh_total_offer_value
                                      : trim.cdh_total_offer_value_dollar
                                  )}
                                </td>
                                <td>
                                  {numberWithCommas(
                                    currency
                                      ? trim.cdh_net_transaction_price
                                      : trim.cdh_net_transaction_price_dollar
                                  )}
                                </td>
                                {/* <td>0</td> */}
                              </tr>
                              //   );
                              // })
                            );
                          })}
                      </>
                    );
                  })

                // ));

                //));
              }
            </tbody>
          </table>
        </div>
        {/* <PricingSummaryTable
          modelData={modelData}
          bmTrimsData={bmTrimsData}
          data={tableData}
          tableRef={tableRef}
          modelSelected={modelSelected}
          modelName={modelName}
        /> */}
      </div>
    </>
  );
};

export default PricingSummary;
