import React, { useEffect, useState } from "react";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { waveMonths } from "../../../data/wave-month-data";
import { axiosPrivate } from "../../../api/axios";
const ExcelExportHelper = ({
  bmData,
  currencyFactor,
  currencyCode,
  compareData,
  countryName,
  modelName,
  wave,
  currency,
}) => {
  const [features, setFeatures] = useState();
  const [headerFeatures, setHeaderFeatures] = useState([]);
  const [bmFeatureValues, setBmFeatureValues] = useState();
  const [compareFeatureValues, setComareFeatureValues] = useState({});

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const cells = ["F", "G", "H", "I", "J", "K", "L", "M", "N", "O", ""];

  useEffect(() => {
    axiosPrivate
      .get("features")
      .then((res) => {
        const fea = res?.data?.data;
        setHeaderFeatures([]);
        let tmp = [];
        for (let i = 0; i < 3; i++) {
          tmp.push(fea[i]);
        }
        setHeaderFeatures(tmp);
        // setFeatures(res?.data?.data);

        fea.shift();
        fea.shift();
        setFeatures(fea);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  useEffect(() => {
    bmData?.cdh_id &&
      axiosPrivate
        .post("features", {
          cdh_id: bmData?.cdh_id,
        })
        .then((res) => {
          setBmFeatureValues(res?.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [bmData]);
  useEffect(() => {
    compareData &&
      Object.keys(compareData).map((key, index) => {
        compareData[key]?.cdh_id &&
          axiosPrivate
            .post("features", {
              cdh_id: compareData[key]?.cdh_id,
            })
            .then((res) => {
              setComareFeatureValues((prev) => ({
                ...prev,
                [index]: res?.data?.data,
              }));
            })
            .catch((e) => Promise.reject(e));
      });
  }, [compareData]);

  useEffect(() => {
    if (features) {
    }
  }, [features]);
  // useEffect(() => {
  //   if (headerFeatures?.length && features) {
  //     let fea = features;
  //     fea.shift();
  //     fea.shift();
  //     setFeatures(fea);
  //   }
  // }, [headerFeatures]);

  const fileName = "zip.xlsx";

  const wb = new Excel.Workbook();
  const ws = wb.addWorksheet("My Sheet");

  ws.getCell("A1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "fff" },
    bgColor: { argb: "fff" },
  };
  ws.getRow(1).values = [
    "GSO Mandatory items",
    "Newly Added ",
    "Description updated",
    "Checked",
    "Online / Assumed",
  ];
  ws.getColumn(1).width = "40";
  ws.getColumn(2).width = "60";
  ws.getColumn(3).width = "40";
  ws.getColumn(4).width = "20";
  ws.getColumn(5).width = "15";

  const widthRet = () => {
    let widthArr = [];

    for (let i = 6; i <= 20; i++) {
      widthArr.push((ws.getColumn(i).width = "20"));
    }
    return widthArr;
  };
  widthRet();

  ws.getCell("A1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ffe0b3" },
    bgColor: { argb: "ffe0b3" },
  };
  ws.getCell("B1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "80aaff" },
    bgColor: { argb: "80aaff" },
  };
  ws.getCell("C1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "3385ff" },
    bgColor: { argb: "3385ff" },
  };
  ws.getCell("D1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ffff80" },
    bgColor: { argb: "ffff80" },
  };
  ws.getCell("E1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "b3ffb3" },
    bgColor: { argb: "b3ffb3" },
  };
  ws.getCell("A2").value = `${countryName}  ${modelName} VIVA `;
  ws.getCell("A2").font = { bold: true, size: "25" };
  ws.getRow(2).height = "70";
  ws.getCell("A3").value = " ";
  // waveMonths[Number(String(wave).slice(-2)) - 1] +
  // "," +
  // String(wave).slice(0, 2);
  ws.getCell("A3").font = { bold: true, size: "18" };
  ws.getRow(3).height = "50";

  ws.getCell("A5").value = "Country";
  ws.getCell("E5").value = countryName;
  ws.getCell("E5").border = {
    right: { style: "medium", color: { argb: "000000" } },
    left: { style: "medium", color: { argb: "000000" } },
    bottom: { style: "medium", color: { argb: "000000" } },
    top: { style: "medium", color: { argb: "000000" } },
  };
  ws.getCell("E5").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ffff80" },
    bgColor: { argb: "ffff80" },
  };

  ws.getCell("E5").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("E5").font = {
    bold: true,
  };

  //   ws.getCell("A6").value = "Country";
  //   ws.getCell("F6").value = bmData?.brands?.name;

  //   const Engine = compareData[0];
  //   const Transmission = compareData[1];
  //   const Diamension = compareData[2];
  // Object.keys(compareData).map((key, index) => {

  // }
  const headerTableData = () => {
    let headerData = { columns: [], rows: [] };
    let model = [];
    let carYear = [];
    let trimCode = [];
    let trim = [];

    if (bmData && compareData) {
      Object.keys(compareData).map((key, index) => {
        compareData[key]?.brands?.name &&
          headerData.columns.push({ name: compareData[key]?.brands?.name });
        compareData[key]?.models?.name &&
          model.push(compareData[key]?.models?.name);
        compareData[key]?.cdh_car_year &&
          carYear.push(
            `MY${String(compareData[key]?.cdh_car_year)?.slice(-2)}`
          );
        compareData[key]?.trim_code_ext &&
          trimCode.push(compareData[key]?.trim_code_ext);
        compareData[key]?.trims?.name &&
          trim.push(compareData[key]?.trims?.name);
        index === Object.keys(compareData)?.length - 1 &&
          (headerData.rows = [model, carYear, trimCode, trim]);
      });
    }
    return headerData;
  };

  const getFeaVal = (id) => {
    compareFeatureValues = Object.keys(compareFeatureValues)?.map(
      (key, index) => {
        return compareFeatureValues[key][id];
      }
    );
  };
  const mainTableData = () => {
    let col = [];
    compareFeatureValues &&
      Object.keys(compareFeatureValues).map((key, i) => {
        col.push({ name: " " });
      });
    let headerData = {
      columns: [
        { name: " " },
        { name: " " },
        { name: " " },
        { name: " " },
        { name: " " },
        { name: " " },
      ],
      rows: [],
    };

    let rows = [];
    if (compareData && bmData && features && bmFeatureValues) {
      features?.map((feature, index) => {
        feature?.other_featurelist?.map((sub, inde) => {
          if (!sub?.other_featurelist?.length) {
            inde === 0
              ? headerData.rows.push([
                  feature?.featurelist_name,
                  sub?.featurelist_name,
                  " ",
                  sub?.featurelist_value,
                  Math.round(sub?.featurelist_value * currencyFactor),
                  bmFeatureValues &&
                  bmFeatureValues[sub?.featurelist_feature_id] != 0
                    ? bmFeatureValues[sub?.featurelist_feature_id]
                    : " ",
                ])
              : headerData.rows.push([
                  " ",
                  sub?.featurelist_name,
                  " ",
                  sub?.featurelist_value,
                  Math.round(sub?.featurelist_value * currencyFactor),
                  bmFeatureValues &&
                  bmFeatureValues[sub?.featurelist_feature_id] == 0
                    ? " "
                    : bmFeatureValues[sub?.featurelist_feature_id],
                ]);
          } else {
            sub?.other_featurelist?.map((sub1, i) => {
              if (i === 0 && inde === 0) {
                headerData.rows.push([
                  feature?.featurelist_name,
                  sub?.featurelist_name,
                  sub1?.featurelist_name,
                  sub1?.featurelist_value,
                  Math.round(sub1?.featurelist_value * currencyFactor),
                  bmFeatureValues &&
                  bmFeatureValues[sub1?.featurelist_feature_id] == 0
                    ? " "
                    : bmFeatureValues[sub?.featurelist_feature_id],
                ]);
              } else if (i === 0) {
                headerData.rows.push([
                  "",
                  sub?.featurelist_name,
                  sub1?.featurelist_name,
                  sub1?.featurelist_value,
                  Math.round(sub1?.featurelist_value * currencyFactor),
                  bmFeatureValues &&
                  bmFeatureValues[sub1?.featurelist_feature_id] == 0
                    ? " "
                    : bmFeatureValues[sub?.featurelist_feature_id],
                ]);
              } else {
                headerData.rows.push([
                  " ",
                  " ",
                  sub1?.featurelist_name,
                  sub1?.featurelist_value,
                  Math.round(sub1?.featurelist_value * currencyFactor),
                  bmFeatureValues &&
                  bmFeatureValues[sub1?.featurelist_feature_id] == 0
                    ? " "
                    : bmFeatureValues[sub?.featurelist_feature_id],
                ]);
              }

              // headerData.rows.push([" ", " ", sub1?.featurelist_name]);
            });
          }
        });
      });
    }
    return headerData;
  };

  const feaHeadTable = () => {
    let col = [];
    headerFeatures &&
      Object.keys(headerFeatures).map((key, i) => {
        col.push({ name: " " });
      });
    let headerData = {
      columns: [
        { name: " " },
        { name: " " },
        { name: " " },
        { name: " " },
        { name: " " },
        { name: " " },
      ],
      rows: [],
    };
    if (
      compareData &&
      bmData &&
      features &&
      headerFeatures &&
      bmFeatureValues
    ) {
      headerFeatures?.map((feature, index) => {
        feature?.other_featurelist?.map((sub, inde) => {
          inde === 0
            ? headerData.rows.push([
                feature?.featurelist_name,
                sub?.featurelist_name,
                " ",
                sub?.featurelist_value,
                Math.round(sub?.featurelist_value * currencyFactor),

                bmFeatureValues &&
                bmFeatureValues[sub?.featurelist_feature_id] != 0
                  ? bmFeatureValues[sub?.featurelist_feature_id]
                  : " ",
              ])
            : headerData.rows.push([
                " ",
                sub?.featurelist_name,
                " ",
                sub?.featurelist_value,
                Math.round(sub?.featurelist_value * currencyFactor),

                bmFeatureValues &&
                bmFeatureValues[sub?.featurelist_feature_id] == 0
                  ? " "
                  : bmFeatureValues[sub?.featurelist_feature_id],
              ]);
        });
      });
    }

    return headerData;
  };
  const table0 = feaHeadTable();

  ws.addTable({
    name: "headwerFeatures",
    ref: "A10",
    //   headerRow: false,
    style: {
      theme: "TableStyleLight1",
    },
    ...table0,
  });
  const valuesTable = () => {
    let col = [];
    compareFeatureValues &&
      Object.keys(compareFeatureValues).map((key, i) => {
        col.push({ name: " " });
      });
    let headerData = {
      columns: [...col],
      rows: [],
    };

    let rows = [];
    if (compareData && bmData && features) {
      features?.map((feature, index) => {
        feature?.other_featurelist?.map((sub, inde) => {
          if (!sub?.other_featurelist?.length) {
            let tmp = [];

            compareFeatureValues &&
              Object.keys(compareFeatureValues)?.map((key, index) => {
                tmp.push(
                  compareFeatureValues[key][sub?.featurelist_feature_id] == 0
                    ? " "
                    : compareFeatureValues[key][sub?.featurelist_feature_id]
                );
              });
            headerData.rows.push(tmp);
          } else {
            sub?.other_featurelist?.map((sub1, i) => {
              let tmp = [];
              compareFeatureValues &&
                Object.keys(compareFeatureValues)?.map((key, index) => {
                  tmp.push(
                    compareFeatureValues[key][sub1?.featurelist_feature_id] == 0
                      ? " "
                      : compareFeatureValues[key][sub1?.featurelist_feature_id]
                  );
                });

              headerData.rows.push(tmp);
              // headerData.rows.push([" ", " ", sub1?.featurelist_name]);
            });
          }
        });
      });
    }
    return headerData;
  };
  const headerValues = () => {
    let col = [];
    compareFeatureValues &&
      Object.keys(compareFeatureValues).map((key, i) => {
        col.push({ name: " " });
      });
    let headerData = {
      columns: [...col],
      rows: [],
    };

    let rows = [];
    if (compareData && bmData && features && headerFeatures) {
      headerFeatures?.map((feature, index) => {
        feature?.other_featurelist?.map((sub, inde) => {
          let tmp = [];

          compareFeatureValues &&
            Object.keys(compareFeatureValues)?.map((key, index) => {
              tmp.push(
                compareFeatureValues[key][sub?.featurelist_feature_id] == 0
                  ? " "
                  : compareFeatureValues[key][sub?.featurelist_feature_id]
              );
            });
          headerData.rows.push(tmp);
        });
      });
    }
    return headerData;
  };

  const table2 = mainTableData();
  ws.addTable({
    name: "mainTable",
    ref: "A19",
    //   headerRow: false,
    style: {
      theme: "TableStyleLight1",
    },
    ...table2,
  });

  ws.getColumn(6).alignment = {
    vertical: "middle",
    horizontal: "center",
  };

  ws.mergeCells(18, 1, 18, 3);
  ws.getCell("A18").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "cacdce" },
    bgColor: { argb: "cacdce" },
  };
  ws.getCell("A18").value = "Currency";

  ws.getCell("D18").value = "USD";
  ws.getCell("D18").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "cacdce" },
    bgColor: { argb: "cacdce" },
  };

  ws.getCell("E18").value = currencyCode;
  ws.getCell("E18").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "cacdce" },
    bgColor: { argb: "cacdce" },
  };

  ws.mergeCells(19, 1, 19, 3);

  ws.getCell("A19").value = "Exchange Rate";

  ws.getCell("D19").value = 1.0;

  ws.getCell("E19").value = currencyFactor;

  // ws.addConditionalFormatting({
  //   ref: "F13:F70",
  //   rules: [
  //     {
  //       type: "expression",
  //       formulae: ["MOD(-3,2)=1"],
  //       style: {
  //         fill: {
  //           type: "pattern",
  //           pattern: "solid",
  //           bgColor: { argb: "FF00FF00" },
  //         },
  //       },
  //     },
  //   ],
  // });

  ws.getCell("A6").value = "Maker";
  ws.getCell("F6").value = bmData?.brands?.name;

  ws.getCell("A7").value = "Model";
  ws.getCell("F7").value = bmData?.models?.name;

  ws.getCell("A8").value = "Model Year";
  ws.getCell("F8").value = `MY${String(bmData?.cdh_car_year)?.slice(-2)}`;

  ws.getCell("A9").value = "Trim Code";
  ws.getCell("F9").value = bmData?.trim_code_ext;

  const table3 = valuesTable();
  const table03 = headerValues();
  ws.addTable({
    name: "headerFeatureValues",
    ref: "G10",
    //   headerRow: false,
    style: {
      theme: "TableStyleLight1",
    },
    ...table03,
  });
  ws.addTable({
    name: "features",
    ref: "G19",
    //   headerRow: false,
    style: {
      theme: "TableStyleLight1",
    },
    ...table3,
  });

  ///added in the bottom beacause need to overwritr headerValues table headers
  ws.getCell("A10").value = "Trim";
  ws.getCell("F10").value = bmData?.trims?.name;

  const table1 = headerTableData();
  table1?.columns[0]?.name &&
    ws.addTable({
      name: "MyTable",
      ref: "G6",
      //   headerRow: false,
      style: {
        theme: "TableStyleLight1",
      },
      ...table1,
    });

  ws.mergeCells(318, 1, 318, 2);
  ws.getCell("A318").value =
    "Manufacturing Selling Retail Price (MSRP) - Including VAT";
  ws.getCell("E318").value = currencyCode;
  ws.getCell("F318").value = numberWithCommas(bmData?.cdh_msrp);

  ws.mergeCells(319, 1, 319, 2);
  ws.getCell("A319").value = "Total Options Value ";
  ws.getCell("E319").value = currencyCode;
  ws.getCell("F319").value = numberWithCommas(
    Math.round(bmData?.TOValue * currencyFactor)
  );

  ws.mergeCells(320, 1, 320, 2);
  ws.getCell("A320").value =
    "Retail Incentive (Input the total offer value; example Cash Discount, Free Service, Insurance, Finance, etc..)";
  ws.getCell("E320").value = currencyCode;
  ws.getCell("F320").value = numberWithCommas(bmData?.cdh_total_offer_value);

  ws.mergeCells(322, 1, 322, 2);
  ws.getCell("A322").value = "MSRP VA";
  ws.getCell("E322").value = currencyCode;
  ws.getCell("F322").value = numberWithCommas(
    Math.round(bmData?.cdh_msrp - bmData?.TOValue * currencyFactor)
  );

  ws.mergeCells(323, 1, 323, 2);
  ws.getCell("A323").value = "TP ";
  ws.getCell("E323").value = currencyCode;
  ws.getCell("F323").value = numberWithCommas(
    Number(bmData?.cdh_msrp) - Number(bmData?.cdh_total_offer_value)
  );

  ws.mergeCells(324, 1, 324, 2);
  ws.getCell("A324").value = "TPVA";
  ws.getCell("E324").value = currencyCode;
  ws.getCell("F324").value = numberWithCommas(
    Math.round(
      bmData?.cdh_msrp -
        bmData?.TOValue * currencyFactor -
        bmData?.cdh_total_offer_value
    )
  );

  ws.mergeCells(326, 1, 326, 2);
  ws.getCell("A326").value = "Visual Index (VI) %";
  ws.getCell("E326").value = " ";
  ws.getCell("F326").value = "100%";

  ws.mergeCells(327, 1, 327, 2);
  ws.getCell("A327").value = "Value Adjusted (VA) % ";
  ws.getCell("E327").value = " ";
  ws.getCell("F327").value = "100%";

  ws.mergeCells(328, 1, 328, 2);
  ws.getCell("A328").value = "Transaction Price VA (TPVA) %";
  ws.getCell("E328").value = " ";
  ws.getCell("F328").value = "100%";

  const bottomTable1 = () => {
    let col = [];
    compareData &&
      Object.keys(compareData).map((key, i) => {
        col.push({ name: " " });
      });
    let headerData = {
      columns: [...col],
      rows: [],
    };
    let tmp = [];
    let tov = [];
    let inc = [];
    compareData &&
      Object.keys(compareData)?.map((key, index) => {
        tmp.push(numberWithCommas(compareData[key]?.cdh_msrp));
        tov.push(
          numberWithCommas(
            Math.round(compareData[key]?.TOValue * currencyFactor)
          )
        );
        inc.push(numberWithCommas(compareData[key]?.cdh_total_offer_value));
      });
    headerData.rows.push(tmp);
    headerData.rows.push(tov);
    headerData.rows.push(inc);
    return headerData;
  };
  const bottomTable2 = () => {
    let col = [];
    compareData &&
      Object.keys(compareData).map((key, i) => {
        col.push({ name: " " });
      });
    let headerData = {
      columns: [...col],
      rows: [],
    };
    let tmp = [];
    let tov = [];
    let inc = [];
    compareData &&
      Object.keys(compareData)?.map((key, index) => {
        tmp.push(
          numberWithCommas(
            Math.round(
              compareData[key]?.cdh_msrp -
                compareData[key]?.TOValue * currencyFactor
            )
          )
        );
        tov.push(
          numberWithCommas(
            compareData[key]?.cdh_msrp - compareData[key]?.cdh_total_offer_value
          )
        );
        inc.push(
          numberWithCommas(
            Math.round(
              compareData[key]?.cdh_msrp -
                compareData[key]?.TOValue * currencyFactor -
                compareData[key]?.cdh_total_offer_value
            )
          )
        );
      });
    headerData.rows.push(tmp);
    headerData.rows.push(tov);
    headerData.rows.push(inc);
    return headerData;
  };
  const bottomTable3 = () => {
    let col = [];
    compareData &&
      Object.keys(compareData).map((key, i) => {
        col.push({ name: " " });
      });
    let headerData = {
      columns: [...col],
      rows: [],
    };
    let vi = [];
    let va = [];
    let tpva = [];
    compareData &&
      Object.keys(compareData)?.map((key, index) => {
        vi.push(
          currency
            ? Math.round(
                (compareData[key]?.cdh_msrp / bmData?.cdh_msrp) * 100
              ) + "%"
            : Math.round(
                (compareData[key]?.cdh_msrp_dollar / bmData?.cdh_msrp_dollar) *
                  100
              ) + "%"
        );
        va.push(
          currency
            ? Math.round(
                ((compareData[key]?.cdh_msrp +
                  (bmData?.TOValue * currencyFactor -
                    compareData[key]?.TOValue * currencyFactor)) /
                  bmData?.cdh_msrp) *
                  100
              ) + "%"
            : Math.round(
                ((compareData[key]?.cdh_msrp_dollar +
                  (bmData?.TOValue * currencyFactor -
                    compareData[key]?.TOValue * currencyFactor)) /
                  bmData?.cdh_msrp_dollar) *
                  100
              ) + "%"
        );
        tpva.push(
          currency
            ? Math.round(
                ((compareData[key]?.cdh_msrp +
                  (bmData?.TOValue * currencyFactor -
                    compareData[key]?.TOValue * currencyFactor) -
                  compareData[key]?.cdh_total_offer_value) /
                  (bmData?.cdh_msrp - bmData?.cdh_total_offer_value)) *
                  100
              ) + "%"
            : Math.round(
                ((compareData[key]?.cdh_msrp_dollar +
                  (bmData?.TOValue * currencyFactor -
                    compareData[key]?.TOValue * currencyFactor) -
                  compareData[key]?.cdh_total_offer_value_dollar) /
                  (bmData?.cdh_msrp_dollar -
                    bmData?.cdh_total_offer_value_dollar)) *
                  100
              ) + "%"
        );
      });
    headerData.rows.push(vi);
    headerData.rows.push(va);
    headerData.rows.push(tpva);
    return headerData;
  };

  const table4 = bottomTable1();
  const table5 = bottomTable2();
  const table6 = bottomTable3();

  const bottomTable = valuesTable();
  ws.addTable({
    name: "data",
    ref: "G317",
    //   headerRow: false,
    style: {
      theme: "TableStyleLight1",
    },
    ...table4,
  });
  ws.addTable({
    name: "data1",
    ref: "G321",
    //   headerRow: false,
    style: {
      theme: "TableStyleLight1",
    },
    ...table5,
  });
  ws.addTable({
    name: "data2",
    ref: "G325",
    //   headerRow: false,
    style: {
      theme: "TableStyleLight1",
    },
    ...table6,
  });
  //   ws.getCell("A1").value = "gardener";
  //   ws.getCell("C1").value = new Date().toLocaleString();

  //   const r3 = ws.getRow(3);
  //   r3.values = [1, 2, 3, 4, 5, 6];
  const save = async () => {
    const buffer = await wb.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, "Features " + new Date() + fileExtension);
  };
  // save();

  return (
    <>
      {" "}
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        className=""
      >
        <button onClick={save} className="btn-details">
          Export
        </button>
      </div>
    </>
  );
};

export default ExcelExportHelper;
