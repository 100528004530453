import React, { useState } from "react";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { axiosAdmin } from "../../../api/axios";
import { useEffect } from "react";

const AddCarType = ({
  show,
  setShow,
  formType,
  editCarTypeData,
  setCarTypesData,
}) => {
  const [carTypeData, setCarTypeData] = useState(null);

  useEffect(() => {
    formType === "Edit" &&
      setCarTypeData({
        ...carTypeData,
        name: editCarTypeData?.name,
        code: editCarTypeData?.code,
        id: editCarTypeData?.id,
      });
  }, [editCarTypeData]);

  useEffect(() => {
    setCarTypeData({
      ...carTypeData,
      name: "",
      code: "",
    });
  }, [formType]);

  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setCarTypeData({ ...carTypeData, [name]: value });
  };
  const handleSubmitModel = (e) => {
    e.preventDefault();
    if (formType === "Add" && !isNaN(Number(carTypeData?.code))) {
      axiosAdmin
        .post("carType/addCarType", {
          name: carTypeData?.name,
          code: carTypeData?.code,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Car Type added", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("carType/getAllCarTypes")
              .then((res) => {
                let data = [];
                res.data.map((brand, i) => {
                  data.push({
                    key: brand?.id,
                    serialNum: String(i + 1),
                    name: brand?.name,
                    id: String(brand?.id),
                    status: brand?.status ? 1 : 0,
                  });
                });
                setCarTypesData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setCarTypeData({
              ...carTypeData,
              name: "",
              code: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Car Type name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Car Type code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else if (formType === "Edit" && !isNaN(Number(carTypeData?.code))) {
      axiosAdmin
        .patch("carType/updateCarType", {
          name: carTypeData?.name,
          code: carTypeData?.code,
          id: carTypeData?.id,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Car type updated", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("carType/getAllCarTypes")
              .then((res) => {
                let data = [];
                res.data.map((brand, i) => {
                  data.push({
                    key: brand?.id,
                    serialNum: String(i + 1),
                    name: brand?.name,
                    id: String(brand?.id),
                    status: brand?.status ? 1 : 0,
                  });
                });
                setCarTypesData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setCarTypeData({
              ...carTypeData,
              name: "",
              code: "",
            });
          }
        })
        .catch((e) => {
          Promise.reject(e);
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Car type  name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Car type code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
          }
        });
    } else {
      toast.error("Car Type code should be a number", {
        position: "top-center",
        autoClose: 2000,
      });
      Promise.reject(e);
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`${formType} Car Type`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitModel} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`Name`}</Form.Label>
              <Form.Control
                required
                onChange={handleModelChange}
                type="text"
                placeholder={`Enter Car Type Name`}
                value={carTypeData?.name}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Code`}</Form.Label>
              <Form.Control
                onChange={handleModelChange}
                minLength={2}
                maxLength={2}
                required
                type="text"
                placeholder={`Enter Car Type Code`}
                value={carTypeData?.code}
                name="code"
              />
            </Form.Group>

            <Button className=" w-100 mx-auto" type="submit">
              {`${formType === "Edit" ? "Update" : "Add"} Car Type`}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCarType;
