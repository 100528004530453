import React from "react";
import { useState } from "react";
import { Button, Form, FormSelect, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import { waveMonthsData } from "../../data/user/import-data-wave";
import { axiosAdmin } from "../../api/axios";

const ImportData = () => {
  const [fileParams, setFileParams] = useState(null);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const handleWaveChange = (e) => {
    setFileParams({ ...fileParams, wave: e.target.value });
  };
  const handleYearChange = (e) => {
    setFileParams({ ...fileParams, year: e.target.value });
  };
  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const onFileSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("wave", fileParams.wave);
    formData.append("year", fileParams.year);
    setLoading(true);
    axiosAdmin
      .post("file/importCSV", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        res.data.success &&
          toast.success("File Successfully updated", {
            position: "top-left",
            autoClose: 2000,
          });
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data?.name) {
          toast.error("Failed to Import data, Unknown Trim Name", {
            position: "top-left",
            autoClose: 2000,
          });
        } else {
          toast.error("Failed to update file", {
            position: "top-left",
            autoClose: 2000,
          });
        }

        Promise.reject(e);
      });
  };
  return (
    <>
      <div className="import-data">
        <div className="import-data__header">
          <h5>Import</h5>
        </div>
        <Form action="." encType="multipart/form-data" onSubmit={onFileSubmit}>
          <div className="import-data__dropdowns">
            <Form.Group className="mb-4">
              <Form.Label>{`Wave`}</Form.Label>
              <Form.Select
                style={{ maxWidth: "10rem" }}
                required
                onChange={handleWaveChange}
                aria-label="Default select example"
              >
                <option value="">Select Month</option>
                {waveMonthsData.map((item, i) => {
                  return (
                    <option key={i} value={item.id} name="wave">
                      {item.month}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Year`}</Form.Label>
              <FormSelect
                style={{ maxWidth: "10rem" }}
                required
                onChange={handleYearChange}
                aria-label="Default select example"
              >
                <option value="">Select Year</option>
                <option value={2022} name="year">
                  2022
                </option>
                <option value={2023} name="year">
                  2023
                </option>
                <option value={2024} name="year">
                  2024
                </option>
                <option value={2025} name="year">
                  2025
                </option>
                <option value={2026} name="year">
                  2026
                </option>
                <option value={2027} name="year">
                  2027
                </option>
                <option value={2028} name="year">
                  2028
                </option>
                <option value={2029} name="year">
                  2029
                </option>
                <option value={2030} name="year">
                  2030
                </option>
              </FormSelect>
            </Form.Group>
          </div>
          <Form.Group className="mb-4">
            <Form.Label>{`File`}</Form.Label>
            <Form.Control
              id="file"
              onChange={onFileChange}
              style={{ maxWidth: "20rem" }}
              required
              type="file"
              name="file"
            />
          </Form.Group>
          <Button disabled={loading} className=" w-20 mx-auto" type="submit">
            {!loading ? (
              "Import"
            ) : (
              <>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>&nbsp;Uploading...</span>
              </>
            )}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default ImportData;
