import React, { useState } from "react";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { adminSidebarItems } from "../data/admin-sidebar-items";

const AdminLayout = ({ children }) => {
  const [adminSidebarOpen, setAdminSidebarOpen] = useState(true);

  const type = "admin";
  return (
    <>
      {adminSidebarOpen && (
        <Sidebar
          type={type}
          sidebarItems={adminSidebarItems}
          sidebarOpen={adminSidebarOpen}
          setSidebarOpen={setAdminSidebarOpen}
        />
      )}

      <div className={`main-container ${type}`}>
        <Navbar type={type} setSidebarOpen={setAdminSidebarOpen} />
        <div className="content">{children}</div>
      </div>
    </>
  );
};

export default AdminLayout;
