import React, { useState } from "react";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, FormControl } from "react-bootstrap";
import { axiosAdmin } from "../../../api/axios";
import { useEffect } from "react";

const AddUser = ({ show, setShow, formType, editUserData, setUsersData }) => {
  const [userData, setUserData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [field, setField] = useState([]);

  // console.log(document.getElementById("inline-checkbox-1"));

  useEffect(() => {
    setCountryData([]);
    axiosAdmin
      .get("country/getAllCountries")
      .then((res) => {
        setCountryData(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  }, [countries]);

  useEffect(() => {
    formType === "Edit" &&
      setUserData({
        ...userData,
        name: editUserData?.name,
        role: editUserData?.role,
        id: editUserData?.id,
        email: editUserData?.email,
      });
    setCountries(editUserData?.countries);
    setValidated(true);
  }, [editUserData]);

  useEffect(() => {
    if (
      (userData?.name && userData?.password,
      userData?.role,
      userData?.email,
      userData?.password)
    ) {
      setValidated(true);
    }
  }, [userData]);

  useEffect(() => {
    setUserData({
      ...userData,
      name: "",
      role: "",
      email: "",
      password: "",
    });
    setCountries([]);
  }, [formType]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const handleSubmitModel = (e) => {
    e.preventDefault();
    if (formType === "Add") {
      axiosAdmin
        .post("user/createUser", {
          name: userData?.name,
          role: userData?.role,
          email: userData?.email,
          password: userData?.password,
          countries,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("User added", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("user/getAllUsers")
              .then((res) => {
                let data = [];
                res.data.data.map((user, i) => {
                  data.push({
                    key: user?.id,
                    serialNum: String(i + 1),
                    name: user?.name,
                    email: user?.email,
                    id: String(user?.id),
                    status: user?.status ? 1 : 0,
                    type: Number(user?.role) === 3 ? "user" : "admin",
                  });
                });
                setUsersData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setValidated(false);
            setUserData({
              ...userData,
              name: "",
              role: "",
              email: "",
              password: "",
            });
          }
        })
        .catch((e) => {
          if (
            e.response.status === 409 &&
            e.response.data.message === "email"
          ) {
            toast.error("Email already registered", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else if (formType === "Edit") {
      axiosAdmin
        .patch("user/updateUser", {
          name: userData?.name,
          role: userData?.role,
          email: userData?.email,
          id: userData?.id,
          countries,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("User updated", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("user/getAllUsers")
              .then((res) => {
                let data = [];
                res.data.data.map((user, i) => {
                  data.push({
                    key: user?.id,
                    serialNum: String(i + 1),
                    name: user?.name,
                    email: user?.email,
                    id: String(user?.id),
                    status: user?.status ? 1 : 0,
                    type: Number(user?.role) === 3 ? "user" : "user+",
                  });
                });
                setUsersData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setValidated(false);
            setUserData({
              ...userData,
              name: "",
              role: "",
              email: "",
              password: "",
            });
            setCountries(editUserData?.countries);
          }
        })
        .catch((e) => {
          if (
            e.response.status === 409 &&
            e.response.data.message === "email"
          ) {
            toast.error("Email already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    }
  };
  const handleRoleChange = (e) => {
    setUserData({ ...userData, role: e.target.value });
  };
  const isChecked = (id) => (countries?.includes(id) ? true : false);

  const onPermissionChange = (e) => {
    const val = Number(e.target.value);

    const tmp = countries;
    setCountries([]);

    if (!tmp?.includes(val)) {
      setCountries([]);
      setCountries([...tmp, val]);
    } else {
      setCountries([]);
      let i = tmp.indexOf(val);
      tmp.splice(i, 1);
      setCountries(tmp);
    }
  };
  console.log(field);

  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`${formType} User`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitModel} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`Name`}</Form.Label>
              <Form.Control
                required
                isValid={false}
                onChange={handleUserChange}
                type="text"
                placeholder={`Enter User Name`}
                value={userData?.name}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`User Role`}</Form.Label>
              <Form.Select
                required
                onChange={handleRoleChange}
                aria-label="Default select example"
              >
                <option value="">Select user role</option>

                {/* <option
                  selected={editUserData?.role === 1}
                  name="role"
                  value={1}
                >
                  Admin
                </option> */}
                <option
                  selected={editUserData?.role === 1}
                  name="role"
                  value={1}
                >
                  Admin
                </option>
                <option
                  selected={editUserData?.role === 2}
                  name="role"
                  value={2}
                >
                  User
                </option>
              </Form.Select>
            </Form.Group>{" "}
            {/* <Form.Group as={Col} controlId="my_multiselect_field">
              <Form.Label>My multiselect</Form.Label>
              <Form.Select
                onChange={(e) => {
                  const val = e.target.value;
                  const tmp = field;
                  setField([]);

                  if (!tmp?.includes(val)) {
                    // setField([]);
                    setField([...tmp, val]);
                  } else {
                    // setField([]);
                    let i = tmp.indexOf(val);
                    tmp.splice(i, 1);
                    setField(tmp);
                  }
                  // setField((prev) => [...prev, e.target.value]);
                }}
                componentClass="select"
                multiple
                value={field}
              >
                <option value="other1">other1</option>
                <option value="other2">other2</option>
                <option value="other3">other3</option>
                <option value="other4">other4</option>
                <option value="other5">other5</option>
              </Form.Select>
            </Form.Group> */}
            {userData?.role == 2 && (
              <Form.Group className="mb-4">
                <>
                  <Form.Label>{`Permission`}</Form.Label>
                  <Form.Select
                    required
                    onChange={onPermissionChange}
                    componentClass="select"
                    multiple
                    value={countries}
                  >
                    {/* <div key={`inline-checkbox`} className="mb-3"> */}
                    {countryData &&
                      countryData.map((item, index) => {
                        console.log(
                          "countries :: ",
                          countries?.includes(item?.id)
                        );
                        return (
                          <>
                            <option key={item?.id} value={item?.id}>
                              {item?.name}
                            </option>
                            {/* <Form.Check
                              defaultChecked={false}
                              key={item?.id}
                              inline
                              type={"checkbox"}
                              // id={`default-checkbox`}
                              label={`${item?.name}`}
                              value={item?.id}
                              onChange={onPermissionChange}
                              checked={isChecked(item?.id)}
                              id={`inline-checkbox-1`}
                              // checked={true}
                            /> */}
                          </>
                        );
                      })}
                    {/* </div> */}
                  </Form.Select>
                </>
              </Form.Group>
            )}
            <Form.Group className="mb-4">
              <Form.Label>{`Email`}</Form.Label>
              <Form.Control
                required
                onChange={handleUserChange}
                type="email"
                placeholder={`Enter Email`}
                value={userData?.email}
                name="email"
              />
            </Form.Group>
            {formType !== "Edit" && (
              <Form.Group className="mb-4">
                <Form.Label>{`Password`}</Form.Label>
                <Form.Control
                  required
                  onChange={handleUserChange}
                  type="password"
                  placeholder={`Enter Password`}
                  value={userData?.password}
                  name="password"
                />
              </Form.Group>
            )}
            <Button
              disabled={!validated}
              className=" w-100 mx-auto"
              type="submit"
            >
              {`${formType === "Edit" ? "Update" : "Add"} User`}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUser;
