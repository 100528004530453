import React, { useState } from "react";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { axiosAdmin } from "../../../api/axios";
import { useEffect } from "react";

const AddCountries = ({
  show,
  setShow,
  formType,
  editCountryData,
  setCountriesData,
}) => {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    formType === "Edit" &&
      setFormData({
        ...formData,
        name: editCountryData?.name,
        id: editCountryData?.id,
      });
  }, [formData?.brand, editCountryData]);

  useEffect(() => {
    setFormData({
      ...formData,
      name: "",
    });
  }, [formType]);

  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmitModel = (e) => {
    e.preventDefault();
    if (formType === "Add") {
      axiosAdmin
        .post("city/createCity", {
          name: formData?.name,
          code: formData?.countryCode,
          currency_code: formData?.currencyCode,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("City added", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("city/getCities?status=false")
              .then((res) => {
                let data = [];
                res.data.data.map((country, i) => {
                  data.push({
                    key: country?.id,
                    serialNum: String(i + 1),
                    name: country?.name,
                    id: String(country?.id),
                    status: country?.status ? 1 : 0,
                  });
                });
                setCountriesData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setFormData({
              ...formData,
              name: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Country name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Country code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else if (formType === "Edit" && !isNaN(Number(formData?.countryCode))) {
      axiosAdmin
        .patch("city/updateCity", {
          name: formData?.name,
          cityId: formData?.id,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("City updated", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("city/getCities?status=true")
              .then((res) => {
                let data = [];
                res.data.data.map((country, i) => {
                  data.push({
                    key: country?.id,
                    serialNum: String(i + 1),
                    name: country?.name,
                    id: String(country?.id),
                    status: country?.status ? 1 : 0,
                  });
                });
                setCountriesData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setFormData({
              ...formData,
              name: "",
            });
          }
        })
        .catch((e) => {
          Promise.reject(e);
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("City name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`${formType} Country`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitModel} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`Name`}</Form.Label>
              <Form.Control
                required
                onChange={handleModelChange}
                type="text"
                placeholder={`Enter Country Name`}
                value={formData?.name}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Country code`}</Form.Label>
              <Form.Control
                onChange={handleModelChange}
                minLength={1}
                maxLength={1}
                required
                type="text"
                placeholder={`Enter Country Code`}
                value={formData?.countryCode}
                name="countryCode"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Currency code`}</Form.Label>
              <Form.Control
                onChange={handleModelChange}
                minLength={3}
                maxLength={3}
                required
                type="text"
                placeholder={`Enter Currrency Code`}
                value={formData?.currencyCode}
                name="currencyCode"
              />
            </Form.Group>

            <Button className=" w-100 mx-auto" type="submit">
              {`${formType === "Edit" ? "Update" : "Add"} Country`}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCountries;
