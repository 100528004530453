import React from "react";
import pptxgen from "pptxgenjs";
import { AiOutlineFilePpt } from "react-icons/ai";

const YourChoicePptx = ({ data, currencyFactor }) => {
  let yourChoiceChart = new pptxgen();

  // const filteredData = data.filter(
  //   (item, index) =>
  //     index === data.findIndex((other) => other.cdh_id === item.cdh_id)
  // );

  let slide = yourChoiceChart.addSlide();

  let pptxData = [];
  let VIObj = {
    labels: [],
    values: [],
    name: "VI",
  };
  let VAObj = {
    labels: [],
    values: [],
    name: "VA",
  };
  let TPVAObj = {
    labels: [],
    values: [],
    name: "TPVA",
  };
  //////For multilevel labels
  let firstLabel = [];
  let secondLabel = [];
  let thirdLabel = [];

  data?.length > 1 &&
    data[0] &&
    data?.map((item, i) => {
      if (i === 0) {
        VIObj.values.push(100);
        firstLabel.push(item?.trims?.name);
        secondLabel.push("MY" + String(item?.cdh_car_year)?.slice(-2));
        thirdLabel.push(item?.models?.name);
        VAObj.values.push(100);
        TPVAObj.values.push(100);
      } else {
        firstLabel.push(item?.trims?.name);
        secondLabel.push("MY" + String(item?.cdh_car_year).slice(-2));
        thirdLabel.push(item?.models?.name);
        VIObj.values.push(
          Math.round((item?.cdh_msrp / data[0]?.cdh_msrp) * 100)
        );
        VAObj.values.push(
          Math.round(
            ((item?.cdh_msrp +
              (data[0]?.TOValue * currencyFactor -
                item?.TOValue * currencyFactor)) /
              data[0]?.cdh_msrp) *
              100
          )
        );

        TPVAObj.values.push(
          Math.round(
            ((item?.cdh_msrp +
              (data[0]?.TOValue * currencyFactor -
                item?.TOValue * currencyFactor) -
              item?.cdh_total_offer_value) /
              (data[0]?.cdh_msrp - item?.cdh_total_offer_value)) *
              100
          )
        );
      }
      if (i === data?.length - 1) {
        VIObj.labels.push(firstLabel, secondLabel, thirdLabel);
        VAObj.labels.push(firstLabel, secondLabel, thirdLabel);
        TPVAObj.labels.push(firstLabel, secondLabel, thirdLabel);
        pptxData.push(VIObj);
        pptxData.push(VAObj);
        pptxData.push(TPVAObj);
      }
    });
  let arrDataRegions = pptxData;
  // console.log("pptxpptx", pptxData);

  let optsChartBar2 = {
    x: 0.5,
    y: 1.0,
    w: "90%",
    h: "80%",
    // chartArea: { fill: { color: "404040" } },
    // plotArea: { fill: { color: "202020" } },
    // catAxisLabelColor: "F1F1F1",
    // valAxisLabelColor: "F1F1F1",
    // valAxisLineColor: "7F7F7F",
    // valGridLine: { color: "7F7F7F" },
    // dataLabelColor: "B7B7B7",
    catAxisMultiLevelLabels: true,
    catAxisLabelRotate: 0,
    valAxisHidden: false,
    barDir: "col", // `col`(vert) | `bar`(horiz)
    showValue: true,
    dataLabelPosition: "outEnd",
    dataLabelFontSize: 7,
    catAxisLabelFontSize: 8,
    catAxisLineShow: true,
    dataLabelFormatCode: "",
    valAxisTitle: "Values in Percentage",
    // showPercent: true,
    // showSerName: true, // for showing series as datalabels
    // valAxisOrientation: "maxMin", /// for revert
    // barGrouping: "stacked",
    // barOverlapPct: 30, /// Overlaping
    valAxisCrossesAt: 100, //// for setting the centre line
    // valAxisLabelFormatCode: "0",
    chartColors: ["c8c8c8", "b87272", "6b94d0"],
    // valAxisMinVal: 50,
    barGapWidthPct: 450, // width between bar sets
    showLegend: true,
    legendPos: "t",
    chartColorsOpacity: 90,
    // legendColor: "F1F1F1",
  };
  slide.addChart(yourChoiceChart.charts.BAR, arrDataRegions, optsChartBar2);
  slide.addText("VIVA", {
    x: 1,
    y: 0.14,
    w: 3,
    h: 1,
    fontSize: 20,
    bold: true,
    // paraSpaceBefore: 12,
    // paraSpaceAfter: 24,
  });
  return (
    <>
      {" "}
      <button
        className="btn_pptx"
        onClick={() => yourChoiceChart.writeFile({ fileName: "VIVA.pptx" })}
      >
        <AiOutlineFilePpt />
        <span style={{ fontSize: ".6rem" }}>VI/VA</span>
      </button>
    </>
  );
};

export default YourChoicePptx;
