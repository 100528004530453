import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useEffect } from "react";
import { waveMonths } from "../../../data/wave-month-data";
import { useRef } from "react";

////////////////////////////////////////////Point//////////////////////////

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const PriceTrendchart = ({
  priceTrendData,
  priceTrend,
  modelName,
  average,
  modelSelected,
  setPriceTrendData,
  filterByTrims,
  trimsSelected,
}) => {
  const [chartData, setChartData] = useState();
  const [pptData, setPptData] = useState();
  const [categories, setCategories] = useState([]);
  const [trendType, setTrendType] = useState("");
  const [chartName, setChartName] = useState();
  const [chartAvgData, setChartAvgData] = useState();
  const [models, setModels] = useState([]);
  const [trimAvg, setTrimAvg] = useState();
  /////Trims after filteration
  const [filteredTrims, setFilteredTrims] = useState({});

  const chart = useRef();

  useEffect(() => {
    modelName && setModels([...models, modelName]);
  }, [modelName]);

  useEffect(() => {
    let competTrims = priceTrendData?.competTrims;
    // let allowed = ["20100401"];
    let filteredCom = [];
    if (trimsSelected && filterByTrims && competTrims) {
      filteredCom = Object.keys(competTrims)
        .filter((key) => trimsSelected.includes(key))
        .reduce((obj, key) => {
          obj[key] = competTrims[key];
          return obj;
        }, {});
      // setPriceTrendData((prev) => ({ ...prev, competTrims: filteredCom }));
      setFilteredTrims(filteredCom);
    }

    // setPriceTrendData((prev) => ({ ...prev, competTrims: filteredCom }));
  }, [filterByTrims, trimsSelected, priceTrendData]);

  useEffect(() => {
    if (priceTrend === "cdh_transaction_price") {
      setTrendType("TP");
      setChartName("Transaction Price Trend");
    } else if (priceTrend === "cdh_net_transaction_price") {
      setTrendType("NTP");
      setChartName("Net Transaction Price Trend");
    } else if (priceTrend === "cdh_msrp") {
      setChartName("MSRP Trend");

      setTrendType("MSRP");
    }
  }, [priceTrend]);
  useEffect(() => {
    if (priceTrendData) {
      setCategories([]);
      priceTrendData?.categories?.map((item) => {
        setCategories((prev) => [
          ...prev,
          waveMonths[Number(String(item).slice(-2)) - 1],
        ]);
      });
    }
  }, [priceTrendData]);

  ////////////////////////////For Setting All Trims Data to Chart////////////////////////////////

  useEffect(() => {
    let data = [];
    let dat = [];
    priceTrendData &&
      categories &&
      Object.keys(priceTrendData?.bmTrims).forEach(function (key, index) {
        let obj = {
          data: [],
          name: "",
          legend: "",
          wave: [],
          isReplaced: [],
          isActive: [],
          // dataLabels: {
          //   enabled: true,
          //   defer: false,
          //   style: {
          //     color: "green",
          //   },
          // },
          // labels: [],
          // lineWidth: 5,
        };
        let avg;
        // if (priceTrendData?.bmTrims[key]?.length > 1) {
        priceTrendData?.bmTrims[key]?.map((item, i) => {
          console.log("isActive", priceTrendData?.bmTrims[key][i]?.active);
          obj.data.push(
            priceTrendData?.bmTrims[key][i]?.active
              ? priceTrendData?.bmTrims[key][i][priceTrend]
              : null
          );
          // obj.values.push(priceTrendData?.bmTrims[key][i][priceTrend]);
          // obj.labels = categories;
          obj.wave.push(priceTrendData?.bmTrims[key][i]?.cdh_wave);
          obj.isReplaced.push(
            priceTrendData?.bmTrims[key][i]?.replaced === true ? true : false
          );
          obj.isActive.push(
            priceTrendData?.bmTrims[key][i]?.active === true ? true : false
          );

          obj.legend =
            priceTrendData?.bmTrims[key][i]?.modelName +
            " " +
            priceTrendData?.bmTrims[key][i]?.trim_name_spec +
            " MY-" +
            priceTrendData?.bmTrims[key][i]?.MY;
          // priceTrendData?.bmTrims[key][i]?.brandName +
          // " " +
          obj.name =
            priceTrendData?.bmTrims[key][i]?.trim_name_spec +
            " MY-" +
            priceTrendData?.bmTrims[key][i]?.MY;
          i + 1 === priceTrendData?.bmTrims[key]?.length && data.push(obj);
        });
        // } else {
        //   data.push({
        //     wave: priceTrendData?.bmTrims[key][0]?.cdh_wave,
        //     isReplaced: priceTrendData?.bmTrims[key][0]?.replaced,
        //     isActive: priceTrendData?.bmTrims[key][0]?.active,
        //     name:
        //       // priceTrendData?.bmTrims[key][0]?.brandName +
        //       // " " +
        //       priceTrendData?.bmTrims[key][0]?.trim_name_spec +
        //       " MY-" +
        //       priceTrendData?.bmTrims[key][0]?.MY,
        //     legend:
        //       priceTrendData?.bmTrims[key][0]?.modelName +
        //       " " +
        //       priceTrendData?.bmTrims[key][0]?.trim_name_spec +
        //       " MY-" +
        //       priceTrendData?.bmTrims[key][0]?.MY,
        //     data: [priceTrendData?.bmTrims[key][0][priceTrend]],
        //     isReplaced: priceTrendData?.bmTrims[key][0]?.replaced,
        //     // values: [priceTrendData?.bmTrims[key][0][priceTrend]],
        //     // labels: categories,
        //   });
        // }
        console.log("Data : ", data);
        // console.log(obj);
      });
    setChartData(data);
    priceTrendData &&
      !filterByTrims &&
      Object.keys(priceTrendData?.competTrims).forEach(function (key, index) {
        let obj = {
          data: [],
          name: "",
          legend: "",
          wave: [],
          isReplaced: [],
          isActive: [],

          // values: [],
          // labels: [],
          // lineWidth: 5,
        };
        // Object.keys(obj).forEach(function (key, index) {
        // })
        // if (priceTrendData?.competTrims[key]?.length > 1) {
        priceTrendData?.competTrims[key]?.map((item, i) => {
          obj.data.push(
            priceTrendData?.competTrims[key][i]?.active
              ? priceTrendData?.competTrims[key][i][priceTrend]
              : null
          );
          obj.isReplaced.push(
            priceTrendData?.competTrims[key][i]?.replaced === true
              ? true
              : false
          );
          obj.isActive.push(
            priceTrendData?.competTrims[key][i]?.active === true ? true : false
          );
          // obj.values.push(priceTrendData?.competTrims[key][i][priceTrend]);
          // obj.labels = categories;
          priceTrendData?.competTrims[key][i]?.active
            ? (obj.name =
                // priceTrendData?.competTrims[key][0]?.brandName +
                // " " +
                priceTrendData?.competTrims[key][i]?.trim_name_spec +
                " MY-" +
                priceTrendData?.competTrims[key][i]?.MY)
            : (obj.name = null);

          obj.legend =
            priceTrendData?.competTrims[key][i]?.modelName +
            " " +
            priceTrendData?.competTrims[key][i]?.trim_name_spec +
            " MY-" +
            priceTrendData?.competTrims[key][i]?.MY;
          obj.wave.push(priceTrendData?.competTrims[key][i]?.cdh_wave);
          i + 1 === priceTrendData?.competTrims[key]?.length && data.push(obj);
        });
        // } else {/
        //   data.push({
        //     wave: priceTrendData?.competTrims[key][0]?.cdh_wave,
        //     isReplaced: priceTrendData?.competTrims[key][0]?.replaced,
        //     isActive: priceTrendData?.competTrims[key][0]?.active,
        //     legend:
        //       priceTrendData?.competTrims[key][0]?.modelName +
        //       " " +
        //       priceTrendData?.competTrims[key][0]?.trim_name_spec +
        //       " MY-" +
        //       priceTrendData?.competTrims[key][0]?.MY,
        //     name:
        //       // priceTrendData?.competTrims[key][0]?.brandName +
        //       // " " +
        //       priceTrendData?.competTrims[key][0]?.trim_name_spec +
        //       " MY-" +
        //       priceTrendData?.competTrims[key][0]?.MY,
        //     data: [priceTrendData?.competTrims[key][0][priceTrend]],
        //     // values: [priceTrendData?.competTrims[key][0][priceTrend]],
        //     // labels: categories,
        //   });
        // }
      });

    ///////////////////////if trim filtering true/////////////////////
    priceTrendData &&
      filterByTrims &&
      Object.keys(filteredTrims).forEach(function (key, index) {
        let obj = {
          data: [],
          name: "",
          isReplaced: [],
          isActive: [],
          wave: [],
          legend: "",
          // values: [],
          // labels: [],
          // lineWidth: 5,
        };
        // Object.keys(obj).forEach(function (key, index) {
        // })
        // if (filteredTrims[key]?.length > 1) {
        filteredTrims[key]?.map((item, i) => {
          console.log("item", item);
          obj.isReplaced.push(item?.replaced === true ? true : false);
          obj.isActive.push(item?.active === true ? true : false);
          obj.wave.push(item?.cdh_wave);
          obj.data.push(
            item?.active ? filteredTrims[key][i][priceTrend] : null
          );
          obj.legend =
            item?.modelName + " " + item?.trim_name_spec + " MY-" + item?.MY;
          // obj.values.push(filteredTrims[key][i][priceTrend]);
          // obj.labels = categories;

          i + 1 === filteredTrims[key]?.length && data.push(obj);

          item?.active
            ? (obj.name =
                // filteredTrims[key][0]?.brandName +
                // " " +
                // " " +
                filteredTrims[key][i]?.trim_name_spec)
            : (obj.name = null);
        });
        // } else {
        //   data.push({
        //     isReplaced: filterByTrims[key][0]?.replaced,
        //     isActive: filterByTrims[key][0]?.active,
        //     wave: filterByTrims[key][0]?.cdh_wave,
        //     name:
        //       // filteredTrims[key][0]?.brandName +
        //       // " " +
        //       // " " +
        //       filteredTrims[key][0]?.trim_name_spec,
        //     data: [filteredTrims[key][0][priceTrend]],
        //     // values: [priceTrendData?.competTrims[key][0][priceTrend]],
        //     // labels: categories,
        //   });
        // }
      });
    setChartData((prev) => [...prev, ...dat]);
  }, [
    priceTrendData,
    categories,
    priceTrend,
    filterByTrims,
    filteredTrims,
    modelSelected,
  ]);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////For Setting Avg Data to Chart//////////////////////////////////
  useEffect(() => {
    let modelNames = [];
    let data = [];
    let dat = [];
    priceTrendData &&
      categories &&
      Object.keys(priceTrendData?.bmTrims).forEach(function (key, index) {
        let obj = {
          [modelName]: {
            name: "",
            data: [],
            labels: [],
            legend: "",
            isActive: [],
            isReplaced: [],
            wave: [],
            // lineWidth: 5,
          },
        };
        let avg;
        // if (priceTrendData?.bmTrims[key]?.length > 1) {
        priceTrendData?.bmTrims[key]?.map((item, i) => {
          const model = item?.modelName;
          if (!(model in obj)) {
            obj = {
              [model]: {
                name: "",
                data: [],
                labels: [],
                isActive: [],
                legend: "",
                wave: [],
                // lineWidth: 5,
              },
            };
          }
          modelNames?.push(model);
          obj?.wave?.push(item?.cdh_wave);
          obj?.isActive?.push(item?.active);
          obj[model]?.data?.push(priceTrendData?.bmTrims[key][i][priceTrend]);
          // obj[model]?.labels = categories;
          obj.legend = "kk";
          i + 1 === priceTrendData?.bmTrims[key]?.length && data?.push(obj);
          obj[model] &&
            (obj[model].name =
              priceTrendData?.bmTrims[key][i]?.modelName + " " + "Average");
        });
        // } else {
        //   const model = priceTrendData?.bmTrims[key][0]?.modelName;
        //   setModels([...models, model]);
        //   data.push({
        //     [model]: {
        //       wave: priceTrendData?.bmTrims[key][0]?.cdh_wave,
        //       name:
        //         // priceTrendData?.bmTrims[key][0]?.brandName +
        //         // " " +
        //         priceTrendData?.bmTrims[key][0]?.modelName + " " + "Average",
        //       data: [priceTrendData?.bmTrims[key][0][priceTrend]],
        //       labels: categories,
        //       legend: "kk",
        //     },
        //   });
        // }
      });
    setModels([...models, ...modelNames]);
    setChartAvgData(data);
    priceTrendData &&
      Object.keys(priceTrendData?.competTrims).forEach(function (key, index) {
        let obj = {};
        // Object.keys(obj).forEach(function (key, index) {

        // })
        // if (priceTrendData?.competTrims[key]?.length > 1) {
        priceTrendData?.competTrims[key]?.map((item, i) => {
          const model = item.modelName;
          // setModels([...models, model]);
          if (!(model in obj)) {
            obj = {
              [model]: {
                name: "",
                data: [],
                labels: [],
                wave: [],
                legend: "",

                // lineWidth: 5,
              },
            };
          }

          modelNames.push(model);
          obj?.wave?.push(priceTrendData?.competTrims[key][i]?.cdh_wave);
          obj[model].data.push(priceTrendData?.competTrims[key][i][priceTrend]);
          obj[model].labels = categories;
          obj.legend = "kk";

          i === priceTrendData?.competTrims[key]?.length - 1 && data.push(obj);
          obj[model].name =
            // priceTrendData?.competTrims[key][0]?.brandName +
            // " " +
            priceTrendData?.competTrims[key][i]?.modelName + " " + "Average";
        });
        // } else {
        //   const model = priceTrendData?.competTrims[key][0]?.modelName;
        //   setModels([...models, model]);

        //   data.push({
        //     [model]: {
        //       wave: priceTrendData?.competTrims[key][0]?.cdh_wave,
        //       legend: "kk",
        //       name:
        //         priceTrendData?.competTrims[key][0]?.modelName +
        //         " " +
        //         "Average",
        //       data: [priceTrendData?.competTrims[key][0][priceTrend]],
        //       labels: categories,
        //     },
        //   });
        // }
      });
    setModels([...models, ...modelNames]);
    setChartAvgData((prev) => [...prev, ...dat]);
  }, [priceTrendData, categories, priceTrend, modelName]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  let filteredNames = [];
  let modelsArr = models;
  if (modelsArr) {
    filteredNames = modelsArr?.filter(
      (model, index) =>
        index === modelsArr?.findIndex((other) => model === other)
    );
  }
  filteredNames.push(modelName);

  let modelObj = {};
  filteredNames?.map((name, i) => {
    modelObj = { ...modelObj, [name]: [] };
  });
  if (chartAvgData) {
    filteredNames.map((name) => {
      chartAvgData.map((item, i) => {
        item[name] !== undefined && modelObj[name].push(item[name]);
      });
    });
  }

  console.log("modelObj", modelObj);

  /////////////////////////////////////////////Calculating average for chart///////////////////////////////
  useEffect(() => {
    // const trimAvg = (modelObj) => {
    let chartFinal = [];
    Object.keys(modelObj).forEach(function (key, keyInd) {
      let len = modelObj[key].length;
      //   console.log("length", len);
      let avg = 0;
      let grp = 0;
      let grpAvg = [];
      categories?.map((cat, ind) => {
        let repeat = 0;
        grpAvg.push(0);
        modelObj[key].map((item, index) => {
          //   console.log("len", len);
          // if (item.values.length > 1) {
          item.data[ind]
            ? (grpAvg[ind] = grpAvg[ind] + item.data[ind])
            : (repeat += 1);
          if (index === len - 1 && repeat === 0) {
            grpAvg[ind] = Math.round(grpAvg[ind] / len);
          } else if (index === len - 1 && repeat > 0) {
            grpAvg[ind] = Math.round(grpAvg[ind] / (len - repeat));
            // repeat = 0;
          }

          index === len - 1 &&
            ind === categories?.length - 1 &&
            chartFinal.push({
              name: item.name,
              labels: item.labels,
              data: [...grpAvg],
            });
        });
      });
    });
    // return chartFinal;
    setTrimAvg(chartFinal);
    // };
  }, [chartAvgData, priceTrendData]);

  ///////////////////////////////////////////////////////////////////////////////////////

  const options = {
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      fallbackToExportServer: false,
    },

    accessibility: {
      enabled: false,
    },
    chart: {
      height: 600,
      // renderTo: "container",
      // zoomType: "xy",
    },

    credits: {
      enabled: false,
    },
    title: {
      text: chartName,
      x: -20, //center
    },

    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: `${chartName}(${trendType})`,
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: "#808080",
        },
      ],
    },
    plotOptions: {
      series: {
        // color: "#000000",
        dataLabels: {
          enabled: true,
          formatter: function () {
            // console.log("This", this);
            /*  if (this.point.value) { */
            // console.log("point name", this.point.series.userOptions);
            // if (this.point.index === 0) {
            //   return this.point.series.userOptions.name;
            // }
            console.log("name", this?.point?.series?.userOptions);
            if (this?.point?.index === 0) {
              return `${this?.point?.series?.userOptions?.name}`;
            } else if (
              this?.point?.index === 1 &&
              this?.point?.series?.userOptions?.isActive?.length &&
              !this?.point?.series?.userOptions?.isActive[0]
            ) {
              return `${this?.point?.series?.userOptions?.name}`;
            } else if (
              this?.point?.series?.userOptions?.isReplaced?.length >
                this?.point?.index &&
              this?.point?.series?.userOptions?.isReplaced[this?.point?.index]
            ) {
              let pointIndex = this?.point?.index;
              let wave = "";
              wave =
                waveMonths[
                  Number(
                    this?.point?.series?.userOptions?.wave[pointIndex]?.slice(
                      -2
                    )
                  ) - 1
                ] +
                "-" +
                "20" +
                this?.point?.series?.userOptions?.wave[pointIndex]?.slice(0, 2);
              return `${wave}`;
            } else {
              return "";
            }
            /*    } */
          },
        },
      },
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            console.log(this?.point?.series?.userOptions);
            let pointIndex = this?.point?.index;
            return `${this?.point?.series?.userOptions?.name} - ${this?.point?.series?.userOptions?.tmpData[pointIndex]?.modelName} - ${this?.point?.series?.userOptions?.tmpData[pointIndex]?.trimName} - ${this?.point?.series?.userOptions?.tmpData[pointIndex]?.msrp}`;
          },
        },
      },
    },
    tooltip: {
      valueSuffix: ` ${trendType}`,
      formatter: function () {
        let pointIndex = this?.point?.index;
        let wave = "";
        wave =
          waveMonths[
            Number(
              this?.point?.series?.userOptions?.wave[pointIndex]?.slice(-2)
            ) - 1
          ] +
          "-" +
          "20" +
          this.point?.series?.userOptions?.wave[pointIndex]?.slice(0, 2);
        return `Wave:  ${wave} <br/> Trim:  ${this?.point?.series?.userOptions?.name} <br/> ${trendType}:  ${this.y}   `;
      },
    },
    legend: {
      labelFormatter: function () {
        console.log("thisLegend : ", this?.userOptions);
        let legend = average
          ? this?.userOptions?.name
          : this?.userOptions?.legend;
        return legend;
      },
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 10,
      floating: false,
      borderWidth: 1,
      borderRadius: 4,
      padding: 20,
      itemMarginBottom: 15,
      itemStyle: {
        fontSize: 13,
        fontWeight: "none",
      },
    },
    //  const avgData = trimAvg(modelObj)
    series: average ? trimAvg : chartData,
  };

  // slide.addChart("pie", data, {
  //   type: "pie",
  //   showLegend: true,
  //   legendPos: "t",
  // });

  // slide.addText("TEXT", {
  //   fill: "#ff0000",
  //   x: 0.5,
  //   y: 5.0,
  //   w: "90%",
  //   margin: 0.5,
  //   fontFace: "Arial",
  //   fontSize: 32,
  //   color: "00CC00",
  //   bold: true,
  //   underline: true,
  //   isTextBox: true,
  // });
  // console.log(chartData);
  return (
    <>
      {" "}
      <HighchartsReact
        ref={chart}
        highcharts={Highcharts}
        options={options}
      />{" "}
    </>
  );
};

export default PriceTrendchart;
