import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("dashboard-user"))?.token;
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  return <>{token && children}</>;
};

export default PrivateRoute;
