import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { BsDownload } from "react-icons/bs";
import FileDownload from "js-file-download";

import ToggleBtn from "./toggle-button";
import { axiosAdmin, axiosPrivate } from "../../api/axios";
import { waveMonths } from "../../data/wave-month-data";
import fileDownload from "js-file-download";

const SupportingDocuments = () => {
  const [countryData, setCountryData] = useState(null);
  const [wavesData, setWavesData] = useState(null);
  const [segment, setSegment] = useState(true);
  const [countrySelected, setCountrySelected] = useState(true);
  const [waveSelected, setWaveSelected] = useState(true);
  const [files, setFiles] = useState();
  const [brandsData, setBrandsData] = useState();
  const [brandSelected, setBrandSelected] = useState();
  const [modelData, setModelData] = useState();
  const [modelSelected, setModelSeleced] = useState();
  const [modelCodeSelected, setModelCodeSelected] = useState();
  const [brandCode, setBrandCode] = useState();

  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
    axiosPrivate
      .get("wave")
      .then((res) => {
        let waves = [];
        // console.log("waves", res.data.data);
        res.data.data.map((item) => {
          waves.push({
            month: waveMonths[Number(String(item.code).slice(-2)) - 1],
            year: String(item.code).slice(0, 2),
            code: item.code,
            id: item.id,
          });
        });
        setWavesData(waves);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  useEffect(() => {
    countrySelected &&
      waveSelected &&
      brandSelected &&
      modelSelected &&
      axiosAdmin
        .post(`file/getSpecFileNames`, {
          country: countrySelected,
          wave: waveSelected,
          segment: segment ? 1 : 2,
          modelId: modelSelected,
          brandId: Number(brandCode),
        })
        .then((res) => {
          setFiles(res.data.data);
        })
        .catch((err) => Promise.reject(err));
  }, [countrySelected, waveSelected, brandSelected, modelSelected]);

  useEffect(() => {
    countrySelected &&
      waveSelected &&
      axiosPrivate
        .post("brand/brandByWave", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          wave: String(waveSelected),
        })
        .then((res) => {
          setBrandsData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [segment, countrySelected, waveSelected]);

  useEffect(() => {
    brandSelected &&
      axiosPrivate
        .post(`yourChoice/models`, {
          countryId: Number(countrySelected),
          brandId: brandCode,
          wave: String(waveSelected),
          segment: segment ? 1 : 2,
        })
        .then((res) => {
          setModelData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [brandSelected]);

  const onWaveChange = (value) => {
    setWaveSelected(value);
  };
  const onCountryChange = (value) => {
    setCountrySelected(value);
  };

  const onFileSelect = (filename) => {
    axiosAdmin
      .post(
        `file/getSpecFile`,
        { fileName: filename },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, filename);
      })
      .catch((err) => Promise.reject(err));
  };
  const onDownloadAll = () => {
    files?.map((file, i) => {
      axiosAdmin
        .post(
          `file/getSpecFile`,
          { fileName: file.name },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          fileDownload(res.data, file.name);
        })
        .catch((err) => Promise.reject(err));
    });
  };

  const onBrandChange = (value, data) => {
    setBrandSelected(value);
    setBrandCode(data.key);
  };
  const onModelChange = (value, data) => {
    setModelSeleced(value);
    setModelCodeSelected(data.key);
  };

  return (
    <>
      <div className="supporting-documents">
        <div className="supporting-documents__header">
          <div className="main-toggle">
            <p className="rtl">RTL</p>
            <div
              onClick={() => setSegment((prev) => !prev)}
              className={`toggle ${segment ? "right" : "left"}`}
            >
              <div className="toggle__toggler"></div>
            </div>
            <p className="sme">SME</p>
          </div>
          <div className="supporting-documents__select">
            <Select
              onChange={onCountryChange}
              defaultValue="Country"
              style={{
                width: 120,
              }}
            >
              {countryData?.map((country, i) => (
                <Select.Option key={country.id} value={country.code}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              showSearch
              defaultValue="Wave"
              style={{
                width: 120,
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.includes(input.toUpperCase())
              }
              onChange={onWaveChange}
            >
              {wavesData?.map((wave, i) => (
                <Select.Option key={wave.id} value={wave.code}>
                  {wave.month + "-" + wave.year}
                </Select.Option>
              ))}
            </Select>
            <Select
              showSearch
              defaultValue="Brand"
              style={{
                width: 120,
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.includes(input.toUpperCase())
              }
              onChange={onBrandChange}
            >
              {brandsData?.map((brand, i) => (
                <Select.Option key={brand.code} value={brand.id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              showSearch
              defaultValue="Model"
              style={{
                width: 120,
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.includes(input.toUpperCase())
              }
              onChange={onModelChange}
            >
              {modelData?.map((model, i) => (
                <Select.Option key={model.id} value={model.id}>
                  {model.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="supporting-documents__heading">
            <h5>Supporting Documents</h5>
            <button onClick={onDownloadAll} className="btn-download">
              Download All &nbsp;&nbsp;
              <BsDownload />
            </button>
          </div>
        </div>
        <div className="user-table">
          <table id="l">
            <thead>
              <tr>
                <th>FILE NAME</th>
              </tr>
            </thead>
            <tbody>
              {files &&
                files.map((file, i) => (
                  <tr key={file.id} className="sd-table">
                    <td onClick={() => onFileSelect(file.name)}>{file.name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SupportingDocuments;
