import React, { useRef, useState } from "react";
import XLSX from "xlsx";

import { useEffect } from "react";
import { axiosPrivate } from "../../../api/axios";
import { useDownloadExcel } from "react-export-table-to-excel";

import ExcelJS from "exceljs";
import { waveMonths } from "../../../data/wave-month-data";

const FeaturesTable = ({
  bmData,
  currencyFactor,
  currencyCode,
  compareData,
  countryName,
  modelName,
  wave,
  showFeatures,
}) => {
  const [features, setFeatures] = useState();
  const [bmFeatureValues, setBmFeatureValues] = useState();
  const [compareFeatureValues, setComareFeatureValues] = useState({});

  // exTest();

  //   const onChange = (pagination, filters, sorter, extra) => {
  //     console.log("params", pagination, filters, sorter, extra);
  //   };

  //  const tableRef = useRef();

  console.log("ss");
  // const options = ["brands"]

  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef?.current,
    filename: "Feature",
    sheet: "Featus",
  });
  console.log("refCurrent", tableRef);
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    axiosPrivate
      .get("features")
      .then((res) => {
        setFeatures(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);
  useEffect(() => {
    bmData?.cdh_id &&
      axiosPrivate
        .post("features", {
          cdh_id: bmData?.cdh_id,
        })
        .then((res) => {
          setBmFeatureValues(res?.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [bmData]);
  useEffect(() => {
    compareData &&
      Object.keys(compareData).map((key, index) => {
        if (compareData[key]?.cdh_id) {
          axiosPrivate
            .post("features", {
              cdh_id: compareData[key]?.cdh_id,
            })
            .then((res) => {
              setComareFeatureValues((prev) => ({
                ...prev,
                [index]: res?.data?.data,
              }));
            })
            .catch((e) => Promise.reject(e));
        }
      });
  }, [compareData]);
  console.log("features", features);

  return (
    <>
      <div className="user-table">
        <div className="details-controls">
          {" "}
          {/* <button onClick={onDownload} className="btn-details">
            Export
          </button> */}
          &nbsp;
          {/* <button onClick={handleFeaturesShow} id="sheetjsexport">
            <b>Export as XLSX</b>
          </button> */}
        </div>
        {showFeatures && (
          <table id="TableToExport" ref={tableRef}>
            <thead>
              <tr>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Label</th>
                <th>Price USD</th>
                <th>{`Price ${currencyCode}`}</th>
                <th>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </th>
                <th>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </th>
                {compareData &&
                  Object.keys(compareData).map((key, index) => (
                    <th>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                  ))}

                {/* <th>Promotions</th> */}
              </tr>
            </thead>
            <tbody>
              {/* <tr style={{ borderBottom: "1px solid " }}>
                <td style={{ backgroundColor: "red" }}> GSO Mandatory items</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{} </td>

                <td></td>

                {/* <th>Promotions</th> */}
              {/* </tr>  */}
              <tr style={{ borderBottom: "1px solid " }}>
                <td style={{ fontSize: "2rem" }}>
                  {" "}
                  {`${countryName}  ${modelName} VIVA `}
                </td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>&nbsp;</td>

                <td></td>

                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td style={{ fontSize: "1.2rem" }}>
                  {" "}
                  {wave
                    ? waveMonths[Number(String(wave).slice(-2)) - 1] +
                      "," +
                      String(wave).slice(0, 2)
                    : ""}
                </td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> </td>

                <td></td>
              </tr>

              <tr style={{ borderBottom: "1px solid " }}>
                <td> Country</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{countryName} </td>

                <td></td>

                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Maker</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{bmData?.brands?.name} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return (
                      <>
                        <td>{compareData[key]?.brands?.name} </td>
                      </>
                    );
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Model</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{bmData?.models?.name} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return (
                      <>
                        <td>{compareData[key]?.models?.name} </td>
                      </>
                    );
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Model Year</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{`MY${String(bmData?.cdh_car_year)?.slice(-2)}`} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return (
                      <>
                        <td>
                          {`MY${String(compareData[key]?.cdh_car_year)?.slice(
                            -2
                          )}`}{" "}
                        </td>
                      </>
                    );
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Trim Code</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{`${bmData?.trim_code_ext}`} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return (
                      <>
                        <td>{compareData[key]?.trim_code_ext} </td>
                      </>
                    );
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td> Trim</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td> &nbsp;</td>
                <td>{bmData?.trims?.name} </td>
                {compareData &&
                  Object.keys(compareData).map((key, index) => {
                    return (
                      <>
                        <td>{compareData[key]?.trims?.name} </td>
                      </>
                    );
                  })}
                {/* <th>Promotions</th> */}
              </tr>
              {features?.map((feature, index) => {
                //   return feature?.other_featurelist?.map((sub, ind) => {
                return feature?.other_featurelist?.map((sub, ind) => {
                  if (!sub?.other_featurelist?.length) {
                    return (
                      <tr
                        key={sub.featurelist_id}
                        style={{ borderBottom: "1px solid" }}
                      >
                        <td
                          style={{
                            borderBottom: `1px solid ${
                              index !== feature?.length - 1 ? "#fff" : "#000"
                            }`,
                            borderRight: "1px solid",
                          }}
                        >
                          {ind === 0 ? feature?.featurelist_name : ""}
                        </td>
                        <td>{""}</td>
                        <td>{sub?.featurelist_name}</td>
                        <td>{numberWithCommas(sub?.featurelist_value)}</td>
                        <td>
                          {numberWithCommas(
                            Math.round(sub?.featurelist_value * currencyFactor)
                          )}
                        </td>
                        <td>
                          {bmFeatureValues &&
                            bmFeatureValues[sub?.featurelist_feature_id]}{" "}
                        </td>
                        {compareFeatureValues &&
                          Object.keys(compareFeatureValues).map((key, idx) => {
                            return (
                              <td>
                                {
                                  compareFeatureValues[key][
                                    sub?.featurelist_feature_id
                                  ]
                                }
                              </td>
                            );
                          })}
                      </tr>
                    );
                  } else {
                    return sub?.other_featurelist?.map((sub1, i) => {
                      return (
                        <tr
                          key={sub1.featurelist_id}
                          style={{ borderBottom: "1px solid " }}
                        >
                          <td
                            style={{
                              borderBottom: `1px solid ${
                                index !== feature?.length - 1 ? "#fff" : "#000"
                              }`,
                              borderRight: "1px solid",
                            }}
                          >
                            {ind === 0 && i === 0
                              ? feature?.featurelist_name
                              : ""}
                          </td>
                          <td
                            style={{
                              borderBottom: `1px solid ${
                                i !== sub?.other_featurelist?.length - 1
                                  ? "#fff"
                                  : "#000"
                              }`,
                              borderRight: "1px solid",
                            }}
                          >
                            {i === 0 ? sub?.featurelist_name : ""}
                          </td>
                          <td>{sub1?.featurelist_name}</td>
                          <td>{numberWithCommas(sub1?.featurelist_value)}</td>
                          <td>
                            {numberWithCommas(
                              Math.round(
                                sub1?.featurelist_value * currencyFactor
                              )
                            )}
                          </td>
                          <td>
                            {bmFeatureValues &&
                              bmFeatureValues[
                                sub1?.featurelist_feature_id
                              ]}{" "}
                          </td>
                          {compareFeatureValues &&
                            Object.keys(compareFeatureValues).map(
                              (key, idx) => {
                                return (
                                  <td>
                                    {
                                      compareFeatureValues[key][
                                        sub1?.featurelist_feature_id
                                      ]
                                    }
                                  </td>
                                );
                              }
                            )}
                        </tr>
                      );
                    });
                  }
                });
                //   });
              })}
              <tr style={{ borderBottom: "1px solid " }}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>
                  Manufacturing Selling Retail Price (MSRP) - Including VAT
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{numberWithCommas(bmData?.cdh_msrp)}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>{numberWithCommas(compareData[key]?.cdh_msrp)}</td>
                    );
                  })}
              </tr>

              <tr style={{ borderBottom: "1px solid " }}>
                <td>Total Options Value </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>
                  {numberWithCommas(
                    Math.round(bmData?.TOValue * currencyFactor)
                  )}
                </td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {numberWithCommas(
                          Math.round(
                            Math.round(
                              compareData[key]?.TOValue * currencyFactor
                            )
                          )
                        )}
                      </td>
                    );
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>
                  {" "}
                  Retail Incentive (Input the total offer value; example Cash
                  Discount, Free Service, Insurance, Finance, etc..
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{numberWithCommas(bmData?.cdh_total_offer_value)}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {numberWithCommas(
                          compareData[key]?.cdh_total_offer_value
                        )}
                      </td>
                    );
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>MSRP VA</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>
                  {numberWithCommas(
                    Math.round(
                      bmData?.cdh_msrp - bmData?.TOValue * currencyFactor
                    )
                  )}
                </td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {numberWithCommas(
                          Math.round(
                            compareData[key]?.cdh_msrp -
                              compareData[key]?.TOValue * currencyFactor
                          )
                        )}
                      </td>
                    );
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>TP</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{bmData?.cdh_msrp - bmData?.cdh_total_offer_value}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {numberWithCommas(
                          compareData[key]?.cdh_msrp -
                            compareData[key]?.cdh_total_offer_value
                        )}
                      </td>
                    );
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>TPVA</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>
                  {numberWithCommas(
                    Math.round(
                      bmData?.cdh_msrp -
                        bmData?.TOValue * currencyFactor -
                        bmData?.cdh_total_offer_value
                    )
                  )}
                </td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {numberWithCommas(
                          Math.round(
                            compareData[key]?.cdh_msrp -
                              compareData[key]?.TOValue * currencyFactor -
                              compareData[key]?.cdh_total_offer_value
                          )
                        )}
                      </td>
                    );
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>Visual Index (VI) %</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{"100%"}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {Math.round(
                          (compareData[key]?.cdh_msrp / bmData?.cdh_msrp) * 100
                        ) + "%"}
                      </td>
                    );
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>Value Adjusted (VA) %</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{"100%"}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {Math.round(
                          ((compareData[key]?.cdh_msrp +
                            (bmData?.TOValue * currencyFactor -
                              compareData[key]?.TOValue * currencyFactor)) /
                            bmData?.cdh_msrp) *
                            100
                        ) + "%"}
                      </td>
                    );
                  })}
              </tr>
              <tr style={{ borderBottom: "1px solid " }}>
                <td>Transaction Price VA (TPVA) %</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{currencyCode}</td>
                <td>{"100%"}</td>
                {compareData &&
                  Object.keys(compareData).map((key, idx) => {
                    return (
                      <td>
                        {Math.round(
                          ((compareData[key]?.cdh_msrp +
                            (bmData?.TOValue * currencyFactor -
                              compareData[key]?.TOValue * currencyFactor) -
                            compareData[key]?.cdh_total_offer_value) /
                            (bmData?.cdh_msrp -
                              bmData?.cdh_total_offer_value)) *
                            100
                        ) + "%"}
                      </td>
                    );
                  })}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default FeaturesTable;
