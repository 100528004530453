import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../api/axios";
import { toast } from "react-toastify";
import userLogo from "../../assets/images/userLogo.jpg";

const UserLogin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  // const ForgotPassHandler = () => {
  //     navigate("/user/fogotpass");
  // }

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosPrivate
      .post("auth/login", { ...formData })
      .then((res) => {
        console.log(res);
        if (res?.data?.accessToken) {
          // toast.success("Login Success", {
          //   position: "top-left",
          //   autoClose: 2000,
          // });
          localStorage.setItem(
            "dashboard-user",
            JSON.stringify({ token: res?.data?.accessToken })
          );
          // navigate("/dashboard");
          window.location.assign("/");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          toast.error("Invalid credentials", {
            position: "top-left",
            autoClose: 2000,
          });
          Promise.reject(err);
        } else {
          Promise.reject(err);
        }
      });
  };
  return (
    <div className="container-fluid fill">
      <div className="row fill-main">
        <div className="col-md-6 text-center login-area">
          <div className="mt-5">
            <img src={userLogo} alt="" className="login-logo" />
          </div>
          <form className="login-form" onSubmit={handleSubmit} action="">
            <input
              onChange={onInputChange}
              className="input-feilds mt-3 pb-2 ps-2 pt-2"
              type="email"
              name="email"
              placeholder="Email"
            />
            <input
              onChange={onInputChange}
              className="input-feilds mt-3 pb-2 ps-2 pt-2"
              type="password"
              name="password"
              placeholder="Password"
            />
            {/* <div className="form-check remember-me-inpt mt-4 text-left ml-5 pl-5">
              <input
                type="checkbox"
                className="form-check-input ml-4"
                id="exampleCheck1"
              />
              <label
                className="form-check-label ml-5 p-2"
                htmlFor="exampleCheck1"
              >
                Remember Me
              </label>
            </div> */}
            <div className="login-buttn">
              <button
                type="submit"
                className="btn btn-primary mt-3 login-btn"
                id="login-btn"
              >
                Login
              </button>

              {/* <button type="submit" className="btn btn-primary mt-3 login-btn">Create Account</button> */}
            </div>
          </form>
          {/* <div>
                        <p className="form-check-label forget-pass mt-4" onClick={ForgotPassHandler}><u>Forgot Password</u></p>
                    </div> */}
        </div>
        <div className="col-md-6 text-center">
          <div className=" right-data">
            <h3 className="align-middle right-data-header">
              Welcome To Feedback
            </h3>
            {/* <p>
              Viva text is simply dummy text of the
              <br />
              printing and typesetting industry
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
