import axios from "axios";
// const token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFAcmVvbnRlbC5jIiwicm9sZSI6MSwiaWQiOjQ3LCJpYXQiOjE2NjQxNjc4NjUsImV4cCI6MTY2NTQ2Mzg2NX0.d2RHTBJ-jBupXT00BJPHSn2QToWlRXspF7rQh_Iuzfc";
let token = JSON.parse(localStorage.getItem("user"))?.token;
let dashboardToken = JSON.parse(localStorage.getItem("dashboard-user"))?.token;

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_USER_URL,
  // baseURL: "http://localhost:4000/",
  withCredentials: true,
  timeout: 200000,
});
export const axiosAdmin = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL,
  // baseURL: "http://localhost:3333/api/",
  timeout: 120000,
  withCredentials: true,
});

axiosAdmin.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});
axiosPrivate.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${dashboardToken}`;

  return config;
});

export const logout = () => {
  axiosAdmin
    .delete("user/signout")
    .then((res) => res.then((res) => res).catch((err) => Promise.reject(err)));
  localStorage.clear();
  window.location.assign("/admin/login");
};
export const logoutUser = () => {
  localStorage.clear();
  window.location.assign("/login");
};

axiosAdmin.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status !== 401) {
      return Promise.reject(error);
    }

    //axios.interceptors.response.eject(interceptor);
    if (
      error.response.status === 401 &&
      error.response.data.message === "token expired"
    ) {
      alert("Session expired, Please login again");
      logout();
      // return axiosAdmin
      //   .get("user/tokenRefresh")
      //   .then((response) => {
      //     token = response?.data?.token;
      //     axiosAdmin.interceptors.request.use(function (config) {
      //       config.headers.Authorization = `Bearer ${token}`;

      //       return config;
      //     });
      //     error.response.config.headers["Authorization"] = "Bearer " + token;
      //     localStorage.setItem(
      //       "user",
      //       JSON.stringify({ token: response?.data?.token })
      //     );
      //     return axios(error.response.config);
      //   })
      //   .catch((error) => {
      //     return Promise.reject(error);
      //   });
    } else if (
      error.response.status === 401 &&
      (error.response.data.message === "session expired" ||
        error.response.data.message === "invlid token")
    ) {
      alert("Session expired, Please login again");
      logout();
    } else {
      return Promise.reject(error);
    }
  }
);
