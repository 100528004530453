export const waveMonthsData = [
  { id: "01", month: "January" },
  { id: "02", month: "February" },
  { id: "03", month: "March" },
  { id: "04", month: "April" },
  { id: "05", month: "May" },
  { id: "06", month: "June" },
  { id: "07", month: "July" },
  { id: "08", month: "August" },
  { id: "09", month: "September" },
  { id: "10", month: "October" },
  { id: "11", month: "November" },
  { id: "12", month: "December" },
];
