import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import pptxgen from "pptxgenjs";
import { waveMonths } from "../../../data/wave-month-data";

const PriceLadderChart = ({ data, currencyCode, waveSelected }) => {
  let ladderChart = new pptxgen();

  let slide = ladderChart.addSlide();

  const chart = useRef();

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const Y_AXIS_MAX = 800000;
  const Y_AXIS_INTERVAL = 30000;
  let chartData = [];
  if (data?.brands) {
    data?.brands.map((item) => {
      chartData.push({
        name: item,
        y: 200000,
        visible: false,
      });
    });
  }
  let chartValues = [];
  if (data?.ladderData) {
    Object.keys(data?.ladderData).forEach(function (key, index) {
      data?.ladderData[key]?.map((item) => {
        chartData.push({
          name: item.trims.name,
          y: item.cdh_msrp,

          visible: false,
        });
        chartValues.push({
          data: [item.cdh_msrp],
          name: item?.brands.name,
        });
      });
    });
  }

  const getChartXAxis = () => {
    const MITSUBISHI = "MITSUBISHI";
    if (Array.isArray(data?.brands) && data?.brands?.length > 0) {
      // getting mitsubishi as first data;
      let firstData = data?.brands.find((item) => {
        return item.split(" ")[0] === MITSUBISHI;
      });
      let filteredBrands = data?.brands.filter((brand, brandIndex) => {
        return brand.split(" ")[0] !== MITSUBISHI;
      });
      return [firstData, ...filteredBrands];
    }
    return [];
  };

  const getPlotData = () => {
    if (data?.ladderData) {
      let bmData = data.ladderData.MITSUBISHI;
      // console.log("bmData", bmData);
      delete data?.ladderData.MITSUBISHI;
      if (bmData) {
        data.ladderData = { MITSUBISHI: bmData, ...data.ladderData };
      }
      // data.ladderData.MITSUBISHI = firstData;
      let tmp = [];
      let dataIndex = 0;
      for (const key in data.ladderData) {
        tmp.push({
          // dataLabels: data?.ladderData[key]?.map((it, i) => {
          //   console.log("waveItem", it);

          //   return {
          //     // borderColor: "red",
          //     // borderWidth: 2,
          //     // padding: 5,
          //     // shadow: true,
          //     style: {
          //       fontWeight: "bold",
          //       color: `${it.replaced ? "white" : "black"}`,
          //     },
          //     // y: -6,
          //   };
          // }),
          name: `${key}`,
          // dataLabels: {
          //   borderColor: "red",
          //   borderWidth: 2,
          //   padding: 5,
          //   shadow: true,
          //   style: {
          //     fontWeight: "bold",
          //     color: `${itm.replaced ? "white" : ""}`,
          //   },
          // },
          tmpData: data.ladderData[key].map((itm, idx) => {
            return {
              modelName: itm.models.name,
              trimName: itm.trims.name,
              trimNameSpec:
                itm.trim_name_spec +
                " - " +
                "MY" +
                String(itm.cdh_car_year).slice(-2),
              msrp: itm.cdh_msrp,
              wave: itm.cdh_wave,
              replaced: itm?.replaced ? true : false,
            };
          }),
          data: data.ladderData[key].map((item, index) => {
            return [dataIndex, item.cdh_msrp];
          }),
        });
        dataIndex += 1;
      }
      return tmp;
    }
    return [];
  };

  let plotData = getPlotData();
  let averageData = [];
  let val = 0;
  let count = 0;

  plotData?.map((plot, index) => {
    plot?.data?.map((data, ind) => {
      count += 1;
      val += data[1];
    });
  });

  plotData?.map((plot, i) => {
    averageData.push([i, Math.round(val / count)]);
  });

  const options = {
    chart: {
      type: "scatter",
      zoomType: "y",
      height: 650,
    },
    // title: {
    //   text: "MitB",
    // },
    // subtitle: {
    //   text: "Source: Heinz  2003",
    // },
    credits: {
      enabled: false,
    },
    xAxis: {
      /*   title: {
            enabled: true,
            text: 'Height (cm)'
        }, */
      /*    startOnTick: true,
        endOnTick: true,
        showLastLabel: true, */
      alternateGridColor: "#E7EFF7",
      categories: getChartXAxis(),
    },
    title: {
      text: "",
    },
    yAxis: {
      // allowDecimals: false,
      // max: Y_AXIS_MAX,
      // tickInterval: Y_AXIS_INTERVAL,

      stackLabels: {
        enabled: true,
        style: {
          color: "#000000b7",
        },
        /*   formatter: function () {
          let a;
          chartData.map((dat) => {
            if (dat.y === this.total) {
              a = dat.name;
            }
          });
          return a;
        }, */
      },
    },

    tooltip: {
      formatter: function () {
        // console.log(
        //   "thisthisthis",
        //   this.point.series.userOptions.tmpData[0].modelName
        // );
        console.log("this", this);
        const index = this?.point?.index;
        let wave = "";
        wave =
          waveMonths[
            Number(
              this?.point?.series?.userOptions?.tmpData[index]?.wave?.slice(-2)
            ) - 1
          ] +
          "-" +
          "20" +
          this?.point?.series?.userOptions?.tmpData[index]?.wave?.slice(0, 2);
        if (this?.point?.series?.userOptions?.tmpData[index]?.modelName) {
          return ` Wave:  ${wave}<br/>Model: ${this?.point?.series?.userOptions?.tmpData[index]?.modelName} <br/>Trim:  ${this?.point?.series?.userOptions?.tmpData[index]?.trimName} 
            <br/>
  MSRP: ${this?.point?.series?.userOptions?.tmpData[index]?.msrp} ${currencyCode}`;
        } else {
          return null;
        }
      },
      shared: true,
    },
    legend: {
      enabled: false,
      layout: "horizontal",
      align: "left",
      verticalAlign: "bottom",
      x: 0,
      y: 30,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
      borderWidth: 1,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            // color: "red",
          },
          formatter: function () {
            /*  if (this.point.value) { */
            return this.point.series.userOptions.name;
            /*    } */
          },
        },
      },
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: true,
            },
          },
        },

        dataLabels: {
          useHTML: true,
          enabled: true,
          style: {
            fontWeight: "bold",
          },
          formatter: function () {
            // console.log("Scatter Data Label", this);
            // console.log("daaaaaataaaa", this.point.series.userOptions);
            //${this.point.series.userOptions.tmpData[pointIndex].modelName}

            let pointIndex = this?.point?.index;
            let wave = "";
            wave =
              waveMonths[
                Number(
                  this?.point?.series?.userOptions?.tmpData[
                    pointIndex
                  ]?.wave?.slice(-2)
                ) - 1
              ] +
              "-" +
              "20" +
              this?.point?.series?.userOptions?.tmpData[
                pointIndex
              ]?.wave?.slice(0, 2);
            if (
              this?.point?.series?.userOptions?.tmpData[pointIndex]?.wave !==
              waveSelected
            ) {
              return (
                `${
                  this?.point?.series?.userOptions?.tmpData[pointIndex]
                    ?.trimNameSpec
                } 
            - ${numberWithCommas(
              this?.point?.series?.userOptions?.tmpData[pointIndex]?.msrp
            )} ${currencyCode}` +
                `<span style={{color: 'red'}} class='right'>(${wave})</span>`
              );
            } else {
              return `${
                this?.point?.series?.userOptions?.tmpData[pointIndex]
                  ?.trimNameSpec
              } 
            - ${numberWithCommas(
              this?.point?.series?.userOptions?.tmpData[pointIndex]?.msrp
            )} ${currencyCode}`;
            }
          },
        },
      },
    },

    series: [
      {
        type: "spline",
        name: "Average Line",
        data: [...averageData],
        lineWidth: 5,
        marker: {
          enabled: true,
        },
        plotOptions: {
          series: {
            lineWidth: 5,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (
              this &&
              typeof this?.x === "string" &&
              this?.x?.includes("MITSUBISHI")
            ) {
              return (
                "ALL Trims Average: " +
                numberWithCommas(this.y) +
                " " +
                currencyCode
              );
            }
          },
        },
      },
      ...getPlotData(),
    ],
  };
  let plot = getPlotData();
  let pptData = [];
  plot &&
    plot.map((item, i) => {
      let obj = {
        name: "",
        values: [],
        labels: [],
      };
      obj.name = item.name;

      item.tmpData.map((tmp, i) => {
        obj.values.push(tmp.msrp);
        obj.labels.push(tmp.trimName);
      });
      pptData.push(obj);
      // pptData.push({});
    });
  // pptData.push({
  //   name: "X-Axis",
  //   values: [5, 6, 7, 8, 9],
  // });

  chartData &&
    slide.addChart(ladderChart.ChartType.scatter, pptData, {
      showLegend: true,
      legendPos: "t",
      x: 0.5,
      y: 0.6,
      w: "100%",
      h: "100%",
      valAxisTitle: "MSRP",
      valAxisTitleColor: "428442",
      valAxisTitleFontSize: 14,
      showValAxisTitle: true,
      lineSize: 0,
      catAxisTitle: "Brands",
      catAxisTitleColor: "428442",
      catAxisTitleFontSize: 14,
      showCatAxisTitle: true,
      showLabel: true, // Must be set to true or labels will not be shown
      dataLabelPosition: "r", // Options: 't'|'b'|'l'|'r'|'ctr'
    });

  // console.log("pptData", pptData);

  return (
    <>
      {/* <button
        onClick={() => ladderChart.writeFile({ fileName: "react-demo.pptx" })}
      >
        Download
      </button> */}
      <div className="col-lg my-3 mb-3">
        <i className="bi bi-printer-fill" />
        <HighchartsReact
          id="barchart"
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </>
  );
};

export default PriceLadderChart;
