import * as BsIcons from "react-icons/bs";
export const sidebarItems = [
  // {
  //   id: 1,
  //   path: "#",
  //   name: "Dash Board",
  //   icon: <BsIcons.BsGrid />,
  // },
  {
    id: 2,
    path: "pricing-summary",
    name: "Pricing Summary",
    icon: <BsIcons.BsCurrencyExchange />,
  },
  {
    id: 3,
    path: "price-ladder",
    name: "Price Ladder",
    icon: <BsIcons.BsCreditCard2Back />,
  },
  {
    id: 4,
    path: "transaction-price-trend",
    name: "Price Trend",
    icon: <BsIcons.BsCurrencyDollar />,
  },
  {
    id: 5,
    path: "your-choice",
    name: "Your Choice",
    icon: <BsIcons.BsCalendarEvent />,
  },
  {
    id: 6,
    path: "fixed-segments",
    name: "VIVA-Fixed Segments",
    icon: <BsIcons.BsFileEarmarkCheck />,
  },

  {
    id: 7,
    path: "supporting-documents",
    name: "Supporting Documents",
    icon: <BsIcons.BsFileEarmarkMedical />,
  },
  {
    id: 8,
    path: "summary-of-models",
    name: "Summary of Models",
    icon: <BsIcons.BsFileEarmarkMedical />,
  },
  // {
  //   id: 8,
  //   path: "ppt-reports",
  //   name: "PPT Reports",
  //   icon: <BsIcons.BsFileEarmarkMedical />,
  // },

  // {
  //   id: 9,
  //   path: "#",
  //   name: "Logout",
  //   icon: <BsIcons.BsBoxArrowLeft />,
  // },
];
