import React, { useEffect, useState } from "react";
import pptxgen from "pptxgenjs";
import { axiosPrivate } from "../../../api/axios";
import { waveMonths } from "../../../data/wave-month-data";
import { AiOutlineFilePpt } from "react-icons/ai";

const TPVAPptx = ({
  data,
  waveForTPVA,
  bmModelSelected,
  bmTrimSelected,
  countrySelected,
  segment,
  bmMainData,
  currentRow,
  trimSelected,
  togglerPosition,
  bmAverage,
  rowData,
  bmAverageData,
  bmWaveSelected,
  currencyFactor,
}) => {
  const [tpvaData, setTpvaData] = useState({});
  const [bmTpvaData, setBmTpvaData] = useState({});
  const [categories, setCategories] = useState([]);
  const [pptxTpvaData, setPptxTpvaData] = useState();
  const [bmData, setBmData] = useState();
  const [tpvaWaves, setTpvaWaves] = useState([]);

  // const waveDiff = waveForTPVA?.length > 12 ? 12 : waveForTPVA?.length;

  const waveDiff = waveForTPVA;

  let tpvaChart = new pptxgen();
  console.log("waveDiff", waveDiff);
  console.log("bmData", bmData);
  console.log("bmAverageData", bmAverageData);

  let slide = tpvaChart.addSlide();

  useEffect(() => {
    bmAverage ? setBmData(bmAverageData) : setBmData(bmMainData);
  }, [bmMainData, bmAverageData, bmAverage]);

  useEffect(() => {
    if (waveForTPVA) {
      const currentPeriod = waveForTPVA;
      setTpvaWaves(currentPeriod);
      setCategories([]);
      currentPeriod.map((item) => {
        setCategories((prev) => [
          ...prev,
          waveMonths[Number(String(item).slice(-2)) - 1] +
            "-" +
            String(item).slice(0, 2),
        ]);
      });
    }
  }, [waveForTPVA]);
  //////////////////////////For Getting all monts data for Benchmark Model////////
  useEffect(() => {
    bmModelSelected &&
      bmWaveSelected &&
      bmTrimSelected &&
      tpvaWaves?.length &&
      bmTrimSelected !== -1 &&
      Array.apply(0, Array(waveDiff)).map((item, i) => {
        axiosPrivate
          .post(`yourChoice`, {
            countryId: Number(countrySelected),
            variantId: bmTrimSelected,
            segment: segment ? 1 : 2,
            wave: String(tpvaWaves[i]),
          })
          .then((res) => {
            let compRes = res?.data?.data;
            setBmTpvaData((prev) => ({
              ...prev,

              [tpvaWaves[i]]: 0,
            }));
            if (compRes?.cdh_id) {
              axiosPrivate
                .get(`features/${compRes?.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  compRes.TOValue = data;
                  // let wav = {tpvaWaves[i]: compRes}
                  console.log("compRes", compRes);
                  setBmTpvaData((prev) => ({
                    ...prev,

                    [tpvaWaves[i]]: compRes,
                  }));
                })
                .catch((e) => Promise.reject(e));
            }
          })
          .catch((e) => Promise.reject(e));
      });
  }, [
    bmTrimSelected,
    togglerPosition,
    segment,
    bmAverage,
    bmWaveSelected,
    tpvaWaves,
  ]);
  ////////////////////////////////////////////////////////////////////////////////////
  console.log("bmTpvaData", bmTpvaData);
  //////////////////////////For getting all months Data for compare Models////////
  useEffect(() => {
    bmData &&
      trimSelected &&
      trimSelected[currentRow] &&
      trimSelected[currentRow] !== -1 &&
      Array.apply(0, Array(waveDiff)).map((item, i) => {
        console.log("currentIndex", i, tpvaWaves[i]);

        axiosPrivate
          .post(`yourChoice`, {
            countryId: Number(countrySelected),
            variantId: trimSelected[currentRow],
            segment: segment ? 1 : 2,
            wave: String(tpvaWaves[i]),
          })
          .then((res) => {
            let compRes = res?.data?.data;
            setTpvaData((prev) => ({
              ...prev,
              [currentRow]: {
                ...prev[currentRow],
                [tpvaWaves[i]]: 0,
              },
            }));
            compRes &&
              axiosPrivate
                .get(`features/${compRes?.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  compRes.TOValue = data;
                  // let wav = {tpvaWaves[i]: compRes}
                  setTpvaData((prev) => ({
                    ...prev,
                    [currentRow]: {
                      ...prev[currentRow],
                      [tpvaWaves[i]]: compRes,
                    },
                  }));
                })
                .catch((e) => Promise.reject(e));
          })
          .catch((e) => Promise.reject(e));
      });
  }, [trimSelected, segment, countrySelected, bmData, tpvaWaves]);

  //////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   bmTpvaData &&
  //     categories &&
  //     setPptxTpvaData([
  //       {
  //         labels: categories,
  //         values: [100, 100],
  //         name: "misibushi",
  //       },
  //     ]);
  // }, [bmTpvaData, categories]);
  console.log("tpvaWaves", tpvaWaves);

  useEffect(() => {
    console.log("xxtpvaData", tpvaData);
    console.log("xxwaveDiff", waveDiff);
    console.log("xxtpvaData", tpvaWaves);
    let data = [];
    let values = [];
    Array.apply(1, Array(waveDiff))?.map((item, i) => {
      values.push(100);
    });
    console.log("bmTrimName", bmData?.trims?.name);
    bmTpvaData &&
      tpvaWaves?.length &&
      data.push({
        labels: categories,
        values,
        name:
          // bmTpvaData[tpvaWaves[0]]?.models.name +
          !bmAverage
            ? bmData?.models?.name + " " + bmData?.trims?.name
            : bmData?.models?.name + " " + "Average",
        // bmTpvaData[tpvaWaves[0]]?.trims.name,
      });

    Object.keys(tpvaData)?.length &&
      tpvaWaves?.length &&
      bmTpvaData &&
      Object.keys(tpvaData)?.map((key, i) => {
        let name = "";
        let dat = [];
        let tpva = 0;
        tpvaWaves?.map((cat, index) => {
          console.log("cat", cat, key);
          let currentItem = tpvaData[key][cat];
          console.log("currentItem", currentItem, rowData[index]?.average);
          let bmItem = bmTpvaData[cat];
          // console.log("CurrentItem", bmItem);
          if (currentItem !== 0) {
            console.log("currentItem?.cdh_id", currentItem?.cdh_msrp);
            console.log("bmItem?.cdh_id", bmItem?.TOValue);
            tpva =
              Math.round(
                ((currentItem?.cdh_msrp +
                  (bmItem?.TOValue * currencyFactor -
                    currentItem?.TOValue * currencyFactor) -
                  currentItem?.cdh_total_offer_value) /
                  (bmItem?.cdh_msrp - currentItem?.cdh_total_offer_value)) *
                  100
              ) || 0;
            console.log("rowdataParam", Boolean(rowData[index]?.average));
            if (!Boolean(rowData[index]?.average)) {
              console.log("average", name);
              !name &&
                (name = `${currentItem?.models.name || " "}   ${
                  currentItem?.trims.name || " "
                }`);
            } else {
              name = "Average";
            }
          } else {
            tpva = 100;
          }
          console.log("tpvaData::", cat, tpva, currentItem?.cdh_msrp);
          dat.push(tpva);
        });
        data.push({
          values: dat,
          labels: categories,
          name,
        });
      });
    setPptxTpvaData(data);
  }, [tpvaData, tpvaWaves, bmTpvaData, bmData, bmAverage, rowData]);

  console.log("pptxTpvaData", pptxTpvaData);

  console.log("tpvaData", tpvaData);
  console.log("currentRow", currentRow);
  pptxTpvaData &&
    slide.addChart(
      tpvaChart.ChartType.line,
      // [
      //   {
      //     labels: [["aug", "uyu"]],
      //     values: [100, 123],
      //     name: "mis",
      //   },
      // ],
      pptxTpvaData,
      {
        showLegend: true,
        legendPos: "t",
        showLabel: false,
        dataLabelPosition: "bestFit",
        lineSize: 1,
        catGridLine: { style: "none" },
        valGridLine: { style: "none" },
        showPercent: true,
        showValue: true, // for datalabels
        dataLabelFontSize: 7,
        catAxisMultiLevelLabels: true,
        lineDataSymbol: "none",
        // showDataTable: true,
        // showDataTableKeys: true,
        lineSize: "none",
        // dataLabelFormatCode: "0MY22",

        // showPercent:
        // showLeaderLines: true,

        // lineDataSymbol: "none",
        x: 1,
        y: 1,
        w: 8,
        h: 4,
      }
    );

  slide.addText("TPVA", {
    x: 1,
    y: 0.14,
    w: 3,
    h: 1,
    fontSize: 20,
    bold: true,
    // paraSpaceBefore: 12,
    // paraSpaceAfter: 24,
  });
  return (
    <>
      {" "}
      <button
        className="btn_pptx"
        onClick={() => tpvaChart.writeFile({ fileName: "TPVA.pptx" })}
      >
        <AiOutlineFilePpt />
        <span style={{ fontSize: ".6rem" }}>TPVA</span>
      </button>
    </>
  );
};

export default TPVAPptx;
