import React, { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import * as BsIcons from "react-icons/bs";

const NetTransactionTable = ({
  priceTrendData,
  priceTrend,
  categories,
  currency,
  ref,
}) => {
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "NTP Table",
    sheet: "Users",
  });
  return (
    <>
      <div className="user-table">
        <div className="net-transaction__header">
          <h6>Net Transaction Price</h6>
          <span onClick={onDownload}>
            <BsIcons.BsFileEarmarkExcel />
          </span>
        </div>
        <table ref={tableRef} id="l">
          <thead>
            <tr>
              <th>Model</th>
              {/* <th>Last Model Year</th> */}
              <th>Trim</th>
              {/* <th>Launch Month</th> */}
              {categories?.map((item) => (
                <th>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {priceTrendData &&
              Object.keys(priceTrendData?.bmTrims).map((key, index) => (
                <tr>
                  <td>
                    {priceTrendData?.bmTrims[key][
                      priceTrendData?.bmTrims[key]?.length - 1
                    ]?.brandName +
                      " " +
                      priceTrendData?.bmTrims[key][
                        priceTrendData?.bmTrims[key]?.length - 1
                      ]?.modelName}
                  </td>
                  <td>
                    {
                      priceTrendData?.bmTrims[key][
                        priceTrendData?.bmTrims[key]?.length - 1
                      ]?.trim_name_spec
                    }
                  </td>
                  {priceTrendData?.bmTrims[key]?.map((item) => (
                    <td>
                      {currency
                        ? item[priceTrend]
                        : item[`${priceTrend}_dollar`]}
                    </td>
                  ))}
                </tr>
              ))}
            {priceTrendData &&
              Object.keys(priceTrendData?.competTrims).map((key, index) => (
                <tr>
                  <td>
                    {priceTrendData?.competTrims[key][
                      priceTrendData?.competTrims[key]?.length - 1
                    ]?.brandName +
                      " " +
                      priceTrendData?.competTrims[key][
                        priceTrendData?.competTrims[key]?.length - 1
                      ]?.modelName}
                  </td>
                  <td>
                    {
                      priceTrendData?.competTrims[key][
                        priceTrendData?.competTrims[key]?.length - 1
                      ]?.trim_name_spec
                    }
                  </td>
                  {priceTrendData?.competTrims[key]?.map((item) => (
                    <td>
                      {currency
                        ? item[priceTrend]
                        : item[`${priceTrend}_dollar`]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NetTransactionTable;
