import React, { useState } from "react";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { axiosAdmin } from "../../../api/axios";
import { useEffect } from "react";

const AddBrand = ({
  show,
  setShow,
  formType,
  editBrandData,
  setBrandsData,
}) => {
  const [brandData, setBrandData] = useState(null);
  useEffect(() => {
    formType === "Edit" &&
      setBrandData({
        ...brandData,
        name: editBrandData?.name,
        brandCode: editBrandData?.code,
        id: editBrandData?.id,
      });
  }, [editBrandData]);

  useEffect(() => {
    setBrandData({
      ...brandData,
      name: "",
      countryCode: "",
      currencyCode: "",
    });
  }, [formType]);

  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setBrandData({ ...brandData, [name]: value });
  };
  const handleSubmitModel = (e) => {
    e.preventDefault();
    if (formType === "Add" && !isNaN(Number(brandData?.brandCode))) {
      axiosAdmin
        .post("brand/addBrand", {
          name: brandData?.name,
          code: brandData?.brandCode,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Country added", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("brand/getAllBrands")
              .then((res) => {
                let data = [];
                res.data.data.map((brand, i) => {
                  data.push({
                    key: brand?.id,
                    serialNum: String(i + 1),
                    name: brand?.name,
                    id: String(brand?.id),
                    status: brand?.status ? 1 : 0,
                  });
                });
                setBrandsData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setBrandData({
              ...brandData,
              name: "",
              brandCode: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Brand name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Brand code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else if (formType === "Edit" && !isNaN(Number(brandData?.brandCode))) {
      axiosAdmin
        .patch("brand/updateBrand", {
          name: brandData?.name,
          code: brandData?.brandCode,
          id: brandData?.id,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Brand updated", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("brand/getAllBrands")
              .then((res) => {
                let data = [];
                res.data.data.map((brand, i) => {
                  data.push({
                    key: brand?.id,
                    serialNum: String(i + 1),
                    name: brand?.name,
                    id: String(brand?.id),
                    status: brand?.status ? 1 : 0,
                  });
                });
                setBrandsData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setBrandData({
              ...brandData,
              name: "",
              brandCode: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Brand name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Brand code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else {
      toast.error("Brand code should be a number", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`${formType} Brand`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitModel} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`Name`}</Form.Label>
              <Form.Control
                required
                onChange={handleModelChange}
                type="text"
                placeholder={`Enter Brand Name`}
                value={brandData?.name}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Brand code`}</Form.Label>
              <Form.Control
                onChange={handleModelChange}
                required
                minLength={2}
                maxLength={2}
                type="text"
                placeholder={`Enter Brand Code`}
                value={brandData?.brandCode}
                name="brandCode"
              />
            </Form.Group>

            <Button className=" w-100 mx-auto" type="submit">
              {`${formType === "Edit" ? "Update" : "Add"} Brand`}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBrand;
