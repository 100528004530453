import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import vivaLogo from "../../assets/images/userLogo.jpg";

import { axiosAdmin } from "../../api/axios";

const Login = () => {
  const [formData, setFormData] = useState(null);

  const navigate = useNavigate();

  const onFormValueChange = (e) => {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    axiosAdmin
      .post("user/signin", { ...formData })
      .then((res) => {
        if (res?.data?.success) {
          // toast.success("Login Success", {
          //   position: "top-left",
          //   autoClose: 2000,
          // });
          localStorage.setItem(
            "user",
            JSON.stringify({ token: res?.data?.token })
          );
          // navigate("/dashboard");
          window.location.assign("/admin");
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.message === "invalid credentials"
        ) {
          toast.error("Invalid credentials", {
            position: "top-left",
            autoClose: 2000,
          });
        } else {
          Promise.reject(err);
        }
      });
  };

  return (
    <>
      <div className="admin-login">
        <div className="admin-login__form-area">
          {" "}
          <div className="admin-login__logo">
            <img src={vivaLogo} alt="" />
          </div>
          <Form
            name="normal_login"
            className="login-form"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="Email"
              style={{ color: "#fff" }}
              value={"formData?.email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Enter a valid email",
                },
              ]}
            >
              <Input
                type="email"
                value={"formData?.email"}
                name="email"
                onChange={onFormValueChange}
              />
            </Form.Item>
            <Form.Item
              name={"password"}
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Enter a password",
                },
              ]}
            >
              <Input
                type="password"
                value={formData?.password}
                name="password"
                onChange={onFormValueChange}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                className="login-form-button"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className="admin-login__brand-area"></div>
      </div>
    </>
  );
};

export default Login;
