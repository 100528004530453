import React, { useState, useRef, useEffect } from "react";
import { Button, Checkbox, Select } from "antd";
import * as BsIcons from "react-icons/bs";
import { useDownloadExcel } from "react-export-table-to-excel";
import ChartModal from "./charts/chart-modal";
import { axiosPrivate } from "../../api/axios";
import { Option } from "antd/lib/mentions";

import { waveMonths } from "../../data/wave-month-data";
import YourChoicePptx from "./pptx/your-choice-pptx";
import TPVAPptx from "./pptx/tpva-pptx";
import FeaturesTable from "./tables/featuresTable";
import ExcelExportHelper from "./xlsx/featuresXlsx";

const YourChoice = () => {
  /////////////////////////////Benchmark Data/ States///////////////////////////////////
  const [bmModelCodeSelected, setBmModelCodeSelected] = useState();
  const [bmData, setBmData] = useState(null);
  const [bmCarYear, setBmCarYear] = useState();
  const [bmCarYearData, setBmCarYearData] = useState("");
  const [bmModelSelected, setBmModelSelected] = useState(null);
  const [bmTrimSelected, setBmTrimSelected] = useState("");
  const [bmTrimName, setBmTrimName] = useState();
  const [bmTrimsData, setBmTrimsData] = useState();
  const [bmModelName, setBmModelName] = useState();
  const [bmAverage, setBmAverage] = useState();
  const [bmAverageData, setBmAverageData] = useState({});
  const [bmFeatures, setBmFeatures] = useState({});
  const [bmWaveSelected, setBmWaveSelected] = useState();
  const [bmDataAvg, setBmDataAvg] = useState([]);

  ////////////////////////////////////Compare Data States/////////////////////////////////
  const [togglerPosition, setTogglerPosition] = useState(true);
  const [show, setShow] = useState(false);
  const [countryData, setCountryData] = useState(null);
  const [countrySelected, setCountrySelected] = useState("");
  const [wavesData, setWavesData] = useState(null);
  const [waveSelected, setWaveSelected] = useState("");
  const [rows, setRows] = useState(0);
  const [currentRow, setCurrentRow] = useState();
  const [segment, setSegment] = useState(true);
  const [currency, setCurrency] = useState(true);
  const [modelData, setModelData] = useState(null);
  const [trimsData, setTrimsData] = useState(null);
  const [compareData, setCompareData] = useState(null);
  const [modelSelected, setModelSelected] = useState(null);
  const [trimSelected, setTrimSelected] = useState("");
  const [carYear, setCarYear] = useState("");
  const [carYearData, setCarYearData] = useState(null);
  const [currencyFactor, setCurrencyFactor] = useState();
  const [trimNames, setTrimNames] = useState();
  const [modelName, setModelName] = useState();
  const [compareModels, setCompareModels] = useState();
  const [currencyCode, setCurrencyCode] = useState("AED");
  const [rowData, setRowData] = useState(null);
  const [compareAvgData, setCompareAvgData] = useState();
  const [comparePercent, setComparePercent] = useState({});
  const [brandData, setBrandData] = useState([]);
  const [brandSelected, setBrandSelected] = useState({});
  const [features, setFeatures] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [showFeatures, setShowFeatures] = useState(false);
  const [compareWaveSelected, setCompareWaveSelected] = useState({});
  const [trimsDataAvg, setTrimsDataAvg] = useState({});

  const [waveForTPVA, setWaveForTPVA] = useState();
  const [compareFeatures, setCompareFeatures] = useState([]);
  const [VAT, setVAT] = useState();
  const [tpva, setTpva] = useState(true);
  // console.log("bmAverageData", bmAverageData);
  // console.log("bmData", bmData);
  // console.log("compareAvgData", compareAvgData);

  let modelCode = {};

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Your Choice table",
    sheet: "YourChoice",
  });

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    bmModelSelected &&
      axiosPrivate
        .post(`yourChoice/carYear`, {
          modelCode: bmModelCodeSelected,
          trimCode: bmTrimSelected,
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          // wave: String(waveSelected),
        })
        .then((res) => {
          setBmCarYearData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [togglerPosition, waveSelected, segment, bmModelSelected]);

  //////////////////for currency conversion. Values Are Taken from google search/////////////////////
  useEffect(() => {
    if (Number(countrySelected) === 1 && currency) {
      setCurrencyFactor(3.67);
      setCurrencyCode("AED");
      setVAT(5);
    } else if (Number(countrySelected) === 2 && currency) {
      setCurrencyFactor(3.75);
      setCurrencyCode("SAR");
      setVAT(15);
    } else if (Number(countrySelected) === 3 && currency) {
      setCurrencyFactor(19.18);
      setCurrencyCode("EGP");
      setVAT(14);
    } else {
      setCurrencyFactor(1);
    }
  }, [countrySelected, currency]);

  useEffect(() => {
    // setBmData();
  }, [bmCarYear, bmModelSelected]);

  useEffect(() => {
    // setCompareData();
    setTrimNames();
    setModelSelected();
    setCarYear();
    setBmCarYear();
    setBmModelSelected();
    setBmTrimName();
    setBmTrimSelected();
    setModelName();
    setBmData();
    setBmModelName();
    setRows(0);
  }, [segment, countrySelected]);

  useEffect(() => {
    setBmData(bmAverageData);
  }, [bmAverageData]);

  useEffect(() => {
    countrySelected &&
      axiosPrivate
        .post(`brand/brandByWave`, {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          // wave: String(waveSelected),
        })
        .then((res) => {
          setBrandData(res?.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [countrySelected]);

  ////////////////////////For calculating trims average for Benchmark Model
  useEffect(() => {
    setBmDataAvg([]);
    bmAverage &&
      bmCarYear &&
      bmWaveSelected &&
      axiosPrivate
        .post(`yourChoice/trims`, {
          countryId: Number(countrySelected),
          cdh_car_year: bmCarYear,
          modelCode: bmModelCodeSelected,
          segment: segment === true ? 1 : 2,
          month: Number(String(bmWaveSelected)?.slice(-2)),
          year: Number("20" + String(bmWaveSelected)?.slice(0, 2)),
        })
        .then((res) => {
          let bmRes = res.data.data;
          // let data = [];
          bmRes?.map((item, i) => {
            axiosPrivate
              .get(`features/${item?.cdh_id}`)
              .then((response) => {
                let data = response?.data?.data;
                // bmRes.TOValue = data;
                // data.push({ ...item, TOValue: response.data.data });
                setBmDataAvg((prev) => [...prev, { ...item, TOValue: data }]);

                // setBmData(bmRes);
              })
              .catch((e) => Promise.reject(e));
          });
        })
        .catch((e) => Promise.reject(e));
  }, [bmAverage, bmCarYear, bmWaveSelected]);

  useEffect(() => {
    let msrp = 0;
    let incentive = 0;
    let ntp = 0;
    let tov = 0;
    let msrpDollar = 0;
    let incentiveDollar = 0;
    let ntpDollar = 0;
    let my = "";
    let trims = [];
    let models = [];
    let brands = [];
    let cashDiscount = 0;
    let cashDiscountDollar = 0;

    bmDataAvg?.map((trim, i) => {
      msrp += trim.cdh_msrp;
      incentive += trim.cdh_total_offer_value;
      ntp += trim.cdh_net_transaction_price;
      tov += trim.TOValue;
      msrpDollar += trim.cdh_msrp_dollar;
      incentiveDollar += trim.cdh_total_offer_value_dollar;
      ntpDollar += trim.cdh_net_transaction_price_dollar;
      my = trim.carYear;
      trims = { ...trim.trims, name: "Average" };
      models = trim.models;
      brands = trim.brands;
      cashDiscount = trim?.cdh_cash_discount;
      cashDiscountDollar = trim?.cdh_cash_discount_dollar;
    });
    setBmAverageData({
      cdh_msrp: Math.round(msrp / bmDataAvg?.length),
      cdh_total_offer_value: Math.round(incentive / bmDataAvg?.length),
      cdh_net_transaction_price: Math.round(ntp / bmDataAvg?.length),
      TOValue: Math.round(tov / bmDataAvg?.length),
      cdh_msrp_dollar: Math.round(msrpDollar / bmDataAvg?.length),
      cdh_total_offer_value_dollar: Math.round(
        incentiveDollar / bmDataAvg?.length
      ),
      cdh_net_transaction_price_dollar: Math.round(
        ntpDollar / bmDataAvg?.length
      ),
      cdh_car_year: my,
      models,
      trims,
      brands,
      cdh_cash_discount: Math.round(cashDiscount / bmDataAvg?.length),
      cdh_cash_discount_dollar: Math.round(
        cashDiscountDollar / bmDataAvg?.length
      ),
    });
  }, [bmDataAvg, bmCarYear, bmTrimSelected]);
  /////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////For calculating trims average for compare models////////

  useEffect(() => {
    setCompareAvgData((prev) => ({ ...prev, [currentRow]: {} }));
    trimSelected[currentRow] === -1 &&
      carYear[currentRow] &&
      compareWaveSelected[currentRow] &&
      axiosPrivate
        .post(`yourChoice/trims`, {
          cdh_car_year: carYear[currentRow],

          countryId: Number(countrySelected),
          month: Number(String(compareWaveSelected[currentRow]).slice(-2)),
          year: Number(
            "20" + String(compareWaveSelected[currentRow]).slice(0, 2)
          ),
          modelCode: modelSelected,
          segment: segment === true ? 1 : 2,
        })
        .then((res) => {
          let bmRes = res.data.data;
          let temp = [];
          // let data = [];
          bmRes?.map((item, i) => {
            item?.cdh_id &&
              axiosPrivate
                .get(`features/${item?.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  temp.push({ ...item, TOValue: data });
                  // setBmTrimsData((prev) => [...prev, { ...item, TOValue: data }]);

                  // setBmData(bmRes);
                  i === bmRes?.length - 1 &&
                    setTrimsDataAvg((prev) => ({
                      ...prev,
                      [currentRow]: temp,
                    }));
                })
                .catch((e) => Promise.reject(e));
          });
          // setTrimsData({ ...trimsData, [currentRow]: res.data.data });
        })
        .catch((e) => Promise.reject(e));
  }, [trimSelected, carYear, compareWaveSelected]);

  useEffect(() => {
    trimsDataAvg &&
      Object.keys(trimsDataAvg).map((key, ind) => {
        let msrp = 0;
        let incentive = 0;
        let ntp = 0;
        let tov = 0;
        let msrpDollar = 0;
        let incentiveDollar = 0;
        let ntpDollar = 0;
        let my = "";
        let trims = [];
        let models = [];
        let brands = [];
        let cashDiscount = 0;
        let cashDiscount_dollar = 0;
        let cdh_value_other_offer = 0;

        trimsDataAvg[key].map((trim, i) => {
          msrp += trim.cdh_msrp;
          incentive += trim.cdh_total_offer_value;
          ntp += trim.cdh_net_transaction_price;
          tov += trim.TOValue;
          msrpDollar += trim.cdh_msrp_dollar;
          incentiveDollar += trim.cdh_total_offer_value_dollar;
          ntpDollar += trim.cdh_net_transaction_price_dollar;
          my = trim.carYear;
          models = trim.models;
          trims = { ...trim.trims, name: "Average" };
          brands = trim.brands;
          cashDiscount += trim?.cdh_cash_discount;
          cashDiscount_dollar += trim?.cdh_cash_discount_dollar;
          cdh_value_other_offer = trim?.cdh_value_other_offer;
        });
        setCompareAvgData({
          ...compareAvgData,
          [key]: {
            cdh_msrp: Math.round(msrp / trimsDataAvg[key]?.length),
            cdh_total_offer_value: Math.round(
              incentive / trimsDataAvg[key]?.length
            ),
            cdh_net_transaction_price: Math.round(
              ntp / trimsDataAvg[key]?.length
            ),
            TOValue: Math.round(tov / trimsDataAvg[key]?.length),
            cdh_msrp_dollar: Math.round(msrpDollar / trimsDataAvg[key]?.length),
            cdh_total_offer_value_dollar: Math.round(
              incentiveDollar / trimsDataAvg[key]?.length
            ),
            cdh_net_transaction_price_dollar: Math.round(
              ntpDollar / trimsDataAvg[key]?.length
            ),
            cdh_car_year: my,
            brands,
            trims,
            models,
            cashDiscount,
            cashDiscount_dollar,
            cdh_value_other_offer,
          },
        });
      });
  }, [trimsDataAvg, modelSelected, trimSelected, compareData]);

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  useEffect(() => {
    bmCarYear &&
      axiosPrivate
        .get("wave")
        .then((res) => {
          let allWaves = [];
          let waves = [];
          // console.log("waves", res.data.data);
          res.data.data.map((item) => {
            allWaves.push(item.code);
            waves.push({
              month: waveMonths[Number(String(item.code).slice(-2)) - 1],
              year: String(item.code).slice(0, 2),
              code: item.code,
              id: item.id,
            });
          });
          setWaveForTPVA(allWaves);
          setWavesData(waves);
        })
        .catch((e) => Promise.reject(e));
  }, [bmCarYear]);

  const handleCountryChange = (value, data) => {
    // setSegment(segment === true ? true : false);
    setCountrySelected(value);
    setCountryName(data?.children);
  };

  const onAddRow = () => {
    if (bmData || bmAverage) {
      setRows((prev) => prev + 1);
    }
    // setModelSelected();
    // setTrimSelected();
  };
  const onDeleteRow = () => {
    rows > 0 && setRows((prev) => prev - 1);
    // setCompareData((prev) => ({ ...prev, [rows]: "" }));
    // delete compareData.rows;
    // compareFeatures.pop();
    setCompareFeatures([]);
    compareData && setCompareData((prev) => delete prev.rows);
  };

  //////////////////////////Table///////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    axiosPrivate
      .post("competModels/benchmarkModels", {
        countryId: Number(countrySelected),
        // month: Number(String(waveSelected).slice(-2)),
        // year: Number("20" + String(waveSelected).slice(0, 2)),
        segment: segment ? 1 : 2,
      })
      .then((res) => {
        setModelData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
  }, [countrySelected, waveSelected, segment]);

  /////////////////////////////For Benchmark Data///////////////////////////////////

  useEffect(() => {
    modelCode = modelData?.map((model) => bmModelSelected === model.id);
    setBmTrimsData([]);
    bmModelSelected &&
      axiosPrivate
        .post(`yourChoice/trims`, {
          countryId: Number(countrySelected),
          // month: Number(String(waveSelected).slice(-2)),
          // year: Number("20" + String(waveSelected).slice(0, 2)),
          modelCode: bmModelCodeSelected,
          segment: segment === true ? 1 : 2,
        })
        .then((res) => {
          let bmRes = res.data.data;
          // let data = [];
          bmRes?.map((item, i) => {
            axiosPrivate
              .get(`features/${item?.cdh_id}`)
              .then((response) => {
                let data = response?.data?.data;
                // bmRes.TOValue = data;
                // data.push({ ...item, TOValue: response.data.data });
                setBmTrimsData((prev) => [...prev, { ...item, TOValue: data }]);

                // setBmData(bmRes);
              })
              .catch((e) => Promise.reject(e));
          });
        })
        .catch((e) => Promise.reject(e));
  }, [
    bmModelCodeSelected,
    bmModelSelected,
    countrySelected,
    segment,
    bmCarYear,
  ]);
  useEffect(() => {
    bmModelSelected &&
      bmWaveSelected &&
      bmTrimSelected &&
      bmTrimSelected !== -1 &&
      bmCarYear &&
      axiosPrivate
        .post(`yourChoice`, {
          variantId: bmTrimSelected,
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          wave: String(bmWaveSelected),
          carYear: Number(bmCarYear),
        })
        .then((res) => {
          // !compareData && setBmData([]);
          let bmRes = res?.data?.data;
          bmRes?.cdh_id
            ? axiosPrivate
                .get(`features/${bmRes?.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  bmRes.TOValue = data;

                  setBmData(bmRes);
                })
                .catch((e) => Promise.reject(e))
            : setBmData(bmRes);
        })
        .catch((e) => Promise.reject(e));
  }, [bmTrimSelected, segment, bmWaveSelected, countrySelected, bmCarYear]);

  /////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////For Compare Data///////////////////////////////////////////////
  console.log("brandSelected", brandSelected);
  useEffect(() => {
    countrySelected &&
      brandSelected &&
      brandSelected[currentRow] &&
      axiosPrivate
        .post("yourChoice/models", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          // wave: String(waveSelected),
          brandId: brandSelected[currentRow],
        })
        .then((res) => {
          const data = res?.data?.data;
          // const filteredData = data?.filter(
          //   (item, index) => item.brandName !== "MITSUBISHI"
          // );
          setCompareModels(data);
        })
        .catch((error) => Promise.reject(error));
  }, [countrySelected, segment, brandSelected]);

  useEffect(() => {
    // modelCode = modelData?.map((model) => modelSelected === model.id);
    modelSelected &&
      axiosPrivate
        .post(`yourChoice/trims`, {
          countryId: Number(countrySelected),
          // month: Number(String(waveSelected).slice(-2)),
          // year: Number("20" + String(waveSelected).slice(0, 2)),
          modelCode: modelSelected,
          segment: segment === true ? 1 : 2,
        })
        .then((res) => {
          let bmRes = res.data.data;
          let temp = [];
          // let data = [];
          bmRes?.map((item, i) => {
            item?.cdh_id &&
              axiosPrivate
                .get(`features/${item?.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  temp.push({ ...item, TOValue: data });
                  // setBmTrimsData((prev) => [...prev, { ...item, TOValue: data }]);

                  // setBmData(bmRes);
                  i === bmRes?.length - 1 &&
                    setTrimsData((prev) => ({
                      ...prev,
                      [currentRow]: temp,
                    }));
                })
                .catch((e) => Promise.reject(e));
          });
          // setTrimsData({ ...trimsData, [currentRow]: res.data.data });
        })
        .catch((e) => Promise.reject(e));
  }, [modelSelected, countrySelected, segment]);

  useEffect(() => {
    // bmData &&

    // segment &&
    modelSelected &&
      axiosPrivate
        .post("yourChoice/carYear", {
          countryId: Number(countrySelected),
          trimCode: trimSelected[currentRow],
          segment: togglerPosition ? 1 : 2,
          // wave: String(waveSelected),
          modelCode: modelSelected,
        })
        .then((res) => {
          setCarYearData((prev) => ({ ...prev, [currentRow]: res.data.data }));
        })
        .catch((e) => Promise.reject(e));
  }, [togglerPosition, segment, modelSelected, countrySelected, trimSelected]);

  useEffect(() => {
    bmData &&
      trimSelected &&
      trimSelected[currentRow] &&
      compareWaveSelected[currentRow] &&
      trimSelected[currentRow] !== -1 &&
      modelSelected &&
      carYear[currentRow] &&
      axiosPrivate
        .post(`yourChoice`, {
          countryId: Number(countrySelected),
          variantId: trimSelected[currentRow],
          segment: segment ? 1 : 2,
          carYear: Number(carYear[currentRow]),
          wave: String(compareWaveSelected[currentRow]),
          carYear: Number(carYear[currentRow]),
        })
        .then((res) => {
          // currentRow === -1 && setCurrentRow(0);
          // !compareData && setBmData([]);
          let compRes = res?.data?.data;
          compRes?.cdh_id
            ? axiosPrivate
                .get(`features/${compRes?.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  compRes.TOValue = data;

                  setCompareData({
                    ...compareData,
                    [currentRow]: compRes,
                  });
                })
                .catch((e) => Promise.reject(e))
            : setCompareData({
                ...compareData,
                [currentRow]: {},
              });
        })
        .catch((e) => Promise.reject(e));
  }, [
    trimSelected,
    modelSelected,
    togglerPosition,
    compareWaveSelected,
    countrySelected,
    segment,
    rows,
    carYear,
  ]);

  ///////////////////////////////////////////////////////////////////////////

  /////////////////////////////VI,VA,TPVA CALCULATIONS///////////////////////////////////////////

  // useEffect(() => {
  //   let compareObj;
  //   if (
  //     trimSelected &&
  //     trimSelected[currentRow] &&
  //     compareData &&
  //     compareData[currentRow]
  //   ) {
  //     compareObj =
  //       trimSelected[currentRow] === -1 ? compareAvgData : compareData;
  //     setComparePercent((prev) => ({
  //       ...prev,
  //       [currentRow]: {
  //         VI: Math.round(
  //           (compareObj[currentRow]?.cdh_msrp / bmData?.cdh_msrp) * 100
  //         ),
  //       },
  //     }));
  //   }
  // }, [currentRow, trimSelected, compareData, compareAvgData]);
  ///////////////////////////////////start from here//////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////

  let chartData = [bmData];
  console.log("trimsdata", trimsData);

  if (compareData) {
    Object.keys(compareData).forEach((key, index) => {
      compareData[key] && chartData.push(compareData[key]);
    });
  }

  const handleTrimChange = (value, data) => {
    value === -1
      ? setRowData((prev) => ({ ...prev, [currentRow]: { average: true } }))
      : setRowData((prev) => ({ ...prev, [currentRow]: { average: false } }));
    setTrimSelected((prev) => ({ ...prev, [currentRow]: value }));
    // setCarYear({ ...carYear, [currentRow]: "" });
    setTrimNames({ ...trimNames, [currentRow]: data.children });
    value === -1
      ? setCompareData((prev) => ({ ...prev, [currentRow]: {} }))
      : setCompareAvgData((prev) => ({ ...prev, [currentRow]: {} }));

    // setCompareData({
    //   ...compareData,
    //   [currentRow]: compareAvgData[currentRow],
    // });
  };
  const handleModelChange = (value, data) => {
    setModelSelected(data.key);
    // setCompareData({ ...compareData, [currentRow]: null });
    // setCompareData({ ...compareData, [currentRow]: null });
    setTrimNames((prev) => ({ ...prev, [currentRow]: "" }));

    setCarYear((prev) => ({ ...prev, [currentRow]: "" }));
    setModelName((prev) => ({ ...prev, [currentRow]: data.children }));
    setTrimSelected((prev) => ({ ...prev, [currentRow]: null }));
    setCompareData((prev) => ({ ...prev, [currentRow]: "" }));
  };
  const handleSegmentChange = () => {
    setSegment(!segment);
  };
  const handleBmTrimChange = (value, data) => {
    if (value === -1) {
      // setBmData(bmAverageData);
      setBmAverage(true);
    } else {
      setBmAverage(false);
    }
    setBmWaveSelected();
    setBmTrimSelected(value);
    setBmTrimName(data.children);
    setBmCarYear();
  };
  const handleBmModelChange = (value, data) => {
    setBmModelSelected(value);
    setBmModelName(data.children);
    setBmModelCodeSelected(data.key);
    setBmTrimName();
    setBmTrimSelected();
    setBmCarYear();
    setBmData();
    // setBmTrimsData();
    setBmAverageData();
  };
  const handleBmCarYearChange = (value) => {
    setBmWaveSelected();
    setBmCarYear(value);
  };
  const handleCarYearChange = (value, data) => {
    setCarYear({ ...carYear, [currentRow]: value });
  };
  const onCompareWaveChange = (value) => {
    setCompareWaveSelected((prev) => ({ ...prev, [currentRow]: value }));
  };
  const onBmWaveChange = (value) => {
    setBmWaveSelected(value);
  };
  const handleBrandChange = (value, data) => {
    setBrandSelected((prev) => ({ ...prev, [currentRow]: data.key }));
  };

  const handleFeaturesShow = () => {
    setShowFeatures(!showFeatures);
  };

  console.log("compareData", compareData);

  return (
    <>
      <div className="main-header">
        <div className="main-toggle">
          <p className="rtl">RTL</p>
          <div
            onClick={handleSegmentChange}
            className={`toggle ${segment ? "right" : "left"}`}
          >
            <div className="toggle__toggler"></div>
          </div>
          <p className="sme">SME</p>
        </div>
        <div className="vat">
          {VAT ? `*All Values Including ${VAT}% VAT` : ""}
        </div>
      </div>
      <div className="your-choice">
        <div className="your-choice__controls">
          <Select
            showSearch
            defaultValue="Country"
            style={{
              width: 120,
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toUpperCase()
                .localeCompare(optionB.children.toUpperCase())
            }
            onChange={handleCountryChange}
          >
            {countryData?.map((country, i) => (
              <Select.Option key={country.id} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select>

          <Button
            onClick={onAddRow}
            className="ant-btn-primary ant-btn-default"
            // onClick={addRowEventHandler}
          >
            Add new Row &nbsp;
            <BsIcons.BsPlusCircleFill />
          </Button>
          <Button onClick={onDeleteRow} className="btn-control-delete">
            Delete &nbsp;
            <BsIcons.BsTrash2Fill />
          </Button>
        </div>

        <ChartModal
          show={show}
          // cat={cat}
          // viData={viData}
          // vaData={vaData}
          // tpvaData={tpvaData}
          chartData={chartData}
          setShow={setShow}
        />
        {/* <YourChoicePptx data={chartData} />
        <TPVAPptx
          trimSelected={trimSelected}
          currentRow={currentRow}
          bmData={bmData}
          waveForTPVA={waveForTPVA}
          data={chartData}
          bmModelSelected={bmModelSelected}
          bmTrimSelected={bmTrimSelected}
          countrySelected={countrySelected}
          segment={segment}
          togglerPosition={togglerPosition}
        /> */}

        <div>
          <div className="data-options">
            <h5>Summary</h5>
            <div className="data-options__tools">
              {/* <span>
                <BsIcons.BsBarChart />
              </span> */}
              {/* <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`tooltip-top`}>Show Chart</Tooltip>}
              > */}
              <YourChoicePptx
                data={chartData}
                currencyFactor={currencyFactor}
              />
              <TPVAPptx
                trimSelected={trimSelected}
                currentRow={currentRow}
                rowData={rowData}
                bmMainData={bmData}
                waveForTPVA={waveForTPVA}
                data={chartData}
                bmModelSelected={bmModelSelected}
                bmTrimSelected={bmTrimSelected}
                countrySelected={countrySelected}
                segment={segment}
                togglerPosition={togglerPosition}
                bmAverage={bmAverage}
                bmAverageData={bmAverageData}
                bmWaveSelected={bmWaveSelected}
                currencyFactor={currencyFactor}
              />
              <span onClick={() => setShow(true)}>
                <BsIcons.BsGraphUp />
              </span>
              {/* </OverlayTrigger>
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`tooltip-top`}>Export To Excel</Tooltip>}
              > */}
              <span onClick={onDownload}>
                <BsIcons.BsFileEarmarkExcel />
              </span>
              {/* </OverlayTrigger> */}

              <span>|</span>
              <div className="main-toggle">
                <p className="rtl">TPVA</p>
                <div
                  onClick={() => setTpva((prev) => !prev)}
                  className={`toggle ${tpva ? "right" : "left"}`}
                >
                  <div className="toggle__toggler"></div>
                </div>
                <p className="sme">NTPVA</p>
              </div>
              <span>|</span>
              <div className="main-toggle">
                <p className="rtl">{currencyCode}</p>
                <div
                  onClick={() => setCurrency((prev) => !prev)}
                  className={`toggle ${currency ? "right" : "left"}`}
                >
                  <div className="toggle__toggler"></div>
                </div>
                <p className="USD">USD</p>
              </div>
            </div>
          </div>
          {/* <UserTable
              onAddRow={onAddRow}
              wave={waveSelected}
              country={countrySelected}
              setRows={setRows}
              rows={rows}
              togglerPosition={togglerPosition}
              tableRef={tableRef}
            /> */}
          <div className="user-table">
            <table ref={tableRef} id="l">
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Model Name</th>
                  <th>Trim Name</th>
                  <th>My</th>
                  <th>Wave</th>
                  <th style={{ minWidth: "200px" }}>Trim Name Spec</th>
                  <th>MSRP (A)</th>
                  <th>Cash Discount (B)</th>
                  <th>TP (C=A-B)</th>
                  <th>Total Options Value (D)</th>
                  <th>Value Adjusted NTP (E=C-D)</th>
                  <th>VI %</th>
                  <th>VA %</th>
                  <th>{tpva ? "TPVA" : "NTPVA"} %</th>
                </tr>
              </thead>
              <tbody>
                <tr onClick={() => setCurrentRow(0)}>
                  <td className="user-table__data">MITSUBISHI</td>
                  <td className="user-table__data">
                    <Select
                      showSearch
                      // value={
                      //   modelData && modelData?.name ? modelData?.name : "Model"
                      // }
                      value={bmModelName ? bmModelName : "Model"}
                      style={{
                        width: 160,
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toUpperCase()
                          .localeCompare(optionB.children.toUpperCase())
                      }
                      onChange={handleBmModelChange}
                    >
                      {modelData?.map((model, i) => (
                        <Option key={model.code} value={model.id}>
                          {model.name}
                        </Option>
                      ))}
                    </Select>
                  </td>

                  <td className="user-table__data">
                    <Select
                      showSearch
                      value={bmTrimName || "Trims"}
                      style={{
                        width: 220,
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toUpperCase()
                          .localeCompare(optionB.children.toUpperCase())
                      }
                      onChange={handleBmTrimChange}
                    >
                      <Option value={-1}>Average</Option>

                      {bmTrimsData?.map((trim, i) => (
                        <>
                          <Option key={i} value={trim.code}>
                            {trim.name}
                          </Option>
                        </>
                      ))}
                    </Select>
                  </td>
                  {/* <td className="user-table__data">
                    <Select
                      value={bmCarYear || "Year"}
                      style={{
                        width: 120,
                      }}
                      onChange={handleBmCarYearChange}
                    >
                      <Option value="2022">2022</Option>
                      <Option value="2023">2023</Option>
                    </Select>
                  </td> */}
                  {
                    <>
                      {" "}
                      <td className="user-table__data">
                        <Select
                          value={bmCarYear ? bmCarYear : "MY"}
                          style={{
                            width: 120,
                          }}
                          onChange={handleBmCarYearChange}
                        >
                          {bmCarYearData &&
                            bmCarYearData?.map((year, i) => (
                              <>
                                <Option
                                  key={year?.cdh_id}
                                  value={year?.cdh_car_year}
                                >
                                  {year?.cdh_car_year}
                                </Option>
                              </>
                            ))}
                        </Select>
                      </td>
                      <td className="user-table__data">
                        <Select
                          showSearch
                          defaultValue="Wave"
                          style={{
                            width: 120,
                          }}
                          value={bmWaveSelected ? bmWaveSelected : "Wave"}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toUpperCase()
                              .includes(input.toUpperCase())
                          }
                          onChange={onBmWaveChange}
                        >
                          {wavesData?.map((wave, i) => (
                            <Select.Option key={wave.id} value={wave.code}>
                              {wave.month + "-" + wave.year}
                            </Select.Option>
                          ))}
                        </Select>
                      </td>
                      {bmWaveSelected && (
                        <>
                          <td>{bmData?.trim_name_spec}</td>
                          <td>
                            {numberWithCommas(
                              bmAverage
                                ? currency
                                  ? bmAverageData?.cdh_msrp
                                  : bmAverageData?.cdh_msrp_dollar
                                : currency
                                ? bmData?.cdh_msrp
                                : bmData?.cdh_msrp_dollar
                            )}
                          </td>
                          <td>
                            {numberWithCommas(
                              bmAverage
                                ? currency
                                  ? bmAverageData?.cdh_cash_discount
                                  : bmAverageData?.cdh_cash_discount_dollar
                                : currency
                                ? bmData?.cdh_cash_discount
                                : bmData?.cdh_cash_discount_dollar
                            )}
                          </td>
                          <td>
                            {numberWithCommas(
                              bmAverage
                                ? currency
                                  ? bmAverageData?.cdh_msrp -
                                    bmAverageData?.cdh_cash_discount
                                  : bmAverageData?.bmData?.cdh_msrp_dollar -
                                    bmAverageData?.cdh_cash_discount_dollar
                                : currency
                                ? bmData?.cdh_msrp - bmData?.cdh_cash_discount
                                : bmData?.cdh_msrp_dollar -
                                  bmData?.cdh_cash_discount_dollar
                            )}
                          </td>
                          <td>
                            {numberWithCommas(
                              bmAverage
                                ? Math.round(
                                    currencyFactor * bmAverageData?.TOValue
                                  )
                                : Math.round(currencyFactor * bmData?.TOValue)
                            )}
                          </td>
                          <td>
                            {numberWithCommas(
                              bmAverage
                                ? currency
                                  ? bmAverageData?.cdh_msrp -
                                    Math.round(
                                      currencyFactor * bmAverageData?.TOValue
                                    )
                                  : bmAverageData?.cdh_msrp_dollar -
                                    Math.round(
                                      currencyFactor * bmAverageData?.TOValue
                                    )
                                : currency
                                ? bmData?.cdh_net_transaction_price -
                                  Math.round(currencyFactor * bmData?.TOValue)
                                : bmData?.cdh_net_transaction_price_dollar -
                                  Math.round(currencyFactor * bmData?.TOValue)
                            )}
                          </td>
                          <td>100%</td>
                          <td>100%</td>
                          <td>100 %</td>
                        </>
                      )}
                    </>
                  }
                </tr>
                {Array.apply(1, Array(rows)).map((data, index) => (
                  <tr key={index} onClick={() => setCurrentRow(index)}>
                    <td className="user-table__data">
                      {" "}
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toUpperCase()
                            .includes(input.toUpperCase())
                        }
                        placeholder="Brand"
                        style={{
                          width: 120,
                        }}
                        onChange={handleBrandChange}
                      >
                        {brandData?.map((brand, i) => (
                          <Option key={brand.code} value={brand.id}>
                            {brand.name}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td className="user-table__data">
                      <Select
                        showSearch
                        value={
                          modelName && modelName[index]
                            ? modelName[index]
                            : "Model"
                        }
                        style={{
                          width: 160,
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toUpperCase()
                            .includes(input.toUpperCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toUpperCase()
                            .localeCompare(optionB.children.toUpperCase())
                        }
                        onChange={handleModelChange}
                      >
                        {compareModels?.map((model, i) => (
                          <Option key={model.code} value={model.id}>
                            {model.name}
                          </Option>
                        ))}
                      </Select>
                    </td>

                    <td className="user-table__data">
                      <Select
                        showSearch
                        value={
                          trimNames && trimNames[index]
                            ? trimNames[index]
                            : "Trims"
                        }
                        style={{
                          width: 220,
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toUpperCase()
                            .includes(input.toUpperCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toUpperCase()
                            .localeCompare(optionB.children.toUpperCase())
                        }
                        onChange={handleTrimChange}
                      >
                        <Option value={-1}>Average</Option>
                        {trimsData &&
                          trimsData[index]?.length &&
                          trimsData[index]?.map((trim, i) => (
                            <>
                              <Option key={i} value={trim.code}>
                                {trim.name}
                              </Option>
                            </>
                          ))}
                      </Select>
                    </td>

                    <td className="user-table__data">
                      <Select
                        value={
                          carYear && carYear[index] ? carYear[index] : "MY"
                        }
                        style={{
                          width: 120,
                        }}
                        onChange={handleCarYearChange}
                      >
                        {carYearData &&
                          carYearData[index]?.map((year, i) => (
                            <>
                              <Option
                                key={year?.cdh_id}
                                value={year?.cdh_car_year}
                              >
                                {year?.cdh_car_year}
                              </Option>
                            </>
                          ))}
                      </Select>
                    </td>
                    <td className="user-table__data">
                      <Select
                        defaultValue="Wave"
                        style={{
                          width: 120,
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toUpperCase()
                            .includes(input.toUpperCase())
                        }
                        onChange={onCompareWaveChange}
                      >
                        {wavesData?.map((wave, i) => (
                          <Select.Option key={wave.id} value={wave.code}>
                            {wave.month + "-" + wave.year}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>

                    {compareData && rowData && (
                      <>
                        <td>
                          {trimSelected[index] !== -1 &&
                            compareData[index]?.trim_name_spec}
                        </td>
                        <td>
                          {numberWithCommas(
                            rowData[index]?.average && compareAvgData
                              ? currency
                                ? compareAvgData[index]?.cdh_msrp
                                : compareAvgData[index]?.cdh_msrp_dollar
                              : currency
                              ? compareData[index]?.cdh_msrp
                              : compareData[index]?.cdh_msrp_dollar
                          )}
                        </td>
                        <td>
                          {numberWithCommas(
                            rowData[index]?.average &&
                              compareAvgData &&
                              compareAvgData
                              ? currency
                                ? compareAvgData[index]?.cashDiscount
                                : compareAvgData[index]?.cashDiscount_dollar
                              : currency
                              ? compareData[index]?.cdh_cash_discount
                              : compareData[index]?.cdh_cash_discount_dollar
                          )}
                        </td>
                        <td>
                          {numberWithCommas(
                            rowData[index]?.average && compareAvgData
                              ? currency
                                ? compareAvgData[index]?.cdh_msrp -
                                  compareAvgData[index]?.cashDiscount
                                : compareAvgData[index]?.cdh_msrp_dollar -
                                  compareAvgData[index]?.cashDiscount_dollar
                              : currency
                              ? compareData[index]?.cdh_msrp -
                                compareData[index]?.cdh_cash_discount
                              : compareData[index]?.cdh_msrp_dollar -
                                compareData[index]?.cdh_cash_discount_dollar
                          )}
                        </td>
                        <td>
                          {numberWithCommas(
                            rowData[index]?.average && compareAvgData
                              ? Math.round(
                                  currencyFactor *
                                    compareAvgData[index]?.TOValue
                                )
                              : Math.round(
                                  currencyFactor * compareData[index]?.TOValue
                                )
                          )}
                        </td>
                        <td>
                          {numberWithCommas(
                            rowData[index]?.average && compareAvgData
                              ? currency
                                ? compareAvgData[index]?.cdh_msrp -
                                  Math.round(
                                    currencyFactor *
                                      compareAvgData[index]?.TOValue
                                  )
                                : compareAvgData[index]?.cdh_msrp_dollar -
                                  Math.round(
                                    currencyFactor *
                                      compareAvgData[index]?.TOValue
                                  )
                              : currency
                              ? compareData[index]?.cdh_net_transaction_price -
                                Math.round(
                                  currencyFactor * compareData[index]?.TOValue
                                )
                              : compareData[index]
                                  ?.cdh_net_transaction_price_dollar -
                                Math.round(
                                  currencyFactor * compareData[index]?.TOValue
                                )
                          )}
                        </td>
                        <td>
                          {currency
                            ? trimSelected[index] === -1 &&
                              compareAvgData &&
                              compareAvgData
                              ? Math.round(
                                  (compareAvgData[index]?.cdh_msrp /
                                    bmData?.cdh_msrp) *
                                    100
                                )
                              : Math.round(
                                  (compareData[index]?.cdh_msrp /
                                    bmData?.cdh_msrp) *
                                    100
                                )
                            : trimSelected[index] === -1 && compareAvgData
                            ? Math.round(
                                (compareAvgData[index]?.cdh_msrp_dollar /
                                  bmData?.cdh_msrp_dollar) *
                                  100
                              )
                            : Math.round(
                                (compareData[index]?.cdh_msrp /
                                  bmData?.cdh_msrp) *
                                  100
                              )}
                          %
                        </td>
                        <td>
                          {trimSelected[index] === -1 && compareAvgData
                            ? currency
                              ? Math.round(
                                  ((compareAvgData[index]?.cdh_msrp +
                                    (bmData?.TOValue * currencyFactor -
                                      compareAvgData[index]?.TOValue *
                                        currencyFactor)) /
                                    bmData?.cdh_msrp) *
                                    100
                                )
                              : Math.round(
                                  ((compareAvgData[index]?.cdh_msrp_dollar +
                                    (bmData?.TOValue * currencyFactor -
                                      compareAvgData[index]?.TOValue *
                                        currencyFactor)) /
                                    bmData?.cdh_msrp_dollar) *
                                    100
                                )
                            : currency
                            ? Math.round(
                                ((compareData[index]?.cdh_msrp +
                                  (bmData?.TOValue * currencyFactor -
                                    compareData[index]?.TOValue *
                                      currencyFactor)) /
                                  bmData?.cdh_msrp) *
                                  100
                              )
                            : Math.round(
                                ((compareData[index]?.cdh_msrp_dollar +
                                  (bmData?.TOValue * currencyFactor -
                                    compareData[index]?.TOValue *
                                      currencyFactor)) /
                                  bmData?.cdh_msrp_dollar) *
                                  100
                              )}
                          %
                        </td>
                        <td>
                          {
                            // console.log("cashDiscount", bmData?.cdh_value_other_offer)
                          }
                          {currency
                            ? trimSelected[index] === -1 && compareAvgData
                              ? tpva
                                ? Math.round(
                                    ((compareAvgData[index]?.cdh_msrp +
                                      (bmData?.TOValue -
                                        compareAvgData[index]?.TOValue) -
                                      compareAvgData[index]
                                        ?.cdh_total_offer_value) /
                                      (bmData?.cdh_msrp -
                                        compareAvgData[index]
                                          ?.cdh_total_offer_value)) *
                                      100
                                  )
                                : Math.round(
                                    ((compareAvgData[index]?.cdh_msrp +
                                      (bmData?.TOValue -
                                        compareAvgData[index]?.TOValue) -
                                      compareAvgData[index]?.cashDiscount -
                                      compareAvgData[index]
                                        ?.cdh_total_offer_value) /
                                      (bmData?.cdh_msrp -
                                        bmData?.cdh_cash_discount -
                                        bmData?.cdh_total_offer_value)) *
                                      100
                                  )
                              : tpva
                              ? Math.round(
                                  ((compareData[index]?.cdh_msrp +
                                    (bmData?.TOValue * currencyFactor -
                                      compareData[index]?.TOValue *
                                        currencyFactor) -
                                    compareData[index]?.cdh_total_offer_value) /
                                    (bmData?.cdh_msrp -
                                      bmData?.cdh_total_offer_value)) *
                                    100
                                )
                              : Math.round(
                                  ((compareData[index]?.cdh_msrp +
                                    (bmData?.TOValue * currencyFactor -
                                      compareData[index]?.TOValue *
                                        currencyFactor) -
                                    compareData[index]?.cdh_cash_discount -
                                    compareData[index]?.cdh_total_offer_value) /
                                    (bmData?.cdh_msrp -
                                      bmData?.cdh_cash_discount -
                                      bmData?.cdh_total_offer_value)) *
                                    100
                                )
                            : trimSelected[index] === -1 && compareAvgData
                            ? tpva
                              ? Math.round(
                                  ((compareAvgData[index]?.cdh_msrp_dollar +
                                    (bmData?.TOValue -
                                      compareAvgData[index]?.TOValue) -
                                    compareAvgData[index]
                                      ?.cdh_total_offer_value_dollar) /
                                    (bmData?.cdh_msrp_dollar -
                                      compareAvgData[index]
                                        ?.cdh_total_offer_value_dollar)) *
                                    100
                                )
                              : Math.round(
                                  ((compareAvgData[index]?.cdh_msrp_dollar +
                                    (bmData?.TOValue -
                                      compareAvgData[index]?.TOValue) -
                                    compareAvgData[index]?.cashDiscount_dollar -
                                    compareAvgData[index]
                                      ?.cdh_total_offer_value) /
                                    (bmData?.cdh_msrp_dollar -
                                      bmData?.cdh_cash_discount_dollar -
                                      bmData?.cdh_total_offer_value)) *
                                    100
                                )
                            : tpva
                            ? Math.round(
                                ((compareData[index]?.cdh_msrp_dollar +
                                  (bmData?.TOValue * currencyFactor -
                                    compareData[index]?.TOValue *
                                      currencyFactor) -
                                  compareData[index]?.cdh_total_offer_value) /
                                  (bmData?.cdh_msrp_dollar -
                                    compareData[index]
                                      ?.cdh_total_offer_value)) *
                                  100
                              )
                            : Math.round(
                                ((compareData[index]?.cdh_msrp_dollar +
                                  (bmData?.TOValue * currencyFactor -
                                    compareData[index]?.TOValue *
                                      currencyFactor) -
                                  compareData[index]?.cdh_cash_discount_dollar -
                                  compareData[index]?.cdh_total_offer_value) /
                                  (bmData?.cdh_msrp_dollar -
                                    bmData?.cdh_cash_discount_dollar -
                                    bmData?.cdh_total_offer_value)) *
                                  100
                              )}
                          %
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="details-controls"></div>
        </div>

        <div className="single-row">
          <div className="single-row__heading">Model Name</div>
          <div className="single-row__item">{bmModelName}</div>

          {Array.apply(1, Array(rows))?.map((item, index) => (
            <div className="single-row__item">
              {trimSelected[index] === -1 && compareAvgData
                ? compareAvgData[index]?.models?.name
                : compareData && compareData[index]?.models?.name}
            </div>
          ))}
        </div>
        <div className="single-row">
          <div className="single-row__heading">Trim Name</div>
          <div className="single-row__item">{bmTrimName}</div>
          {Array.apply(1, Array(rows))?.map((item, index) => (
            <div className="single-row__item">
              {trimSelected[index] === -1 && compareAvgData
                ? compareAvgData[index]?.trims?.name
                : compareData && compareData[index]?.trims?.name}
            </div>
          ))}
        </div>
        <div className="single-row">
          <div className="single-row__heading single-row__item">
            Total Options Value
          </div>
          <div className="single-row__item">
            {Math.round(bmData?.TOValue * currencyFactor)}
          </div>
          {Array.apply(1, Array(rows))?.map((item, index) => (
            <div className="single-row__item">
              {compareData &&
                Math.round(
                  trimSelected[index] === -1 && compareAvgData
                    ? compareAvgData[index]?.TOValue * currencyFactor
                    : compareData[index]?.TOValue * currencyFactor
                )}
            </div>
          ))}
        </div>

        {/* {showFeatures &&
          bmData &&
          features?.map((row, i) => (
            <div key={i} className="main-row">
              <div className="main-row__heading main-row__item">
                {row?.name}
              </div>
              <div className="main-row__item">option
                {bmFeatures[row?.code] == 1 || bmFeatures[row?.code] == 0 ? (
                  <Checkbox
                    checked={Number(bmFeatures[row?.code]) ? true : false}
                  ></Checkbox>
                ) : (
                  <div className="">{bmFeatures[row?.code]} </div>
                )}
              </div>
              {compareFeatures &&
                compareFeatures?.map((item) => (
                  <div className="main-row__item">
                    <Checkbox
                      checked={item[row?.code] ? true : false}
                    ></Checkbox>{" "}
                  </div>
                ))}
            </div>
          ))} */}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          className=""
        >
          <ExcelExportHelper
            bmData={bmData}
            currencyFactor={currencyFactor}
            currencyCode={currencyCode}
            compareData={compareData}
            countryName={countryName}
            modelName={bmModelName}
            wave={waveSelected}
            currency={currency}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={handleFeaturesShow} className="btn-details">
            {showFeatures ? "Collapse" : "Expand"}
          </button>
        </div>
        <FeaturesTable
          bmData={bmData}
          currencyFactor={currencyFactor}
          currencyCode={currencyCode}
          compareData={compareData}
          countryName={countryName}
          modelName={bmModelName}
          wave={waveSelected}
          showFeatures={showFeatures}
        />
      </div>
    </>
  );
};

export default YourChoice;
