import React, { useEffect } from "react";
import { Select } from "antd";
import { useState } from "react";
import ToggleBtn from "./toggle-button";
import PriceLadderChart from "./charts/price-ladder-chart";
import { axiosPrivate } from "../../api/axios";
import { waveMonths } from "../../data/wave-month-data";
import PriceLadderPptx from "./pptx/priceLadder-pptx";

const PriceLadder = () => {
  const [togglerPosition, setTogglerPosition] = useState(true);
  const [countryData, setCountryData] = useState();
  const [countrySelected, setCountrySelected] = useState();
  const [modelData, setModelData] = useState();
  const [tableData, setTableData] = useState();
  const [modelSelected, setModelSelected] = useState([]);
  const [waveSelected, setWaveSelected] = useState();
  const [modelName, setModelName] = useState();
  const [waves, setWaves] = useState();
  const [segment, setSegment] = useState(true);
  const [bmTrimsData, setBmTrimsData] = useState();
  const [currencyCode, setCurrencyCode] = useState("");
  const [VAT, setVAT] = useState();
  const [compareModelSelected, setCompareModelSelected] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [allModels, setAllModels] = useState([]);
  const [competCodes, setCompetCodes] = useState();
  const [allModelsSelected, setAllModelsSelected] = useState([]);

  useEffect(() => {
    if (Number(countrySelected) === 1) {
      setCurrencyCode("AED");
      setVAT(5);
    } else if (Number(countrySelected) === 2) {
      setCurrencyCode("SAR");
      setVAT(15);
    } else if (Number(countrySelected) === 3) {
      setCurrencyCode("EGP");
      setVAT(14);
    }
  }, [countrySelected]);

  useEffect(() => {
    countrySelected &&
      axiosPrivate
        .post("competModels/benchmarkModels", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          brandCode: "20",
        })
        .then((res) => {
          setModelData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [countrySelected]);
  useEffect(() => {
    countrySelected &&
      axiosPrivate
        .get(
          `model/byCountry?country=${countrySelected}&segment=${
            segment ? 1 : 2
          }`
        )
        .then((res) => {
          const filtered = res?.data?.data?.filter(
            (item) => item?.brands?.code !== "20"
          );
          setAllModels(filtered);
        })
        .catch((e) => Promise.reject(e));
  }, [countrySelected]);
  useEffect(() => {
    ////// for benchmark data
    modelSelected &&
      waveSelected &&
      axiosPrivate
        .post(`summary/trims`, {
          countryId: Number(countrySelected),
          month: Number(String(waveSelected).slice(-2)),
          year: Number("20" + String(waveSelected).slice(0, 2)),
          wave: waveSelected,
          modelCode: modelSelected,
          segment: segment ? 1 : 2,
        })
        .then((res) => {
          setBmTrimsData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [modelSelected, segment, waveSelected, countrySelected]);
  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        let countries = [];
        res.data.data.map((item) => {
          countries.push({
            key: item?.id,
            country: item?.name,
            countrycode: item.currency_code,
          });
        });
        setCountryData(countries);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("wave")
      .then((res) => {
        let waves = [];
        res.data.data.map((item) => {
          waves.push({
            month: waveMonths[Number(String(item.code).slice(-2)) - 1],
            year: String(item.code).slice(0, 2),
            code: String(item.code),
            id: item.id,
          });
        });

        setWaves(waves);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  useEffect(() => {
    let data = {
      countryId: countrySelected,
      modelCode: modelSelected?.length ? modelSelected : [],
      competModels:
        compareModelSelected?.length || allModelsSelected?.length
          ? [...compareModelSelected, ...allModelsSelected]
          : [],
      wave: String(waveSelected),
      segment: segment ? 1 : 2,
    };

    modelSelected &&
      (compareModelSelected || allModelsSelected) &&
      waveSelected &&
      axiosPrivate
        .post("summary/priceLadder", data)
        .then((res) => {
          setTableData(res.data?.data);
        })
        .catch((e) => Promise.reject(e));
  }, [
    countrySelected,
    modelSelected,
    waveSelected,
    segment,
    compareModelSelected,
    allModelsSelected,
  ]);

  useEffect(() => {
    //// for compare data
    setCompareData([]);
    modelSelected &&
      countrySelected &&
      axiosPrivate
        .post("priceTrend/competModels", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          modelCode: modelSelected,
        })
        .then((res) => {
          let modelRes = res.data.data;
          setCompetCodes(modelRes);
          console.log("modelRess", modelRes);
          // setCompetTrims(modelRes);
          modelRes.map((item) => {
            axiosPrivate
              .get(`priceTrend/${item.length === 4 ? 0 + item : item}`)
              .then((response) => {
                res.data.data !== null &&
                  setCompareData((prev) => [...prev, response.data.data]);
              });
          });
        })
        .catch((e) => Promise.reject(e));

    ///////////////////all compet trims////////////
    // modelSelected &&
    //   countrySelected &&
    //   axiosPrivate
    //     .post("priceTrend/competTrims", {
    //       countryId: Number(countrySelected),
    //       segment: segment ? 1 : 2,
    //       modelCode: modelSelected,
    //     })
    //     .then((res) => {
    //       // setCompetTrims(modelRes);
    //       setCompetTrims(res.data.data);
    //     })
    //     .catch((e) => Promise.reject(e));
  }, [modelSelected, segment, countrySelected]);

  const handleCountryChange = (value) => {
    setCountrySelected(value);
  };

  const handleModalChange = (value, data) => {
    if (value.includes("0")) {
      setModelSelected([]);
      modelData?.map((item, i) =>
        setModelSelected((prev) => [...prev, item.code])
      );
    } else {
      setModelName(data.children);
      setModelSelected(value);
    }
  };
  const handleDateChange = (value) => {
    // setCompareData([]);
    setWaveSelected(value);
  };
  console.log("compareModelSelected", compareModelSelected);
  const handleCompareModelChange = (value, data) => {
    // setCompareModelSelected([]);
    let modelCodes = [];
    if (value.includes("0")) {
      compareData?.map((model) => {
        modelCodes.push(model.code);
      });
      setCompareModelSelected(modelCodes);
    } else {
      setCompareModelSelected(value);
    }
  };
  const handleAllModelsChange = (value, data) => {
    // setCompareModelSelected([]);
    let modelCodes = [];
    if (value.includes("-1")) {
      console.log("erfjwhedruifhwdj");
      allModels?.map((model) => {
        modelCodes.push(model.code);
      });
      setAllModelsSelected(modelCodes);
    } else {
      setAllModelsSelected(value);
    }
  };
  return (
    <>
      <div className="price-ladder">
        <div className="main-header">
          <div className="main-toggle">
            <p className="rtl">RTL</p>
            <div
              onClick={() => setSegment((prev) => !prev)}
              className={`toggle ${segment ? "right" : "left"}`}
            >
              <div className="toggle__toggler"></div>
            </div>
            <p className="sme">SME</p>
          </div>
          <div className="vat">
            {VAT ? `*All Values Including ${VAT}% VAT` : ""}
          </div>
        </div>
        {/* <PriceLadderPptx data={tableData} /> */}
        <div className="price-ladder__controls">
          <Select
            defaultValue="Country"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toUpperCase()
                .localeCompare(optionB.children.toUpperCase())
            }
            onChange={handleCountryChange}
          >
            {countryData !== undefined
              ? countryData.map((item) => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.country}
                  </Select.Option>
                ))
              : console.log("No Data")}
          </Select>
          <Select
            mode="multiple"
            maxTagCount="responsive"
            placeholder="Model"
            style={{
              width: 320,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toUpperCase()
                .localeCompare(optionB.children.toUpperCase())
            }
            onChange={handleModalChange}
          >
            <Select.Option style={{ color: "green" }} value="0">
              ALL
            </Select.Option>
            {modelData !== undefined
              ? modelData.map((item) => (
                  <Select.Option value={item.code} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))
              : console.log("No Data")}
          </Select>
          <Select
            mode="multiple"
            maxTagCount="responsive"
            placeholder="Compare Model"
            style={{
              width: 160,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            id="modal-data"
            onChange={handleCompareModelChange}
          >
            <Select.Option style={{ color: "green" }} value="0">
              ALL
            </Select.Option>

            {compareData &&
              compareData?.map(
                (data) =>
                  data !== null && (
                    <>
                      <Select.Option value={data?.code} key={data?.id}>
                        {data.name}
                      </Select.Option>
                    </>
                  )
              )}
          </Select>
          <Select
            // disabled={compareModelSelected.length ? true : false}
            mode="multiple"
            maxTagCount="responsive"
            placeholder="All Models"
            style={{
              width: 160,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            id="modal-data"
            onChange={handleAllModelsChange}
          >
            <Select.Option style={{ color: "green" }} value="-1">
              ALL
            </Select.Option>

            {allModels &&
              allModels?.map(
                (data) =>
                  data !== null &&
                  !competCodes.includes(data?.code) && (
                    <>
                      <Select.Option value={data?.code} key={data?.id}>
                        {data.name}
                      </Select.Option>
                    </>
                  )
              )}
          </Select>
          <Select
            defaultValue="Wave"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={handleDateChange}
          >
            {waves?.map((wave) => (
              <Select.Option key={wave.id} value={wave.code}>
                {wave.month + " - " + wave.year}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="fixed-segments__chart">
          <h5>Price Ladder</h5>
          <div
            style={{ display: "flex", justifyContent: "right" }}
            className=""
          >
            {" "}
            <PriceLadderPptx data={tableData} />
          </div>

          {tableData && (
            <PriceLadderChart
              waveSelected={waveSelected}
              currencyCode={currencyCode}
              selectModel={modelSelected}
              data={tableData}
            />
          )}
        </div>
        {/* <PriceLadderPptx data={tableData} /> */}
      </div>
    </>
  );
};

export default PriceLadder;
