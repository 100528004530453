import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import * as BsIcons from "react-icons/bs";

import { axiosAdmin } from "../../api/axios";
import CommonTable from "./table";
import { useEffect } from "react";
import { Popconfirm, Tag } from "antd";
import AddUser from "./forms/add-edit-user-form";
import UpdateUserPassword from "./forms/update-password-form";

const CarType = () => {
  const [formType, setFormType] = useState("Add");
  const [tableFilteredValue, setTableFilteredValue] = useState({});
  const [rowCountItemsShow, setRowCountItemsShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [usersData, setUsersData] = useState();
  const [filteredUsersData, setFilteredUsersData] = useState();
  const [editUserData, setEditUserData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (searchQuery !== "") {
      let filteredData = [];
      usersData?.filter((item) => {
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(searchQuery) &&
            filteredData.push(item)
        ) ||
          Object.values(item).some(
            (val) =>
              typeof val === "string" &&
              val.includes(searchQuery) &&
              filteredData.push(item)
          );
      });
      setFilteredUsersData(filteredData);
    }
  }, [searchQuery, usersData]);

  useEffect(() => {
    axiosAdmin
      .get("user/getAllUsers")
      .then((res) => {
        let data = [];
        res?.data?.data.map((user, i) => {
          data.push({
            key: user?.id,
            serialNum: String(i + 1),
            name: user?.name,
            email: user?.email,
            id: String(user?.id),
            status: user?.status ? 1 : 0,
            type: Number(user?.role) === 2 ? "User" : "Admin",
          });
        });
        setUsersData(data);
      })
      .catch((e) => {
        Promise.reject(e);
      });
  }, []);

  const addModelClickHandler = () => {
    setShow(true);
    setFormType("Add");
  };
  const handleEdit = (key) => {
    setFormType("Edit");
    setShow(true);
    axiosAdmin
      .get(`user/getUserById/${Number(key)}`)
      .then((res) => {
        setEditUserData(res?.data?.data);
      })
      .catch((e) => Promise.reject(e));
  };
  const handlePasswordUpdate = (key) => {
    setFormType("EditPass");
    setShow(true);
    setEditUserData({ id: key });
  };
  const handleChangeStatus = (key) => {
    axiosAdmin
      .delete(`user/deleteUser/${Number(key)}`)
      .then((res) => {
        if (res?.data?.success) {
          axiosAdmin.get("user/getAllUsers").then((res) => {
            let data = [];
            res?.data?.data.map((user, i) => {
              data.push({
                key: user?.id,
                serialNum: String(i + 1),
                name: user?.name,
                email: user?.email,
                id: String(user?.id),
                status: user?.status ? 1 : 0,
                type: Number(user?.role) === 2 ? "user" : "admin",
              });
            });
            setUsersData(data);
          });
        }
      })
      .catch((e) => {
        Promise.reject(e);
      });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "serialNum",
      align: "center",
      width: 8,
    },
    {
      title: "",
      dataIndex: "edit",
      render: (_, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleEdit(record.key)}
        >
          <BsIcons.BsPencil />
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "editPassword",
      render: (_, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handlePasswordUpdate(record.key)}
        >
          <BsIcons.BsLockFill />
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <>
          <Popconfirm
            title={`Are you sure, change status to ${
              record?.status ? "inactive" : "active"
            }.?`}
            okText="Yes"
            onConfirm={() => handleChangeStatus(record?.key)}
          >
            <Tag
              style={{ cursor: "pointer" }}
              color={record?.status ? "green" : "red"}
            >
              {record?.status ? "Active" : "Inactive"}
            </Tag>
          </Popconfirm>
        </>
      ),
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Inactive",
          value: "Inactive",
        },
      ],
      filteredValue: tableFilteredValue.status || null,
      onFilter: (value, record) =>
        record?.status ? value === "Active" : value === "Inactive",
    },
    {
      title: "User Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "User Id",
      dataIndex: "id",
      sorter: {
        compare: (a, b) => a.id - b.id,
      },
    },
    {
      title: "User Email",
      dataIndex: "email",
    },
    {
      title: "User Type",
      dataIndex: "type",
      filters: [
        {
          text: "User",
          value: "user",
        },
        {
          text: "User+",
          value: "user+",
        },
      ],
      filteredValue: tableFilteredValue.type || null,
      onFilter: (value, record) => record?.type === value,
    },
  ];

  return (
    <>
      <div className="vendors">
        <div className="vendors__header">
          <p>Users</p>

          <Button onClick={addModelClickHandler} className="me-2">
            Add User
          </Button>
          {formType === "EditPass" ? (
            <UpdateUserPassword
              editUserData={editUserData}
              formType={formType}
              show={show}
              setShow={setShow}
            />
          ) : (
            <AddUser
              setUsersData={setUsersData}
              editUserData={editUserData}
              formType={formType}
              show={show}
              setShow={setShow}
            />
          )}
        </div>
        <div className="vendors__body">
          <div className="vendors__table-controls">
            <div className="row-count">
              <span>Show</span>
              <div
                onClick={() => setRowCountItemsShow((prev) => !prev)}
                className="row-count__dropdown"
              >
                <span>{pageSize}</span>
                <BsIcons.BsChevronDown />
                <div
                  className={`row-count__items ${
                    rowCountItemsShow ? "visible" : ""
                  }`}
                >
                  <li
                    onClick={() => setPageSize(10)}
                    className="row-count__item"
                  >
                    10
                  </li>
                  <li
                    onClick={() => setPageSize(20)}
                    className="row-count__item"
                  >
                    20
                  </li>
                  <li
                    onClick={() => setPageSize(30)}
                    className="row-count__item"
                  >
                    30
                  </li>
                </div>
              </div>
              <span>Entries</span>
            </div>
            <form
              onSubmit={(e) => e.preventDefault()}
              action=""
              className="search"
            >
              <p>Search:</p>
              <input onChange={onSearchChange} type="text" />
            </form>
          </div>
          <div className="vendors__table">
            {/* table */}
            <CommonTable
              tableFilteredValue={tableFilteredValue}
              setTableFilteredValue={setTableFilteredValue}
              data={searchQuery ? filteredUsersData : usersData}
              columns={columns}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CarType;
