import React from "react";
import pptxgen from "pptxgenjs";

const Exports = () => {
  let pres = new pptxgen();

  let slide = pres.addSlide();

  let dataChartAreaLine = [
    {
      name: "Actual Sales",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values: [
        1500, 4600, 5156, 3167, 8510, 8009, 6006, 7855, 12102, 12789, 10123,
        15121,
      ],
    },
    {
      name: "Projected Sales",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      values: [
        1000, 2600, 3456, 4567, 5010, 6009, 7006, 8855, 9102, 10789, 11123,
        12121,
      ],
    },
  ];
  slide.addChart(pres.ChartType.line, dataChartAreaLine, {
    x: 0,
    y: 0.2,
    w: 4,
    h: 2,
  });
  slide.addChart(pres.ChartType.line, dataChartAreaLine, {
    x: 4.5,
    y: 0.2,
    w: 4,
    h: 2,
  });
  slide.addChart(pres.ChartType.line, dataChartAreaLine, {
    x: 0,
    y: 2.2,
    w: 4,
    h: 2,
  });
  slide.addChart(pres.ChartType.line, dataChartAreaLine, {
    x: 4.5,
    y: 2.2,
    w: 4,
    h: 2,
  });
  pres.writeFile({ fileName: "a.pptx" });
  return (
    <>
      <div>ly</div>
    </>
  );
};

export default Exports;
