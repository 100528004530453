import React, { useState } from "react";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { axiosAdmin } from "../../../api/axios";
import { useEffect } from "react";

const AddTrims = ({
  show,
  setShow,
  brands,
  formType,
  editTrimData,
  setTrims,
  models,
}) => {
  const [brandCode, setBrandCode] = useState();
  const [trimData, setTrimData] = useState(null);

  useEffect(() => {
    formType === "Edit" &&
      setTrimData({
        ...trimData,
        name: editTrimData?.name,
        code: editTrimData?.code,
        modelName: editTrimData?.models?.name,
        model: editTrimData?.models?.id,
        id: editTrimData?.id,
      });
  }, [editTrimData]);

  //   useEffect(() => {
  //     axiosAdmin
  //       .get(`model/getModelByBrand/${Number(brandCode)}`)
  //       .then((res) => {
  //         setModels(res.data.data);
  //       })
  //       .catch((e) => Promise.reject(e));
  //   }, []);

  //   useEffect(() => {
  //     brands &&
  //       brands?.map((brand) => {
  //         brand.id === Number(trimData?.brand) && setBrandCode(brand.code);
  //       });
  //   }, [trimData?.brand]);

  useEffect(() => {
    setTrimData({
      ...trimData,
      name: "",
      code: "",
      model: "",
      brand: "",
    });
  }, [formType]);
  const handleBrandChange = (e) => {
    setBrandCode(e.target.value);
  };
  const handleTrimChange = (e) => {
    const { name, value } = e.target;
    setTrimData({ ...trimData, [name]: value });
  };
  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setTrimData({ ...trimData, model: value });
  };

  const handleSubmitModel = (e) => {
    e.preventDefault();
    if (formType === "Add" && !isNaN(Number(trimData?.code))) {
      axiosAdmin
        .post("trim/addTrim", {
          trimData: {
            name: trimData?.name,
            code: trimData?.code,
            model_id: Number(trimData?.model),
          },
          brandCode,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Trim added", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("trim/getAllTrims")
              .then((res) => {
                let data = [];
                res.data.map((trim, i) => {
                  data.push({
                    key: trim?.id,
                    serialNum: String(i + 1),
                    trimName: trim?.name,
                    id: String(trim?.id),
                    status: trim?.status ? 1 : 0,
                    modelName: trim?.models?.name,
                  });
                });
                setTrims(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setTrimData({
              ...trimData,
              name: "",
              code: "",
              model: "",
              brand: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Model name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Model code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else if (formType === "Edit" && !isNaN(Number(trimData?.code))) {
      axiosAdmin
        .patch("trim/updateTrim", {
          trimData: {
            name: trimData?.name,
            code: trimData?.code,
            model_id: Number(trimData?.model),
            id: trimData?.id,
          },
          brandCode,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Trim updated", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("trim/getAllTrims")
              .then((res) => {
                let data = [];
                res.data.map((trim, i) => {
                  data.push({
                    key: trim?.id,
                    serialNum: String(i + 1),
                    trimName: trim?.name,
                    id: String(trim?.id),
                    status: trim?.status ? 1 : 0,
                    modelName: trim?.models?.name,
                  });
                });
                setTrims(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setTrimData({
              ...trimData,
              name: "",
              code: "",
              type: "",
              brand: "",
              id: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Model name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Model code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else {
      toast.error("Model code should be a number", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`${formType} Model`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitModel} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`Name`}</Form.Label>
              <Form.Control
                required
                onChange={handleTrimChange}
                type="text"
                placeholder={`Enter Trim Name`}
                value={trimData?.name}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Code`}</Form.Label>
              <Form.Control
                onChange={handleTrimChange}
                minLength={8}
                maxLength={8}
                required
                type="text"
                placeholder={`Enter Trim Code`}
                value={trimData?.code}
                name="code"
              />
            </Form.Group>
            {/* 
            <Form.Group className="mb-4">
              <Form.Label>{`brand`}</Form.Label>
              <Form.Select
                required
                onChange={handleBrandChange}
                aria-label="Default select example"
              >
                <option value="">Select Brand</option>
                {brands &&
                  brands?.map((brand) => {
                    return (
                      brand.status && (
                        <option
                          selected={brand.id === editTrimData?.brands?.id}
                          name="brand"
                          key={brand.id}
                          value={brand.id}
                        >
                          {brand.name}
                        </option>
                      )
                    );
                  })}
              </Form.Select>
            </Form.Group> */}
            <Form.Group className="mb-4">
              <Form.Label>{`Model`}</Form.Label>
              <Form.Select
                required
                onChange={handleModelChange}
                aria-label="Default select example"
              >
                <option value="">Select Model</option>
                {models &&
                  models.map((model) => {
                    console.log(model.id == editTrimData?.models?.id);
                    return (
                      model.status && (
                        <option
                          selected={model.id == editTrimData?.models?.id}
                          name="model"
                          key={model.id}
                          value={model.id}
                        >
                          {model.name}
                        </option>
                      )
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Button className=" w-100 mx-auto" type="submit">
              {`${formType === "Edit" ? "Update" : "Add"} Trim`}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTrims;
