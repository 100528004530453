import React, { useEffect, useState } from "react";
import { axiosPrivate } from "../../api/axios";
import { Select } from "antd";

const PPTReport = () => {
  const [countryData, setCountryData] = useState();
  const [countrySelected, setCountrySelected] = useState("");

  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  const handleCountryChange = (value) => {
    setCountrySelected(value);
  };
  return (
    <>
      <div className="reports">
        <div className="reports_header">
          <h3>PPT Reports</h3>
        </div>
        <Select
          defaultValue="Country"
          style={{
            width: 120,
          }}
          onChange={handleCountryChange}
        >
          {countryData &&
            countryData?.map((item) => (
              <Select.Option value={item.code} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
        <div className="reports_dropdowns"></div>
        <div className="reports_tp">
          <div className="reports_tp__header">
            <h4>Transaction Price</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default PPTReport;
