import React, { useEffect, useState } from "react";
import * as BsIcons from "react-icons/bs";
import { BiMenu } from "react-icons/bi";
import { axiosPrivate, logout } from "../api/axios";
import UpdateOwnPassword from "./admin/forms/update-own-password-form";
import { Modal } from "antd";
import flag from "../assets/images/flag-ae.svg";
import { useLocation } from "react-router-dom";

const Navbar = ({ setSidebarOpen, type }) => {
  const [showAdminOptions, setShowAdminOptions] = useState(false);
  const [show, setShow] = useState(false);
  const [userMail, setUserMail] = useState();

  const onChangePassword = () => {
    setShowAdminOptions(!showAdminOptions);
    setShow(!show);
  };
  const location = useLocation();

  const isAdmin = location?.pathname.includes("admin");
  useEffect(() => {
    if (!isAdmin) {
      axiosPrivate.get("auth/getUser").then((res) => {
        setUserMail(res?.data?.data?.email);
      });
    }
  }, []);

  return (
    <nav className={`navbar navbar__${type}`}>
      <div
        onClick={() => setSidebarOpen((prev) => !prev)}
        className="sidebar__toggle"
      >
        <BiMenu />
      </div>
      {type === "user" ? (
        <>
          {/* <form className="navbar__search">
            <input type="text" placeholder="Search" />
            <BsIcons.BsSearch />
          </form> */}
          <div className="navbar__user-section">
            <BsIcons.BsPersonCircle />
            <p>{userMail}</p>
          </div>
          {/* <div className="navbar__flag">
            <img style={{ width: "1.1rem" }} alt="United States" src={flag} />
            <p>UAE</p>
          </div> */}
          {/* <div className="navbar__filter">
            <BsIcons.BsListUl />
          </div> */}
        </>
      ) : (
        <>
          <div
            onClick={() => setShowAdminOptions(!showAdminOptions)}
            className="navbar__admin--control"
          >
            <p>ADMIN</p>
            <BsIcons.BsFillPlayCircleFill />
          </div>
          <div
            className={`navbar__admin--control__popup ${
              showAdminOptions ? "show" : ""
            }`}
          >
            <li onClick={logout}>
              <span>Logout</span>
              <BsIcons.BsBoxArrowRight />
            </li>
            <li onClick={onChangePassword}>
              <span>Change Password</span>
              <BsIcons.BsKeyFill />
            </li>
          </div>
          <UpdateOwnPassword
            logoutModal={Modal}
            show={show}
            setShow={setShow}
          />
        </>
      )}
    </nav>
  );
};

export default Navbar;
