import React from "react";
import pptxgen from "pptxgenjs";
import { AiOutlineFilePpt } from "react-icons/ai";
import { useEffect, useState } from "react";

const PriceLadderPptx = ({ data }) => {
  console.log("Ladder Data", data);
  let priceLadderChart = new pptxgen();
  let slide = priceLadderChart.addSlide();
  const [chartData, setChartData] = useState([]);
  /*
    data = {
      brands: ['A', 'B', 'C'],
      ladderData: {
        A: [],
        B: [],
        C: []
      }
    }
  */

  /*
      d: {
        brands: Array,
        ladderData: Object
      }
    */
  const getChartData = (d) => {
    setChartData([]);
    let tmp = [];
    let largestItemLength = 0;
    let { brands, ladderData } = d;
    if (brands && brands?.length > 0) {
      // finding largest item to loop
      // brands.forEach((brand, brandIndex) => {
      //   // splitting brand coz brands come with model name delimited with space
      //   if (ladderData[brand.split(" ")[0]]?.length > largestItemLength) {
      //     largestItemLength = ladderData[brand.split(" ")[0]]?.length;
      //   }
      // });

      Object.keys(ladderData).forEach((item, index) => {
        if (ladderData[item]?.length > largestItemLength) {
          largestItemLength = ladderData[item]?.length;
        }
      });

      let main = {};
      // brands.forEach((brand, brandIndex) => {
      //   main = {
      //     ...main,
      //     [brandIndex]: {
      //       name: brand,
      //       values: [],
      //       labels: [],
      //     },
      //   };
      // });
      Object.keys(ladderData).forEach((item, index) => {
        main = {
          ...main,
          [index]: {
            values: [],
            labels: [],
          },
        };
      });

      Object.keys(ladderData).map((item, index) => {
        Array.apply(null, Array(largestItemLength)).forEach((i, idx) => {
          // console.log(`Ladder Keys ${item} ${idx}`);
          if (ladderData[item][idx]?.cdh_msrp !== undefined) {
            main[idx]?.values?.push(ladderData[item][idx]?.cdh_msrp);
          } else {
            main[idx]?.values?.push(null);
          }
          if (ladderData[item][idx]?.trim_name_spec !== undefined) {
            main[idx]?.labels?.push(
              `${ladderData[item][idx]?.trim_name_spec} - ${ladderData[item][idx]?.cdh_msrp}`
            );
          } else {
            main[idx]?.labels?.push("");
          }
        });
      });

      Object.keys(main).forEach((item, index) => {
        tmp.push(main[item]);
      });

      setChartData((prev) => {
        return [...prev, ...tmp];
      });
    }
  };

  useEffect(() => {
    if (data) {
      getChartData(data);
    }
  }, [data]);

  let arrDataScatter1 = [
    {
      name: "X-Axis",
      values: [0, 1, 2, 3, 4, 5, 6],
    },
    {
      name: "Y-Value 1",
      values: [90, 80, 70, 85, 75, 92],
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    {
      name: "Y-Value 2",
      values: [21, 32, 40, 49, 31, 29],
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    {
      name: "Y-Value 3",
      values: [31, 42, 50, 69, 71, 89, 100],
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Idx"],
    },
  ];

  let optsChartScat1 = {
    x: 0.5,
    y: 0.6,
    w: "80%",
    h: "80%",
    // legendFontSize: 5,
    // catAxisTitleFontSize: 6,
    // valAxisLabelFontSize: 6,
    // catAxisLabelFontSize: 6,
    // valAxisTitleFontSize: 6,
    dataLabelFontSize: 6,
    valAxisTitle: "Values",
    fontSize: 7,
    valAxisTitleColor: "428442",
    valAxisTitleFontSize: 14,
    showValAxisTitle: true,
    lineSize: 0,
    catAxisTitle: "",
    catAxisTitleColor: "428442",
    catAxisTitleFontSize: 14,
    showCatAxisTitle: false,
    lineDataSymbol: "square",
    showLabel: true, // Must be set to true or labels will not be shown
    dataLabelPosition: "b", // Options: 't'|'b'|'l'|'r'|'ctr'
  };
  slide.addChart(
    priceLadderChart.charts.SCATTER,
    [
      {
        name: "x",
        values: [1, 2, 3, 4, 5, 6],
      },
      ...chartData,
    ],
    optsChartScat1
  );
  // EX: Multiline Text / Line Breaks - use "\n" to create line breaks inside text strings

  let brands = [];

  data?.brands?.forEach((item) => {
    brands.push(item);
  });
  console.log("modified brands", brands);
  let uniqueBrands = [...new Set(brands)];
  console.log("modified brands", uniqueBrands);

  const MITSUBISHI = "MITSUBISHI";
  if (Array.isArray(uniqueBrands) && uniqueBrands?.length > 0) {
    // getting mitsubishi as first data;
    let firstData = uniqueBrands?.find((item) => {
      return item.split(" ")[0] === MITSUBISHI;
    });
    let filteredBrands = uniqueBrands?.filter((brand, brandIndex) => {
      return brand.split(" ")[0] !== MITSUBISHI;
    });
    uniqueBrands = [firstData, ...filteredBrands];
  }

  let textData = "";
  uniqueBrands.forEach((item, idx) => {
    textData += `${idx + 1}. ${item}    `;
  });
  slide.addText(textData, {
    x: 1.6,
    y: 5.2,
    fontSize: 10,
  });
  return (
    <>
      {" "}
      <button
        className="btn_pptx"
        onClick={() =>
          priceLadderChart.writeFile({ fileName: "Price Ladder.pptx" })
        }
      >
        <AiOutlineFilePpt />
        <span style={{ fontSize: ".6rem" }}></span>
      </button>
    </>
  );
};

export default PriceLadderPptx;
