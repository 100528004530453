import React, { useState } from "react";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { axiosAdmin } from "../../../api/axios";
import { useEffect } from "react";

const AddModel = ({
  show,
  setShow,
  carTypes,
  brands,
  formType,
  editModelData,
  setModelsData,
}) => {
  const [brandCode, setBrandCode] = useState();
  const [modelData, setModelData] = useState(null);

  useEffect(() => {
    formType === "Edit" &&
      setModelData({
        ...modelData,
        name: editModelData?.name,
        code: editModelData?.code,
        brand: editModelData?.brands?.id,
        type: editModelData?.car_types?.id,
        id: editModelData?.id,
      });
  }, [editModelData]);

  useEffect(() => {
    brands &&
      brands?.map((brand) => {
        brand.id === Number(modelData?.brand) && setBrandCode(brand.code);
      });
  }, [modelData?.brand]);

  useEffect(() => {
    setModelData({
      ...modelData,
      name: "",
      code: "",
      type: "",
      brand: "",
    });
  }, [formType]);
  const handleTypeChange = (e) => {
    setModelData({ ...modelData, type: e.target.value });
  };
  const handleBrandChange = (e) => {
    setModelData({ ...modelData, brand: e.target.value });
  };
  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setModelData({ ...modelData, [name]: value });
  };
  const handleSubmitModel = (e) => {
    e.preventDefault();
    if (formType === "Add" && !isNaN(Number(modelData?.code))) {
      axiosAdmin
        .post("model/addModel", {
          modelData: {
            name: modelData?.name,
            code: modelData?.code,
            car_type_id: Number(modelData?.type),
            brand_id: Number(modelData?.brand),
          },
          brandCode,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Model added", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("model/getAllModels")
              .then((res) => {
                let data = [];
                res.data.map((model, i) => {
                  data.push({
                    key: model?.id,
                    serialNum: String(i + 1),
                    name: model?.name,
                    id: String(model?.id),
                    status: model?.status ? 1 : 0,
                    carType: model?.car_types?.name,
                    companyName: model?.brands?.name,
                  });
                });
                setModelsData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setModelData({
              ...modelData,
              name: "",
              code: "",
              type: "",
              brand: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Model name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Model code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else if (formType === "Edit" && !isNaN(Number(modelData?.code))) {
      axiosAdmin
        .patch("model/updateModel", {
          modelData: {
            name: modelData?.name,
            code: modelData?.code,
            car_type_id: Number(modelData?.type),
            brand_id: Number(modelData?.brand),
            id: modelData?.id,
          },
          brandCode,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Model updated", {
              position: "top-center",
              autoClose: 2000,
            });
            axiosAdmin
              .get("model/getAllModels")
              .then((res) => {
                let data = [];
                res.data.map((model, i) => {
                  data.push({
                    key: model?.id,
                    serialNum: String(i + 1),
                    name: model?.name,
                    id: String(model?.id),
                    status: model?.status ? 1 : 0,
                    carType: model?.car_types?.name,
                    companyName: model?.brands?.name,
                  });
                });
                setModelsData(data);
              })
              .catch((e) => Promise.reject(e));
            setShow(false);
            setModelData({
              ...modelData,
              name: "",
              code: "",
              type: "",
              brand: "",
              id: "",
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 409 && e.response.data.message === "name") {
            toast.error("Model name already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (
            e.response.status === 409 &&
            e.response.data.message === "code"
          ) {
            toast.error("Model code already exists", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else {
      toast.error("Model code should be a number", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`${formType} Model`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitModel} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`Name`}</Form.Label>
              <Form.Control
                required
                onChange={handleModelChange}
                type="text"
                placeholder={`Enter Model Name`}
                value={modelData?.name}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Code`}</Form.Label>
              <Form.Control
                onChange={handleModelChange}
                minLength={3}
                maxLength={3}
                required
                type="text"
                placeholder={`Enter Model Code`}
                value={modelData?.code}
                name="code"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`Car Type`}</Form.Label>
              <Form.Select
                required
                onChange={handleTypeChange}
                aria-label="Default select example"
              >
                <option value="">Select car type</option>
                {carTypes &&
                  carTypes.map((type) => {
                    return (
                      type.status && (
                        <option
                          selected={type.id === editModelData?.car_types?.id}
                          name="type"
                          key={type.id}
                          value={type.id}
                        >
                          {type.name}
                        </option>
                      )
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`brand`}</Form.Label>
              <Form.Select
                required
                onChange={handleBrandChange}
                aria-label="Default select example"
              >
                <option value="">Select Brand</option>
                {brands &&
                  brands?.map((brand) => {
                    return (
                      brand.status && (
                        <option
                          selected={brand.id === editModelData?.brands?.id}
                          name="brand"
                          key={brand.id}
                          value={brand.id}
                        >
                          {brand.name}
                        </option>
                      )
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Button className=" w-100 mx-auto" type="submit">
              {`${formType === "Edit" ? "Update" : "Add"} Model`}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddModel;
