import React from "react";

const ForgotPass = () => {
    return (
        <div className="container w-100">
            <div className="row text-center">
                <div className="col d-flex justify-content-center mt-5">
                    <div className="forgotpass-card">
                        <div className="card forgotpass-card-main">
                            <div className="mt-4">
                                <img src="/images/brand-1.png" alt="" className="login-logo" />
                            </div>
                            <div className="card-body">
                                <h3>Update Password</h3>
                                <input className="input-feilds mt-4 pb-2 ps-2 pt-2" type="email" placeholder="Email" />
                                <div className="mt-3">
                                <button type="submit" className="btn btn-primary mt-3 pass-correction-btn" id="pass-correction-btn">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPass;

