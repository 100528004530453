import React, { useRef } from "react";
import * as BsIcons from "react-icons/bs";
import DealerPriceTable from "./tables/dealer-price-table";
import NetTransactionTable from "./tables/net-transaction-table";
import { useDownloadExcel } from "react-export-table-to-excel";

import PriceTrendchart from "./charts/transaction-price-trend-chart";
import { Select } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { axiosPrivate } from "../../api/axios";
import { waveMonths } from "../../data/wave-month-data";
import TransactionPricePptx from "./pptx/transaction-price-pptx";

const PriceTrend = () => {
  const [segment, setSegment] = useState(true);
  const [countryData, setCountryData] = useState();
  const [countrySelected, setCountrySelected] = useState("");
  const [modelData, setModelData] = useState();
  const [modelSelected, setModelSelected] = useState();
  const [modelName, setModelName] = useState();
  const [compareData, setCompareData] = useState([]);
  const [compareModelSelected, setCompareModelSelected] = useState([]);
  const [wavesData, setWavesData] = useState();
  const [startWave, setStartWave] = useState();
  const [competTrims, setCompetTrims] = useState();
  const [endWave, setEndWave] = useState();
  const [priceTrendData, setPriceTrendData] = useState();
  const [categories, setCategories] = useState([]);
  const [currency, setCurrency] = useState(true);
  const [carData, setCarData] = useState({});
  const [currencyCode, setCurrencyCode] = useState("AED");
  const [priceTrend, setPriceTrend] = useState("");
  const [trimsDisabled, setTrimsDisabled] = useState(true);
  const [average, setAverage] = useState(false);
  const [filterByTrims, setFilterByTrims] = useState(false);
  const [trimsSelected, setTrimsSelected] = useState();
  const [VAT, setVAT] = useState();

  console.log("Cmpare", compareModelSelected);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Transaction Price",
    sheet: "Users",
  });
  useEffect(() => {
    if (Number(countrySelected) === 1) {
      setCurrencyCode("AED");
      setVAT(5);
    } else if (Number(countrySelected) === 2) {
      setCurrencyCode("SAR");
      setVAT(15);
    } else if (Number(countrySelected) === 3) {
      setCurrencyCode("EGP");
      setVAT(14);
    } else {
      setCurrencyCode("AED");
    }
  }, [countrySelected]);

  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("wave")
      .then((res) => {
        let waves = [];
        res.data.data.map((item) => {
          waves.push({
            month: waveMonths[Number(String(item.code).slice(-2)) - 1],
            year: String(item.code).slice(0, 2),
            code: item.code,
            id: item.id,
          });
        });
        setWavesData(waves);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  useEffect(() => {
    countrySelected &&
      axiosPrivate
        .post("competModels/benchmarkModels", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
        })
        .then((res) => {
          setModelData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [countrySelected, segment]);

  useEffect(() => {
    //// for compare data
    setCompareData([]);
    modelSelected &&
      countrySelected &&
      axiosPrivate
        .post("priceTrend/competModels", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          modelCode: modelSelected,
        })
        .then((res) => {
          let modelRes = res.data.data;
          // setCompetTrims(modelRes);
          modelRes.map((item) => {
            axiosPrivate
              .get(`priceTrend/${item.length === 4 ? 0 + item : item}`)
              .then((response) => {
                res.data.data !== null &&
                  setCompareData((prev) => [...prev, response.data.data]);
              });
          });
        })
        .catch((e) => Promise.reject(e));

    ///////////////////all compet trims////////////
    modelSelected &&
      countrySelected &&
      axiosPrivate
        .post("priceTrend/competTrims", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
          modelCode: modelSelected,
        })
        .then((res) => {
          // setCompetTrims(modelRes);
          setCompetTrims(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [modelSelected, segment, countrySelected]);

  console.log("compareSelected", compareModelSelected);
  useEffect(() => {
    startWave &&
      endWave &&
      modelSelected &&
      countrySelected &&
      compareModelSelected &&
      axiosPrivate
        .post("priceTrend", {
          segment: segment ? 1 : 2,
          countryId: Number(countrySelected),
          bmModelCode: modelSelected,
          compareModelCode:
            compareModelSelected === -2
              ? "0"
              : compareModelSelected === -1
              ? "0"
              : compareModelSelected,
          startWave: String(startWave),
          endWave: String(endWave),
        })
        .then((res) => {
          setPriceTrendData(res.data.data);
          // setCompetTrims(res.data.data?.competTrims);
        });
  }, [
    startWave,
    endWave,
    modelSelected,
    compareModelSelected,
    countrySelected,
  ]);

  useEffect(() => {
    if (priceTrendData) {
      setCategories([]);
      priceTrendData?.categories?.map((item) => {
        setCategories((prev) => [
          ...prev,
          waveMonths[Number(String(item).slice(-2)) - 1] +
            "-" +
            String(item.slice(0, 2)),
        ]);
      });
    }
    /////////////////////////////////////////////
  }, [priceTrendData]);

  useEffect(() => {
    let modelDat = {};
    let waveDat = {};

    priceTrendData?.categories?.map((cat, i) => {
      waveDat = {
        ...waveDat,
        [cat]: [],
      };
    });
    priceTrendData?.carData?.forEach((car, i) => {
      let modelKey = car.cdh_modal_id;
      let waveKey = car.cdh_wave;
      if (!(modelKey in modelDat)) {
        modelDat = {
          ...modelDat,
          [modelKey]: waveDat,
        };
      }

      // if (car.cdh_wave === cat) {
      //   modelDat = { ...modelDat };
      // }

      modelDat[modelKey][waveKey] = [...modelDat[modelKey][waveKey], car];
    });
  }, [priceTrendData]);

  const handleModelChange = (value, data) => {
    setModelSelected(value);
    setModelName(data.children);
    // setCompareModelSelected("0");
    setTrimsSelected([]);
    setCompetTrims();
  };
  const handleCompareModelChange = (value, data) => {
    let cModels = [];
    if (value === -2) {
      setTrimsDisabled(false);
      setFilterByTrims(true);
    } else if (value === "0") {
      compareData?.map((item, index) => {
        cModels.push(item?.code);
      });
    } else {
      setTrimsDisabled(true);
      setFilterByTrims(false);
    }
    // if (value === "-1") {
    //   setCompareModelSelected("0");
    // }
    value === "0"
      ? setCompareModelSelected(cModels)
      : setCompareModelSelected([value]);
    value === -1 ? setAverage(true) : setAverage(false);
  };
  const handleCountryChange = (value) => {
    setCountrySelected(value);
  };

  const handleStartWaveChange = (value) => {
    setStartWave(value);
  };
  const handleEndWaveChange = (value) => {
    setEndWave(value);
  };
  const handlePTChange = (value) => {
    setPriceTrend(value);
  };
  const handleTrimChange = (value) => {
    setTrimsSelected(value);
  };
  return (
    <div className="price-trend">
      <div className="price-trend__header">
        <div className="main-header">
          <div className="main-toggle">
            <p className="rtl">RTL</p>
            <div
              onClick={() => setSegment((prev) => !prev)}
              className={`toggle ${segment ? "right" : "left"}`}
            >
              <div className="toggle__toggler"></div>
            </div>
            <p className="sme">SME</p>
          </div>
          <div className="vat">
            {VAT ? `*All Values Including ${VAT}% VAT` : ""}
          </div>
        </div>
        <div className="price-trend__select">
          <Select
            defaultValue="Country"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={handleCountryChange}
          >
            {countryData &&
              countryData?.map((item) => (
                <Select.Option value={item.code} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
          <Select
            defaultValue="Price Trend"
            style={{
              width: 140,
            }}
            onChange={handlePTChange}
          >
            <Select.Option value={"cdh_transaction_price"}>
              TP Trend{" "}
            </Select.Option>
            <Select.Option value={"cdh_net_transaction_price"}>
              NTP Trend{" "}
            </Select.Option>
            <Select.Option value={"cdh_msrp"}>MSRP Trend </Select.Option>
          </Select>

          <Select
            mode="multiple"
            maxTagCount="responsive"
            placeholder="Segment"
            style={{
              width: 220,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            id="modal-data"
            onChange={handleModelChange}
          >
            {modelData &&
              modelData?.map((item) => (
                <Select.Option value={item.code} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
          <Select
            defaultValue="Compare Model"
            style={{
              width: 160,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            id="modal-data"
            onChange={handleCompareModelChange}
          >
            <Select.Option style={{ color: "green" }} value="0">
              ALL
            </Select.Option>
            <Select.Option style={{ color: "green" }} value={-1}>
              AVERAGE
            </Select.Option>
            <Select.Option style={{ color: "green" }} value={-2}>
              BY TRIM
            </Select.Option>
            {compareData &&
              compareData?.map(
                (data) =>
                  data !== null && (
                    <>
                      <Select.Option value={data?.code} key={data?.id}>
                        {data.name}
                      </Select.Option>
                    </>
                  )
              )}
          </Select>
          <Select
            mode="multiple"
            maxTagCount="responsive"
            style={{
              width: "50%",
            }}
            showArrow={true}
            disabled={trimsDisabled}
            placeholder="Trims"
            onChange={handleTrimChange}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toUpperCase()
                .localeCompare(optionB.children.toUpperCase())
            }
            // options={options}
          >
            {competTrims &&
              competTrims.map((item) => (
                <Select.Option value={item.code} item={item.id}>
                  {item.modelName + " " + item.name}
                </Select.Option>
              ))}

            {/* <Select.Option value={"cdh_net_transaction_price"}>
              NTP Trend{" "}
            </Select.Option>
            <Select.Option value={"cdh_msrp"}>MSRP Trend </Select.Option> */}
          </Select>
          <Select
            defaultValue="Wave From"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={handleStartWaveChange}
          >
            {wavesData?.map((wave, i) => (
              <Select.Option key={wave.id} value={wave.code}>
                {wave.month + "-" + wave.year}
              </Select.Option>
            ))}
          </Select>
          <Select
            defaultValue="Wave To"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={handleEndWaveChange}
          >
            {startWave &&
              wavesData?.map((wave, i) => (
                <Select.Option key={wave.id} value={wave.code}>
                  {wave.month + "-" + wave.year}
                </Select.Option>
              ))}
          </Select>
        </div>
      </div>
      <TransactionPricePptx
        modelName={modelName}
        priceTrendData={priceTrendData}
        startWave={startWave}
        endWave={endWave}
        priceTrend={priceTrend}
      />

      <div className="price-trend__graph">
        <PriceTrendchart
          trimsSelected={trimsSelected}
          filterByTrims={filterByTrims}
          setPriceTrendData={setPriceTrendData}
          modelSelected={modelSelected}
          average={average}
          modelName={modelName}
          priceTrendData={priceTrendData}
          startWave={startWave}
          endWave={endWave}
          priceTrend={priceTrend}
        />
      </div>
      <div className="price-trend-toggle">
        <div className="main-toggle">
          <p className="rtl">{currencyCode}</p>
          <div
            onClick={() => setCurrency((prev) => !prev)}
            className={`toggle ${currency ? "right" : "left"}`}
          >
            <div className="toggle__toggler"></div>
          </div>
          <p className="USD">USD</p>
        </div>
      </div>
      <div className="net-transaction">
        {/* <div className="net-transaction__header">
          <h6>Net Transaction Price</h6>
          <span onClick={onDownload}>
            <BsIcons.BsFileEarmarkExcel />
          </span>
        </div> */}
        <div className="net-transaction__table">
          <NetTransactionTable
            priceTrend={priceTrend}
            tableRef={tableRef}
            currency={currency}
            categories={categories}
            priceTrendData={priceTrendData}
          />
        </div>
      </div>
      <div className="dealer-price">
        <div className="dealer-price__table">
          <DealerPriceTable
            tableRef={tableRef}
            currency={currency}
            categories={categories}
            priceTrendData={priceTrendData}
          />
        </div>
      </div>
      {/* <div className="launch-month">
        <div className="launch-month__header">
          <h6>Launch Month</h6>
          <BsIcons.BsFileEarmarkExcel />
        </div>
        <div className="launch-month__table">
          <LaunchMonthTable />
        </div>
      </div> */}
    </div>
  );
};

export default PriceTrend;
