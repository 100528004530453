import React, { useEffect, useState } from "react";
import pptxgen from "pptxgenjs";
import { waveMonths } from "../../../data/wave-month-data";
import { AiOutlineFilePpt } from "react-icons/ai";

const TransactionPricePptx = ({ priceTrendData, priceTrend, modelName }) => {
  const [pptxName, setPptxName] = useState();
  const [categories, setCategories] = useState([]);
  const [pptxData, setPptxData] = useState();
  const [models, setModels] = useState([]);

  let priceChart = new pptxgen();

  let slide = priceChart.addSlide();

  useEffect(() => {
    if (priceTrend === "cdh_transaction_price") {
      setPptxName("Transaction Price Trend");
    } else if (priceTrend === "cdh_net_transaction_price") {
      setPptxName("Net Transaction Price Trend");
    } else if (priceTrend === "cdh_msrp") {
      setPptxName("MSRP Trend");
    }
  }, [priceTrend]);

  useEffect(() => {
    modelName && setModels([...models, modelName]);
  }, [modelName]);

  useEffect(() => {
    if (priceTrendData) {
      setCategories([]);
      priceTrendData?.categories?.map((item) => {
        setCategories((prev) => [
          ...prev,
          waveMonths[Number(String(item).slice(-2)) - 1],
        ]);
      });
    }
  }, [priceTrendData]);

  useEffect(() => {
    let modelNames = [];
    let data = [];
    let dat = [];
    priceTrendData &&
      categories &&
      Object.keys(priceTrendData?.bmTrims).forEach(function (key, index) {
        console.log("CTitem", priceTrendData?.bmTrims[key]);
        let obj = {
          // [modelName]: {
          //   data: [],
          //   name: "",
          //   values: [],
          //   labels: [],
          //   lineWidth: 5,
          // },
        };
        console.log("modelName", modelName);
        let avg;
        if (priceTrendData?.bmTrims[key]?.length > 1) {
          priceTrendData?.bmTrims[key]?.map((item, i) => {
            if (!(item?.modelName in obj)) {
              obj = {
                [item?.modelName]: {
                  data: [],
                  name: "",
                  values: [],
                  labels: [],
                  lineWidth: 5,
                },
              };
            }
            modelNames.push(item?.modelName);
            // console.log("iitteemm", key);
            obj[item?.modelName].values.push(
              priceTrendData?.bmTrims[key][i][priceTrend] ?? -1
            );
            obj[item?.modelName].labels = categories;
            i + 1 === priceTrendData?.bmTrims[key]?.length && data.push(obj);
            obj[item?.modelName].name =
              priceTrendData?.bmTrims[key][
                priceTrendData?.bmTrims[key]?.length - 1
              ]?.brandName +
              " " +
              priceTrendData?.bmTrims[key][
                priceTrendData?.bmTrims[key]?.length - 1
              ]?.modelName;
          });
        } else {
          modelNames.push(
            priceTrendData?.bmTrims[key][
              priceTrendData?.bmTrims[key]?.length - 1
            ]?.modelName
          );
          data.push({
            [modelName]: {
              name:
                priceTrendData?.bmTrims[key][
                  priceTrendData?.bmTrims[key]?.length - 1
                ]?.brandName +
                " " +
                priceTrendData?.bmTrims[key][
                  priceTrendData?.bmTrims[key]?.length - 1
                ]?.modelName,
              data: [
                priceTrendData?.bmTrims[key][
                  priceTrendData?.bmTrims[key]?.length - 1
                ][priceTrend],
              ],
              values: [
                priceTrendData?.bmTrims[key][
                  priceTrendData?.bmTrims[key]?.length - 1
                ][priceTrend],
              ],
              labels: categories,
            },
          });
        }

        // console.log(obj);
      });
    setPptxData(data);
    console.log("datadata", data);
    priceTrendData &&
      Object.keys(priceTrendData?.competTrims).forEach(function (key, index) {
        console.log("CTitem", priceTrendData?.competTrims[key]);
        let obj = {};
        // Object.keys(obj).forEach(function (key, index) {

        // })
        if (priceTrendData?.competTrims[key]?.length > 1) {
          priceTrendData?.competTrims[key]?.map((item, i) => {
            const model = item.modelName;
            // setModels([...models, model]);
            if (!(model in obj)) {
              obj = {
                [model]: {
                  data: [],
                  name: "",
                  values: [],
                  labels: [],
                  lineWidth: 5,
                },
              };
            }

            modelNames.push(model);
            obj[model].values.push(
              priceTrendData?.competTrims[key][i][priceTrend] ?? -1
            );
            obj[model].labels = categories;

            i === priceTrendData?.competTrims[key]?.length - 1 &&
              data.push(obj);
            obj[model].name =
              priceTrendData?.competTrims[key][
                priceTrendData?.competTrims[key]?.length - 1
              ]?.brandName +
              " " +
              priceTrendData?.competTrims[key][
                priceTrendData?.competTrims[key]?.length - 1
              ]?.modelName;
          });
        } else {
          const model =
            priceTrendData?.competTrims[key][
              priceTrendData?.competTrims[key]?.length - 1
            ]?.modelName;
          setModels([...models, model]);

          data.push({
            [model]: {
              name:
                priceTrendData?.competTrims[key][
                  priceTrendData?.competTrims[key]?.length - 1
                ]?.brandName +
                " " +
                priceTrendData?.competTrims[key][
                  priceTrendData?.competTrims[key]?.length - 1
                ]?.modelName,
              data: [
                priceTrendData?.competTrims[key][
                  priceTrendData?.competTrims[key]?.length - 1
                ][priceTrend],
              ],
              values: [
                priceTrendData?.competTrims[key][
                  priceTrendData?.competTrims[key]?.length - 1
                ][priceTrend],
              ],
              labels: categories,
            },
          });
        }
      });
    setModels([...models, ...modelNames]);
    setPptxData((prev) => [...prev, ...dat]);
  }, [priceTrendData, categories, priceTrend]);
  console.log("pptxdata", pptxData);
  let filteredNames = [];
  let modelsArr = models;
  if (modelsArr) {
    filteredNames = modelsArr?.filter(
      (model, index) =>
        index === modelsArr?.findIndex((other) => model === other)
    );
  }

  let modelObj = {};
  console.log("flteredNames", filteredNames);
  filteredNames?.map((name, i) => {
    // modelObj.push({
    //   [name]: [],
    // });
    modelObj = { ...modelObj, [name]: [] };
  });

  if (pptxData) {
    filteredNames.map((name) => {
      pptxData.map((item, i) => {
        item[name] !== undefined && modelObj[name].push(item[name]);
      });
    });
  }
  const trimAvg = (obj) => {
    let pptxFinal = [];
    Object.keys(obj).forEach(function (key, keyInd) {
      let len = obj[key].length;
      //   console.log("length", len);
      let avg = 0;
      let grp = 0;
      let grpAvg = [];
      categories?.map((cat, ind) => {
        let repeat = 0;
        grpAvg.push(0);
        obj[key].map((item, index) => {
          //   console.log("len", len);
          // if (item.values.length > 1) {
          item.values[ind]
            ? (grpAvg[ind] = grpAvg[ind] + item.values[ind])
            : (repeat += 1);
          if (index === len - 1 && repeat === 0) {
            grpAvg[ind] = Math.round(grpAvg[ind] / len);
          } else if (index === len - 1 && repeat > 0) {
            grpAvg[ind] = Math.round(grpAvg[ind] / (len - repeat));
            // repeat = 0;
          }

          index === len - 1 &&
            ind === categories?.length - 1 &&
            pptxFinal.push({
              name: item.name,
              labels: item.labels,
              values: [...grpAvg],
            });

          // console.log("grpAVg", grpAvg);
          // } else {
          //   avg += item.values[0];

          //   index === len - 1 &&
          //     ind === categories?.length - 1 &&
          //     pptxFinal.push({
          //       name: item.name,
          //       labels: item.labels,
          //       values: [Math.round(avg / len)],
          //     });
          // }
        });
      });
    });
    return pptxFinal;
  };
  // modelObj && trimAvg(modelObj);

  console.log("pptxfinal", modelObj);

  pptxData &&
    slide.addChart(priceChart.ChartType.line, trimAvg(modelObj), {
      showLegend: true,
      legendPos: "t",
      showLabel: true,
      dataLabelPosition: "r",
      lineSize: 1,
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      showPercent: true,
      showValue: true, // for datalabels
      dataLabelFontSize: 7,
      // lineDataSymbol: "none",
      x: 1,
      y: 1,
      w: 8,
      h: 4,
    });

  /////////Decreased Label

  slide.addText("", {
    x: 8.66,
    y: 0.14,
    w: 0.45,
    h: 0.2,
    fontSize: 8,
    fill: { color: "f9cbf1" },
    paraSpaceBefore: 12,
    paraSpaceAfter: 24,
  });
  slide.addText("Decreased", {
    x: 9.11,
    y: 0.14,
    w: 0.89,
    h: 0.2,
    fontSize: 8,
    bold: true,
    paraSpaceBefore: 12,
    paraSpaceAfter: 24,
  });
  ///////////////////////////////

  ////No change Label

  slide.addText("", {
    x: 8.66,
    y: 0.5,
    w: 0.45,
    h: 0.2,
    fontSize: 8,
    fill: { color: "f3c969" },
    paraSpaceBefore: 12,
    paraSpaceAfter: 24,
  });
  slide.addText(pptxName, {
    x: 0.1,
    y: -0.1,
    w: 6,
    h: 1,
    fontSize: 20,
    bold: true,
    // paraSpaceBefore: 12,
    // paraSpaceAfter: 24,
  });

  slide.addText("No Changes", {
    x: 9.11,
    y: 0.5,
    w: 0.89,
    h: 0.2,
    fontSize: 8,
    bold: true,

    paraSpaceBefore: 12,
    paraSpaceAfter: 24,
  });
  ///////////////////////////

  /////Increased Label

  slide.addText("", {
    x: 8.66,
    y: 0.86,
    w: 0.45,
    h: 0.2,
    fontSize: 8,
    fill: { color: "57f15d" },
    paraSpaceBefore: 12,
    paraSpaceAfter: 24,
  });

  slide.addText("Increased", {
    x: 9.11,
    y: 0.86,
    w: 0.89,
    h: 0.2,
    fontSize: 8,
    bold: true,
    paraSpaceBefore: 12,
    paraSpaceAfter: 24,
  });
  ///////////////////
  const showLabel = (model, percent, index) => {
    let color;
    if (Number(percent) > 0) {
      color = "57f15d";
    } else if (Number(percent) === 0) {
      color = "f3c969";
    } else if (Number(percent) < 0) {
      color = "f9cbf1";
    }
    slide.addText(model, {
      x: 1.4 + index,
      y: 0.58,
      w: 0.94,
      h: 0.2,
      fontSize: 8,
      paraSpaceBefore: 12,
      paraSpaceAfter: 24,
    });
    slide.addText(percent + "%", {
      x: 1.4 + index,
      y: 0.78,
      w: 0.45,
      h: 0.2,
      fontSize: 8,
      fill: { color },
      paraSpaceBefore: 12,
      paraSpaceAfter: 24,
    });
  };
  let pptxLabelData = trimAvg(modelObj);
  if (categories?.length > 1) {
    pptxLabelData?.forEach((item, index) => {
      let avg = (100 / item.values[0]) * item.values[item.values.length - 1];
      isNaN(avg) && (avg = 100);
      if (avg === 100) {
        return showLabel(item.name.split(" ")[1], 0, index);
      } else if (avg > 100) {
        return showLabel(item.name.split(" ")[1], Math.round(avg - 100), index);
      } else if (avg < 100) {
        return showLabel(item.name.split(" ")[1], Math.round(avg - 100), index);
      }
    });
  }

  return (
    <>
      {" "}
      <div className="graph-heading graph-price-trend">
        {" "}
        <h5>Transaction Price Trend</h5>{" "}
        <button
          className="btn_pptx"
          onClick={() => priceChart.writeFile({ fileName: "Price Trend.pptx" })}
        >
          <AiOutlineFilePpt />
        </button>
      </div>
    </>
  );
};

export default TransactionPricePptx;
