import React, { useState, useRef, useEffect } from "react";
import { Button, Select } from "antd";
import * as BsIcons from "react-icons/bs";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Option } from "antd/lib/mentions";
import { axiosPrivate } from "../../api/axios";
import { waveMonths } from "../../data/wave-month-data";
import ChartModal from "./charts/chart-modal";
import YourChoicePptx from "./pptx/your-choice-pptx";
import FixedSegmentsPptx from "./pptx/fixed-segments-pptx";
import { time } from "highcharts";
import FixedSegmentChartModal from "./charts/fixedSegmentsChartModal";
import FeaturesTable from "./tables/featuresTable";
import FixedSegmentsFeatures from "./tables/fixedSegmentsFeatures";
import FixedSegmentsFeaturesXlsx from "./xlsx/fixedSegmentsFeaturesXlsx";

const FixedSegments = () => {
  /////////////////////////////////For Benchmark/////////////////////////////
  const [bmModelCodeSelected, setBmModelCodeSelected] = useState();
  const [bmData, setBmData] = useState(null);
  const [bmModelSelected, setBmModelSelected] = useState(null);
  const [bmTrimSelected, setBmTrimSelected] = useState("");
  const [bmTrimName, setBmTrimName] = useState();
  const [bmTrimsData, setBmTrimsData] = useState([]);
  const [bmModelName, setBmModelName] = useState();
  const [bmAverageData, setBmAverageData] = useState({});

  /////////////////////////////////////////////////////////////

  const [countryData, setCountryData] = useState(null);
  const [show, setShow] = useState(false);
  const [countrySelected, setCountrySelected] = useState("");
  const [wavesData, setWavesData] = useState(null);
  const [waveSelected, setWaveSelected] = useState("");
  const [segment, setSegment] = useState(true);
  const [currency, setCurrency] = useState(true);
  const [modelData, setModelData] = useState(null);
  const [trimsData, setTrimsData] = useState([]);
  const [compareData, setCompareData] = useState(null);
  const [currencyFactor, setCurrencyFactor] = useState(1);
  const [currencyCode, setCurrencyCode] = useState("AED");
  const [chartData, setChartData] = useState([]);
  const [chartAvgData, setChartAvgData] = useState([]);
  const [average, setAverage] = useState(false);
  const [compareAvgData, setCompareAvgData] = useState();
  const [VAT, setVAT] = useState();
  const [tpva, setTpva] = useState(true);
  const [countryName, setCountryName] = useState();
  const [showFeatures, setShowFeatures] = useState(false);

  console.log("bmData", bmData);
  console.log("compareData", trimsData);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    if (Number(countrySelected) === 1 && currency) {
      setCurrencyFactor(3.67);
      setCurrencyCode("AED");
      setVAT(5);
    } else if (Number(countrySelected) === 2 && currency) {
      setCurrencyFactor(3.67);
      setCurrencyCode("SAR");
      setVAT(15);
    } else if (Number(countrySelected) === 3 && currency) {
      setCurrencyFactor(19.62);
      setCurrencyCode("EGP");
      setVAT(14);
    } else {
      setCurrencyFactor(1);
    }
  }, [countrySelected, currency]);

  useEffect(() => {
    setCompareData();
    setBmTrimName();
    setBmTrimSelected();
    setBmData(null);
    setCompareData();
    // setBmTrimsData([]);
    // setTrimsData([]);
    setChartData([]);
    setChartAvgData([]);
    setCompareAvgData([]);
    setCompareData([]);
    setBmAverageData({});
  }, [segment, countrySelected, bmModelSelected, waveSelected]);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Fixed Segments table",
    sheet: "FixedSegments",
  });

  ////////////////////////For calculating trims average for Benchmark Model

  useEffect(() => {
    average && bmTrimsData && bmAverage();
  }, [bmTrimsData, average]);
  const bmAverage = () => {
    let msrp = 0;
    let incentive = 0;
    let ntp = 0;
    let tov = 0;
    let msrpDollar = 0;
    let incentiveDollar = 0;
    let ntpDollar = 0;
    let id = 0;
    let my = "";
    let trims = [];
    let models = [];
    let brands = [];
    let cdh_cash_discount = 0;
    let cdh_value_other_offer = 0;

    bmTrimsData?.map((trim, i) => {
      msrp += trim.cdh_msrp;
      incentive += trim.cdh_total_offer_value;
      ntp += trim.cdh_net_transaction_price;
      tov += trim.TOValue;
      msrpDollar += trim.cdh_msrp_dollar;
      incentiveDollar += trim.cdh_total_offer_value_dollar;
      ntpDollar += trim.cdh_net_transaction_price_dollar;
      my = trim.carYear;
      trims = { ...trim.trims, name: "Average" };
      models = trim.models;
      brands = trim.brands;
      id = trim.cdh_id;
      cdh_cash_discount = trim.cdh_cash_discount;
      cdh_value_other_offer = trim.cdh_value_other_offer;
    });
    setBmAverageData({
      cdh_msrp: Math.round(msrp / bmTrimsData?.length),
      cdh_total_offer_value: Math.round(incentive / bmTrimsData?.length),
      cdh_net_transaction_price: Math.round(ntp / bmTrimsData?.length),
      TOValue: Math.round(tov / bmTrimsData?.length),
      cdh_msrp_dollar: Math.round(msrpDollar / bmTrimsData?.length),
      cdh_total_offer_value_dollar: Math.round(
        incentiveDollar / bmTrimsData?.length
      ),
      cdh_net_transaction_price_dollar: Math.round(
        ntpDollar / bmTrimsData?.length
      ),
      cdh_car_year: my,
      models,
      trims,
      brands,
      cdh_id: id,
      cdh_cash_discount,
      cdh_value_other_offer,
    });
  };
  ////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////For calculating trims average for compare models////////

  useEffect(() => {
    trimsData && average && averageData();
  }, [trimsData, average]);
  const averageData = () => {
    setChartData([]);
    Object.keys(trimsData).map((key, ind) => {
      let msrp = 0;
      let incentive = 0;
      let ntp = 0;
      let tov = 0;
      let msrpDollar = 0;
      let incentiveDollar = 0;
      let ntpDollar = 0;
      let my = "";
      let trims = [];
      let models = [];
      let brands = [];
      let cdh_cash_discount = 0;
      let cdh_value_other_offer = 0;
      let cdh_id;
      // trimsData[key]?.length > 0 &&
      trimsData[key].map((trim, i) => {
        // console.log("trim", trim.cdh_msrp);
        msrp += trim.cdh_msrp;
        incentive += trim.cdh_total_offer_value;
        ntp += trim.cdh_net_transaction_price;
        tov += trim.TOValue;
        msrpDollar += trim.cdh_msrp_dollar;
        incentiveDollar += trim.cdh_total_offer_value_dollar;
        ntpDollar += trim.cdh_net_transaction_price_dollar;
        my = trim.carYear;
        models = trim.models;
        trims = { ...trim.trims, name: "Average" };
        brands = trim.brands;
        cdh_cash_discount = trim.cdh_cash_discount;
        cdh_value_other_offer = trim.cdh_value_other_offer;
        cdh_id = trim.cdh_id;
      });
      // i === trimsData[key]?.length - 1 &&

      setCompareAvgData((prev) => ({
        ...prev,
        [key]: {
          cdh_msrp: Math.round(msrp / trimsData[key]?.length),
          cdh_total_offer_value: Math.round(incentive / trimsData[key]?.length),
          cdh_net_transaction_price: Math.round(ntp / trimsData[key]?.length),
          TOValue: Math.round(tov / trimsData[key]?.length),
          cdh_msrp_dollar: Math.round(msrpDollar / trimsData[key]?.length),
          cdh_total_offer_value_dollar: Math.round(
            incentiveDollar / trimsData[key]?.length
          ),
          cdh_net_transaction_price_dollar: Math.round(
            ntpDollar / trimsData[key]?.length
          ),
          carYear: my,
          brands,
          trims,
          models,
          cdh_cash_discount,
          cdh_value_other_offer,
          cdh_id,
        },
      }));
      !isNaN(Math.round(msrp / trimsData[key]?.length)) &&
        setChartAvgData((prev) => [
          ...prev,
          {
            cdh_msrp: Math.round(msrp / trimsData[key]?.length),
            cdh_total_offer_value: Math.round(
              incentive / trimsData[key]?.length
            ),
            cdh_net_transaction_price: Math.round(ntp / trimsData[key]?.length),
            TOValue: Math.round(tov / trimsData[key]?.length),
            cdh_msrp_dollar: Math.round(msrpDollar / trimsData[key]?.length),
            cdh_total_offer_value_dollar: Math.round(
              incentiveDollar / trimsData[key]?.length
            ),
            cdh_net_transaction_price_dollar: Math.round(
              ntpDollar / trimsData[key]?.length
            ),
            carYear: my,
            brands,
            trims,
            models,
            cdh_cash_discount,
            cdh_value_other_offer,
            cdh_id,
          },
        ]);
    });
  };
  ////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   bmAverageData && setBmData(bmAverageData);
  // }, [bmAverageData]);
  ////////////////////////////////////
  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
    axiosPrivate
      .get("wave")
      .then((res) => {
        let waves = [];
        res.data.data.map((item) => {
          waves.push({
            month: waveMonths[Number(String(item.code).slice(-2)) - 1],
            year: String(item.code).slice(0, 2),
            code: item.code,
            id: item.id,
            chartData,
          });
        });
        setWavesData(waves);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  ////////////////////for getting mmitsibushi models////////////////
  useEffect(() => {
    countrySelected &&
      axiosPrivate
        .post("competModels/benchmarkModels", {
          countryId: Number(countrySelected),
          segment: segment ? 1 : 2,
        })
        .then((res) => {
          setModelData(res.data.data);
        })
        .catch((e) => Promise.reject(e));
  }, [countrySelected, segment]);

  ///////////////////////for getting trims for the BM Model selected////////////////////
  useEffect(() => {
    bmModelSelected &&
      waveSelected &&
      countrySelected &&
      !average &&
      axiosPrivate
        .post(`yourChoice/trims`, {
          countryId: Number(countrySelected),
          month: Number(String(waveSelected).slice(-2)),
          year: Number("20" + String(waveSelected).slice(0, 2)),
          modelCode: bmModelSelected,
          segment: segment ? 1 : 2,
        })
        .then((res) => {
          setBmTrimsData([]);
          let bmRes = res.data.data;
          // let data = [];
          bmRes?.map((item, i) => {
            axiosPrivate
              .get(`features/${item?.cdh_id}`)
              .then((response) => {
                let data = response?.data?.data;
                setBmTrimsData((prev) => [...prev, { ...item, TOValue: data }]);
              })
              .catch((e) => Promise.reject(e));
          });
        })
        .catch((e) => Promise.reject(e));
  }, [bmModelSelected, segment, waveSelected, countrySelected, average]);

  ///fro getting benchmark data //////////////////

  useEffect(() => {
    bmTrimSelected &&
      bmTrimSelected !== -1 &&
      !average &&
      axiosPrivate
        .post(`yourChoice`, {
          countryId: Number(countrySelected),
          wave: String(waveSelected),
          segment: segment ? 1 : 2,
          variantId: bmTrimSelected,
          // carYear: 2022,
        })
        .then((res) => {
          let compRes = res?.data?.data;
          // setChartData((prev) => [...prev, compRes]);
          compRes
            ? axiosPrivate
                .get(`features/${compRes.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  compRes.TOValue = data;

                  setBmData(compRes);
                })
                .catch((e) => Promise.reject(e))
            : setBmData();
        })
        .catch((e) => Promise.reject(e));
  }, [
    bmModelSelected,
    bmTrimSelected,
    waveSelected,
    countrySelected,
    segment,
    average,
  ]);

  ///////fro getting all  trims/////////////////////////

  useEffect(() => {
    //// for compare data
    bmModelSelected &&
      waveSelected &&
      countrySelected &&
      !average &&
      axiosPrivate
        .post("competModels", {
          countryId: Number(countrySelected),
          month: Number(String(waveSelected).slice(-2)),
          year: Number("20" + String(waveSelected).slice(0, 2)),
          segment: segment ? 1 : 2,
          modelCode: bmModelSelected,
        })
        .then((res) => {
          let modelRes = res.data.data;
          setCompareData(modelRes);
        })
        .catch((e) => Promise.reject(e));
  }, [bmModelSelected, segment, waveSelected, countrySelected, average]);

  ///////////////////////////////////////for Getting all trims///////////////////////////////////////

  useEffect(() => {
    //// for compare data
    setTrimsData([]);
    setChartData([]);
    bmModelSelected &&
      waveSelected &&
      countrySelected &&
      compareData &&
      !average &&
      compareData?.map((item, i) => {
        axiosPrivate
          .post(`yourChoice/trims`, {
            countryId: Number(countrySelected),
            month: Number(String(waveSelected).slice(-2)),
            year: Number("20" + String(waveSelected).slice(0, 2)),
            modelCode: item.length === 4 ? "0" + item : item,
            segment: segment ? 1 : 2,
          })
          .then((response) => {
            // setChartData([]);
            let resDat = response.data.data;
            let newDat = [];
            resDat?.map((dat) => {
              axiosPrivate
                .get(`features/${dat?.cdh_id}`)
                .then((response) => {
                  let data = response?.data?.data;
                  newDat.push({ ...dat, TOValue: data });

                  dat &&
                    setChartData((prev) => [
                      ...prev,
                      { ...dat, TOValue: data },
                    ]);
                })
                .catch((e) => Promise.reject(e));
            });
            console.log("newData", newDat);
            setTrimsData((prev) => ({
              ...prev,
              [item]: newDat,
            }));
          })
          .catch((e) => Promise.reject(e));
      });
  }, [
    bmModelSelected,
    segment,
    waveSelected,
    countrySelected,
    compareData,
    average,
  ]);

  useEffect(() => {
    const i = chartData?.findIndex((e) => e?.cdh_id === bmData?.cdh_id);
    const j = chartData?.findIndex((e) => e?.brands?.code === "20");
    console.log("jjjjj", j);
    if (j === 0) {
      setChartData(() => {
        const tmp = chartData;
        tmp.shift();
        return tmp;
      });
    }
    // compareAvgData && setChartData(compareAvgData);
    !average &&
      bmData &&
      i === -1 &&
      setChartData((prev) => {
        // prev?.shift();
        return [bmData, ...prev];
      });
    // : setChartData();
  }, [bmData, trimsData, compareAvgData, average]);
  console.log("bmData", bmData);
  useEffect(() => {
    axiosPrivate
      .get("country/userCountries")
      .then((res) => {
        setCountryData(res.data.data);
      })
      .catch((e) => Promise.reject(e));
  }, []);

  const handleWaveChange = (value) => {
    setWaveSelected(value);
  };
  const handleBmTrimChange = (value, data) => {
    value === -1 ? setAverage(true) : setAverage(false);
    setBmTrimSelected(value);
    setBmTrimName(data.children);
  };
  const handleBmModelChange = (value, data) => {
    setBmModelSelected(value);
    setBmModelName(data.children);
    setBmModelCodeSelected(data.key);
    setBmTrimName();
    setBmTrimSelected();
    setBmData(null);
    setChartData([]);
    setWaveSelected();
  };
  console.log("trimsData", trimsData);
  const handleCountryChange = (value, data) => {
    setCountrySelected(value);
    setCountryName(data.children);
    setWaveSelected();
    setBmModelSelected();
    setBmModelName();
  };
  const handleFeaturesShow = () => {
    setShowFeatures(!showFeatures);
  };
  console.log("chartData", chartData);
  return (
    <>
      <div className="pricing-summary">
        <div className="main-header">
          <div className="main-toggle">
            <p className="rtl">RTL</p>
            <div
              onClick={() => setSegment((prev) => !prev)}
              className={`toggle ${segment ? "right" : "left"}`}
            >
              <div className="toggle__toggler"></div>
            </div>
            <p className="sme">SME</p>
          </div>
          <div className="vat">
            {VAT ? `*All Values Including ${VAT}% VAT` : ""}
          </div>
        </div>
        <div className="pricing-summary__controls">
          <Select
            defaultValue="Country"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            onChange={handleCountryChange}
          >
            {countryData &&
              countryData?.map((item) => (
                <Select.Option value={item.code} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            value={bmModelName ? bmModelName : "Model"}
            style={{
              width: 140,
            }}
            onChange={handleBmModelChange}
          >
            {modelData?.map((model, i) => (
              <Option key={model.id} value={model.code}>
                {model.name}
              </Option>
            ))}
          </Select>
          <Select
            defaultValue="Wave"
            style={{
              width: 120,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toUpperCase().includes(input.toUpperCase())
            }
            value={waveSelected ? waveSelected : "Wave"}
            onChange={handleWaveChange}
          >
            {wavesData?.map((wave, i) => (
              <Select.Option key={wave.id} value={wave.code}>
                {wave.month + "-" + wave.year}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="data-options">
          <h5>Pricing Summary</h5>
          <div className="data-options__tools">
            <FixedSegmentsPptx
              chartData={chartData}
              bmData={bmData}
              bmAverageData={bmAverageData}
              chartAvgData={chartAvgData}
              average={average}
              bmTrimSelected={bmTrimSelected}
              currencyFactor={currencyFactor}
            />
            <span onClick={() => setShow(true)}>
              <BsIcons.BsGraphUp />
            </span>
            <span onClick={onDownload}>
              <BsIcons.BsFileEarmarkExcel />
            </span>
            <span>|</span>
            <div className="main-toggle">
              <p className="rtl">TPVA</p>
              <div
                onClick={() => setTpva((prev) => !prev)}
                className={`toggle ${tpva ? "right" : "left"}`}
              >
                <div className="toggle__toggler"></div>
              </div>
              <p className="sme">NTPVA</p>
            </div>
            <span>|</span>
            <div className="main-toggle">
              <p className="rtl">{currencyCode}</p>
              <div
                onClick={() => setCurrency((prev) => !prev)}
                className={`toggle ${currency ? "right" : "left"}`}
              >
                <div className="toggle__toggler"></div>
              </div>
              <p className="USD">USD</p>
            </div>
          </div>
        </div>
        <FixedSegmentChartModal
          show={show}
          data={chartData}
          setShow={setShow}
          average={average}
          bmData={bmData}
          bmAverageData={bmAverageData}
          chartAvgData={chartAvgData}
        />
        <div className="user-table">
          <table ref={tableRef} id="l">
            <thead>
              <tr>
                <th>Model Name</th>
                <th>Trim Name</th>
                <th>Trim Name Spec</th>
                <th>My</th>
                <th>MSRP (A)</th>
                <th>Cash Discount (B)</th>
                <th>TP (C=A-B)</th>
                <th>Total Options Value (D)</th>
                {!tpva && <th>Value Of Other Offers (E)</th>}
                <th>
                  {tpva
                    ? "Value Adjusted NTP (E=C-D)"
                    : "Value Adjusted NTP (F=C-D-E)"}
                </th>
                <th>VI %</th>
                <th>VA %</th>
                <th>{tpva ? "TPVA" : "NTPVA"} %</th>
              </tr>
            </thead>
            <tbody>
              {
                <tr>
                  <td className="user-table__data">{bmModelName}</td>
                  <td className="user-table__data">
                    <Select
                      value={bmTrimName || "Trims"}
                      style={{
                        width: 220,
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toUpperCase()
                          .includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toUpperCase()
                          .localeCompare(optionB.children.toUpperCase())
                      }
                      onChange={handleBmTrimChange}
                    >
                      <Option value={-1}>Average</Option>
                      {bmTrimsData?.map((trim, i) => (
                        <Option key={i} value={trim.code}>
                          {trim.name}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  {/* <Select
                      value={bmCarYear || "Year"}
                      style={{
                        width: 120,
                      }}
                      onChange={handleBmCarYearChange}
                      >
                      <Option value="2022">2022</Option>
                      <Option value="2023">2023</Option>
                    </Select> */}
                  {
                    <>
                      <td className="user-table__data">
                        {bmData?.trim_name_spec}
                      </td>{" "}
                      <td className="user-table__data">
                        {bmData?.cdh_car_year}
                      </td>{" "}
                      <td>
                        {numberWithCommas(
                          bmTrimSelected && average
                            ? currency
                              ? bmAverageData?.cdh_msrp
                              : bmAverageData?.cdh_msrp_dollar
                            : currency
                            ? bmData?.cdh_msrp
                            : bmData?.cdh_msrp_dollar
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          average
                            ? currency
                              ? bmAverageData?.cdh_cash_discount
                              : bmAverageData?.cdh_cash_discount_dollar
                            : currency
                            ? bmData?.cdh_cash_discount
                            : bmData?.cdh_cash_discount_dollar
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          average
                            ? currency
                              ? bmAverageData?.cdh_net_transaction_price
                              : bmAverageData?.cdh_net_transaction_price_dollar
                            : currency
                            ? bmData?.cdh_net_transaction_price
                            : bmData?.cdh_net_transaction_price_dollar
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          average
                            ? Math.round(
                                currencyFactor * bmAverageData?.TOValue
                              )
                            : Math.round(currencyFactor * bmData?.TOValue)
                        )}
                      </td>
                      {!tpva && (
                        <td>
                          {numberWithCommas(
                            average
                              ? currency
                                ? bmAverageData?.cdh_value_other_offer
                                : bmAverageData?.cdh_value_other_offer_dollar
                              : currency
                              ? bmData?.cdh_value_other_offer
                              : bmData?.cdh_value_other_offer_dollar
                          )}
                        </td>
                      )}
                      <td>
                        {numberWithCommas(
                          tpva
                            ? average
                              ? currency
                                ? Math.round(
                                    bmAverageData?.cdh_net_transaction_price -
                                      currencyFactor * bmAverageData?.TOValue
                                  )
                                : Math.round(
                                    bmAverageData?.cdh_net_transaction_price_dollar -
                                      currencyFactor * bmAverageData?.TOValue
                                  )
                              : currency
                              ? Math.round(
                                  bmData?.cdh_net_transaction_price -
                                    currencyFactor * bmData?.TOValue
                                )
                              : Math.round(
                                  bmData?.cdh_net_transaction_price_dollar -
                                    currencyFactor * bmData?.TOValue
                                )
                            : average
                            ? currency
                              ? Math.round(
                                  bmAverageData?.cdh_net_transaction_price -
                                    currencyFactor * bmAverageData?.TOValue -
                                    bmAverageData?.cdh_value_other_offer
                                )
                              : Math.round(
                                  bmAverageData?.cdh_net_transaction_price_dollar -
                                    currencyFactor * bmAverageData?.TOValue -
                                    bmAverageData?.cdh_value_other_offer_dollar
                                )
                            : currency
                            ? Math.round(
                                bmData?.cdh_net_transaction_price -
                                  currencyFactor * bmData?.TOValue -
                                  bmData?.cdh_value_other_offer
                              )
                            : Math.round(
                                bmData?.cdh_net_transaction_price_dollar -
                                  currencyFactor * bmData?.TOValue -
                                  bmData?.cdh_value_other_offer_dollar
                              )
                        )}
                      </td>
                      <td>100%</td>
                      <td>100%</td>
                      <td>100 %</td>
                    </>
                  }
                </tr>
              }

              {
                trimsData &&
                  bmData &&
                  bmTrimSelected &&
                  compareData?.map((data, i) => {
                    let allTrims = { ...trimsData };
                    // let trimsAverage = { ...compareAvgData };
                    let trim;
                    average && compareAvgData
                      ? (trim = [compareAvgData[data]])
                      : (trim = allTrims[data]);
                    // trim &&
                    //   trim.map((item) => {
                    //     console.log(item.name);
                    //   });
                    let column =
                      trim?.length > 2 ? Math.floor(trim?.length / 2) : 0;
                    return (
                      <>
                        {trim
                          ?.sort((a, b) => a?.cdh_msrp - b?.cdh_msrp)
                          ?.map((trim, index) => {
                            if (!isNaN(trim?.cdh_msrp)) {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    borderTop: "1px solid #0000003d",
                                    borderBottom: "1px solid #0000003d",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderTop: ` ${
                                        index !== 0 ? "1px solid #fff" : ""
                                      }`,
                                      borderRight: "1px solid #0000003d",
                                    }}
                                  >
                                    {index === column
                                      ? trim.brands.name +
                                        " " +
                                        trim.models.name
                                      : ""}
                                  </td>
                                  <td>{trim.trims.name}</td>
                                  <td>{trim?.trim_name_spec}</td>
                                  <td>{trim.carYear}</td>
                                  <td>
                                    {numberWithCommas(
                                      currency
                                        ? trim?.cdh_msrp
                                        : trim?.cdh_msrp_dollar
                                    )}
                                  </td>
                                  <td>
                                    {numberWithCommas(
                                      currency
                                        ? trim?.cdh_cash_discount
                                        : trim?.cdh_cash_discount_dollar
                                    )}
                                  </td>
                                  <td>
                                    {numberWithCommas(
                                      currency
                                        ? trim?.cdh_msrp -
                                            trim?.cdh_cash_discount
                                        : trim?.cdh_msrp_dollar -
                                            trim?.cdh_cash_discount_dollar
                                    )}
                                  </td>
                                  <td>
                                    {numberWithCommas(
                                      Math.round(currencyFactor * trim.TOValue)
                                    )}
                                  </td>
                                  {!tpva && (
                                    <td>
                                      {numberWithCommas(
                                        currency
                                          ? trim?.cdh_value_other_offer
                                          : trim?.cdh_value_other_offer_dollar
                                      )}
                                    </td>
                                  )}
                                  <td>
                                    {numberWithCommas(
                                      tpva
                                        ? currency
                                          ? Math.round(
                                              trim?.cdh_net_transaction_price -
                                                currencyFactor * trim?.TOValue
                                            )
                                          : Math.round(
                                              trim?.cdh_net_transaction_price_dollar -
                                                currencyFactor * trim?.TOValue
                                            )
                                        : currency
                                        ? Math.round(
                                            trim?.cdh_net_transaction_price -
                                              currencyFactor * trim?.TOValue -
                                              trim?.cdh_value_other_offer
                                          )
                                        : Math.round(
                                            trim?.cdh_net_transaction_price_dollar -
                                              currencyFactor * trim?.TOValue -
                                              trim?.cdh_value_other_offer_dollar
                                          )
                                    )}
                                  </td>
                                  <td>
                                    {Math.round(
                                      (trim?.cdh_msrp / bmData?.cdh_msrp) * 100
                                    )}
                                    %
                                  </td>
                                  <td>
                                    {currency
                                      ? Math.round(
                                          ((trim?.cdh_msrp +
                                            (bmData?.TOValue * currencyFactor -
                                              trim?.TOValue * currencyFactor)) /
                                            bmData?.cdh_msrp) *
                                            100
                                        )
                                      : Math.round(
                                          ((trim?.cdh_msrp_dollar +
                                            (bmData?.TOValue * currencyFactor -
                                              trim?.TOValue * currencyFactor)) /
                                            bmData?.cdh_msrp_dollar) *
                                            100
                                        )}
                                    %
                                  </td>
                                  <td>
                                    {currency
                                      ? tpva
                                        ? Math.round(
                                            ((trim?.cdh_msrp +
                                              (bmData?.TOValue *
                                                currencyFactor -
                                                trim?.TOValue *
                                                  currencyFactor) -
                                              trim?.cdh_total_offer_value) /
                                              (bmData?.cdh_msrp -
                                                bmData?.cdh_total_offer_value)) *
                                              100
                                          )
                                        : Math.round(
                                            ((trim?.cdh_msrp +
                                              (bmData?.TOValue *
                                                currencyFactor -
                                                trim?.TOValue *
                                                  currencyFactor) -
                                              trim?.cdh_cash_discount -
                                              trim?.cdh_total_offer_value) /
                                              (bmData?.cdh_msrp -
                                                bmData?.cdh_cash_discount -
                                                bmData.cdh_total_offer_value)) *
                                              100
                                          )
                                      : tpva
                                      ? Math.round(
                                          ((trim?.cdh_msrp_dollar +
                                            (bmData?.TOValue * currencyFactor -
                                              trim?.TOValue * currencyFactor) -
                                            trim?.cdh_total_offer_value_dollar) /
                                            (bmData?.cdh_msrp_dollar -
                                              bmData?.cdh_total_offer_value_dollar)) *
                                            100
                                        )
                                      : Math.round(
                                          ((trim?.cdh_msrp_dollar +
                                            (bmData?.TOValue * currencyFactor -
                                              trim?.TOValue * currencyFactor) -
                                            trim?.cdh_cash_discount_dollar -
                                            trim?.cdh_total_offer_value_dollar) /
                                            (bmData?.cdh_msrp_dollar -
                                              bmData?.cdh_cash_discount_dollar -
                                              bmData.cdh_total_offer_value_dollar)) *
                                            100
                                        )}
                                    %
                                  </td>
                                </tr>
                                //   );
                                // })
                              );
                            }
                          })}
                      </>
                    );
                  })

                // ));

                //));
              }
            </tbody>
          </table>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          className=""
        >
          <FixedSegmentsFeaturesXlsx
            bmData={bmData}
            currencyFactor={currencyFactor}
            currencyCode={currencyCode}
            compareData={trimsData}
            countryName={countryName}
            modelName={bmModelName}
            wave={waveSelected}
            currency={currency}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={handleFeaturesShow} className="btn-details">
            {showFeatures ? "Collapse" : "Expand"}
          </button>
        </div>
        <FixedSegmentsFeatures
          bmData={bmData}
          currencyFactor={currencyFactor}
          currencyCode={currencyCode}
          compareData={trimsData}
          bmTrimSelected={bmTrimSelected}
          countryName={countryName}
          modelName={bmModelName}
          wave={waveSelected}
          showFeatures={showFeatures}
          countrySelected={countrySelected}
          waveSelected={waveSelected}
          bmModelSelected={bmModelSelected}
        />
        {/* <PricingSummaryTable
          modelData={modelData}
          bmTrimsData={bmTrimsData}
          data={tableData}
          tableRef={tableRef}
          modelSelected={modelSelected}
          modelName={modelName}
        /> */}
      </div>
    </>
  );
};
export default FixedSegments;
