import React, { useState, useRef, useEffect } from "react";
import { axiosPrivate } from "../../api/axios";

const SummaryOfModels = () => {
  const [modelsData, setModelsData] = useState();

  useEffect(() => {
    axiosPrivate
      .get("model?active=true&brandId=0")
      .then((res) => {
        setModelsData(res.data.data);
      })
      .catch((err) => Promise.reject(err));
  }, []);

  return (
    <>
      <div className="data-options">
        <br />
        <br />
        <h4>Summary of Models</h4>
        <br />
        <br />
        <br />
        <br />
      </div>
      <div className="pricing-summary">
        <div className="user-table">
          <table id="l">
            <thead>
              <tr>
                <th>Sl. No</th>
                <th>Brand Name</th>
                <th>Model Name</th>

                {/* <th>Promotions</th> */}
              </tr>
            </thead>
            <tbody>
              {modelsData &&
                modelsData?.map((model, index) => (
                  <tr key={model.id}>
                    <td>{index + 1}</td>
                    <td>{model.brands.name}</td>
                    <td>{model.name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SummaryOfModels;
