import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import { logout, logoutUser } from "../api/axios";
import { Tooltip } from "antd";
import vivaLogo from "../assets/images/userLogo.jpg";

const Sidebar = ({ sidebarOpen, sidebarItems, type }) => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  let currentPath = pathSnippets[pathSnippets.length - 1];
  if (currentPath === undefined) {
    currentPath = "/";
  }
  return (
    <div className={`sidebar ${sidebarOpen ? "opened" : ""} ${type}`}>
      <div className="sidebar__header">
        <img className="sidebar__brand-logo" src={vivaLogo} alt="brand-logo" />
      </div>
      <div className="sidebar__items">
        {sidebarItems.map((item) => (
          <Tooltip key={item.id} placement="right" title={item.name}>
            <Link
              className={`sidebar__item ${
                currentPath === item.path ? "selected" : ""
              }`}
              to={item.path || "#"}
            >
              <li>
                {item.icon}
                &nbsp;
                <span>{item.name}</span>
              </li>
            </Link>
          </Tooltip>
        ))}
        {type === "admin" && (
          <Link onClick={() => logout()} className="sidebar__item" to="#">
            <li>
              <BsIcons.BsBoxArrowLeft />
              &nbsp;&nbsp; <span>Logout</span>
            </li>
          </Link>
        )}
        {type === "user" && (
          <Link onClick={() => logoutUser()} className="sidebar__item" to="#">
            <li>
              <BsIcons.BsBoxArrowLeft />
              &nbsp;&nbsp; <span>Logout</span>
            </li>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
