import React from "react";
import { Outlet } from "react-router-dom";
import DashboardBreadcrumb from "../components/admin/breadcrumb";
import AdminLayout from "../layout/admin-layout";

const Dashboard = () => {
  return (
    <AdminLayout>
      <DashboardBreadcrumb />
      <div className="page">
        <Outlet />
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
