import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { axiosAdmin, logout } from "../../../api/axios";
import { toast } from "react-toastify";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const UpdateOwnPassword = ({ show, setShow, logoutModal }) => {
  const [userData, setUserData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const handleupdateOwnPassword = (e) => {
    e.preventDefault();
    if (userData?.newPassword === userData?.confirmPassword) {
      axiosAdmin
        .patch("user/updateOwnPassword", {
          oldPassword: userData?.oldPassword,
          newPassword: userData?.newPassword,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("Password Updated", {
              position: "top-center",
              autoClose: 2000,
            });
            setShow(false);
            setUserData({
              ...userData,
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            localStorage.clear();
            const { confirm } = logoutModal;

            confirm({
              title: "Session expired!",
              icon: <ExclamationCircleOutlined />,
              content: "Login again",
              cancelButtonProps: { hidden: true },

              onOk() {
                logout();
              },
            });
          }
        })
        .catch((e) => {
          if (
            e.response.status === 401 &&
            e.response.data?.message === "Invalid old password"
          ) {
            toast.error("Incorrect password", {
              position: "top-center",
              autoClose: 2000,
            });
            Promise.reject(e);
          }
        });
    } else {
      toast.error("No Match", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`Password Update`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleupdateOwnPassword} className="p-3">
            <Form.Group className="mb-4">
              <Form.Label>{`Old password`}</Form.Label>
              <Form.Control
                required
                onChange={handleUserChange}
                type="password"
                placeholder={`Enter old password`}
                value={userData?.oldPassword}
                name="oldPassword"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>{`New password`}</Form.Label>
              <Form.Control
                required
                onChange={handleUserChange}
                type="password"
                placeholder={`Enter New password`}
                value={userData?.newPassword}
                name="newPassword"
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>{`Confirm Password`}</Form.Label>
              <Form.Control
                required
                onChange={handleUserChange}
                type="password"
                placeholder={`Enter Confirm Password`}
                value={userData?.confirmPassword}
                name="confirmPassword"
              />
            </Form.Group>

            <Button className=" w-100 mx-auto" type="submit">
              Update Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateOwnPassword;
