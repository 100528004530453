import React, { useState, useEffect } from "react";

import { Table } from "antd";

const CommonTable = ({
  data,
  columns,
  pageSize,
  setTableFilteredValue,
  tableFilteredValue,
}) => {
  const [currentTableIltems, setCurrentTableItems] = useState(data?.length);
  const [currentPage, setCurrentPage] = useState(1);
  const onChange = (pagination, filters, sorter, extra) => {
    setTableFilteredValue(filters);
    extra?.currentDataSource &&
      setCurrentTableItems(extra?.currentDataSource?.length);
    setCurrentPage(pagination?.current);
  
  };

  const totalPages = Math.ceil(data?.length / pageSize);
  let starting;
  let to;
  useEffect(() => {}, [pageSize, currentPage]);

  if (totalPages === 1) {
    starting = 1;
  } else {
    starting = currentPage * pageSize - pageSize + 1;
  }
  if (data?.length <= pageSize) {
    to = data?.length;
  } else if (currentPage === totalPages) {
    to = data?.length;
  } else {
    to = currentPage * pageSize;
  }

  return (
    <>
      <Table
        scroll={{ x: 100 }}
        columns={columns}
        dataSource={data}
        onChange={onChange}
        pagination={{ pageSize }}
        // footer={() =>
        //   !tableFilteredValue && data?.length
        //     ? `Showing ${starting} to ${to} of ${data?.length} entries`
        //     : ""
        // }
      />
    </>
  );
};
//

export default CommonTable;
