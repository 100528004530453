import * as BsIcons from "react-icons/bs";
import * as HiIcons from "react-icons/hi";
import * as FaIcons from "react-icons/fa";
import * as TbIcons from "react-icons/tb";
export const adminSidebarItems = [
  {
    id: 1,
    path: "/",
    name: "Dash Board",
    icon: <BsIcons.BsGrid />,
  },
  {
    id: 2,
    path: "users",
    name: "Users",
    icon: <HiIcons.HiOutlineUserGroup />,
  },
  {
    id: 3,
    path: "car-type",
    name: "Car Type",
    icon: <FaIcons.FaCar />,
  },
  {
    id: 4,
    path: "trim-type",
    name: "Trim Type",
    icon: <FaIcons.FaCar />,
  },
  {
    id: 5,
    path: "trim",
    name: "Trim",
    icon: <FaIcons.FaCar />,
  },
  // {
  //   id: 6,
  //   name: "Special Pricing",
  //   icon: <BsIcons.BsCurrencyDollar />,
  // },
  // {
  //   id: 7,
  //   name: "Products",
  //   icon: <BsIcons.BsCurrencyDollar />,
  // },
  // {
  //   id: 8,
  //   name: "Product Upload",
  //   icon: <BsIcons.BsSearch />,
  // },
  {
    id: 6,
    path: "models",
    name: "Models",
    icon: <FaIcons.FaCar />,
  },
  {
    id: 9,
    path: "competitive-models",
    name: "Competitive Models",
    icon: <FaIcons.FaCar />,
  },
  {
    id: 10,
    name: "Brand",
    icon: <FaIcons.FaCar />,
    path: "brand",
  },
  {
    id: 11,
    path: "countries",
    name: "Country",
    icon: <TbIcons.TbWorld />,
  },
  {
    id: 12,
    path: "import",
    name: "Import Data",
    icon: <TbIcons.TbFileImport />,
  },
  {
    id: 13,
    path: "car-spec-file",
    name: "Car Specification Data",
    icon: <FaIcons.FaRegFilePdf />,
  },
];
