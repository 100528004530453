import React, { useState } from "react";

import { sidebarItems } from "../data/sidebar-items";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const type = "user";
  return (
    <>
      {sidebarOpen && (
        <Sidebar
          type={type}
          sidebarItems={sidebarItems}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      )}

      <div className={`main-container ${type}`}>
        <Navbar type={type} setSidebarOpen={setSidebarOpen} />
        <div className="content">{children}</div>
      </div>
    </>
  );
};

export default Layout;
